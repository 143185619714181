import { iFrame } from "./iFrame";
import { iStaticUtilities } from "./iStaticUtilities";

export interface iMedia{
   frames: Array<iFrame>,
   id: number,
   indice: number,
   title: string,
   type: string,
   url: string
}

export class iMedia implements iMedia{
    static convertFrontCollection(ob): Array<iMedia> {
      let iMediasCollection: Array<iMedia> = [];
      ob.forEach(element => {
        let media = new iMedia();
        media.id = element.id;
        media.title = element.title;
        media.type = element.type;
        media.url = element.url;
        media.indice = element.indice;
        media.frames = iFrame.convertFrontCollection(iStaticUtilities.normalizeNamesArray(element.frames))
        iMediasCollection.push(media);
      });
      return iMediasCollection;
    }
  }