<header></header>
<carrousel></carrousel>
<div class="discoverGeneralContainer">
    <div class="discoverTitleContainer">
        <div class="text">
            <p>Discover valuable and unique knowledge</p>
        </div>
        <FloattingButton *ngIf="isTeacher"></FloattingButton>
    </div>
    <search (criteria)="getCriteria($event)" (filesTypesEmitter)="filterByFilesType($event)" (sortTypesEmitter)="sortBy($event)" (categoriesEmitter)="filterByCategories($event)" [loaderActive]="searchLoader" [placeholder]="'Search course by specialty and file support'" [type]="'complex'" ></search>
    <div class="coursesContainer">
        <iconLoader [size]="'big'" class="centered" *ngIf="!dataIsLoaded"></iconLoader>
        <ng-container *ngIf="dataIsLoaded">
            <p class="noData" *ngIf="courses.length == 0">No courses available</p>
            <card [size]="'big'" (updateCourses)="reloadCourses()" [data]="course" [id]="i" (mousemove)="rotateToMouse($event, i)"
                (mouseleave)="clearPosition(i)" *ngFor="let course of courses; let i = index"></card>
        </ng-container>
    </div>
    <div class="loaderContainer" >
        <iconLoader [size]="'medium'" class="centered" *ngIf="!paginationLoaded"></iconLoader>
    </div>
</div>
<footer></footer>