<div class="headerGenealContainer">
  <div class="logoContainer">
    <img routerAnimationRight [routerLink]="['/discover']" src="../../../../assets/Images/Logos/logoWithTextLogin360Visi.png" alt="">
  </div>
  <div class="buttonsContainer">
    <a [routerLink]="['/discover']" routerAnimationRight routerLinkActive="active" >Discover</a>
    <a [routerLink]="['/my-courses']" routerAnimationLeft routerLinkActive="active">My courses</a>
    <div >
      <a [routerLink]="['/my-profile']" routerAnimationTop routerLinkActive="active"><img src="../../../../assets/Icons/userProfileIcon.svg" alt=""></a>
      <div class="tooltipMenu"  (click)="logOut()">
        <img src="../../../../assets/Icons/logOutIcon.svg" alt=""><span>Log out</span>
      </div>
    </div>
    
  </div>
</div>