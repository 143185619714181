import { Component, OnInit } from '@angular/core';
import { AnimationControllerService } from '@app/Services/Utils/AnimationController.service';

@Component({
  selector: 'FloattingButton',
  templateUrl: './FloattingButton.component.html',
  styleUrls: ['./FloattingButton.component.scss']
})
export class FloattingButtonComponent implements OnInit {

  constructor(public animCon: AnimationControllerService) { }

  ngOnInit() {
  }

}
