import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoriesService } from '@app/Services/Api/Categories.service';
import { iCourse } from '@app/Shared/Interfaces/iCourse';
import { ITeacherAlumni } from '@app/Shared/Interfaces/iTeacher';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends iUnsubscribeDestroy implements OnInit {

  @Input() placeholder: String = ""
  @Input() dataToFilter: Array<any> = []
  @Input() type: string = "simple"
  @Input() loaderActive: boolean = false;
  @Output() dataFiltered: EventEmitter<Array<any>> = new EventEmitter();
  @Output() criteria: EventEmitter<any> = new EventEmitter();

  @Output() filesTypesEmitter: EventEmitter<Array<string>> = new EventEmitter();
  @Output() sortTypesEmitter: EventEmitter<string> = new EventEmitter();
  @Output() categoriesEmitter: EventEmitter<Array<string>> = new EventEmitter();

  data: string = ""
  opened: boolean = false;
  filesTypes: Array<string> = ["360 videos", "360 images"]
  filesTypesSelected:Array<string> = [] 
  sortTypes: Array<string> = ["Relevance", "Recent","Older","Alphabetical A-Z", "Alphabetical Z-A"]
  sortTypesSelected: string = "Recent"
  categories: Array<string> = []
  categoriesSelected: Array<string> = []

  constructor(private categoriesSE: CategoriesService) {
    super();
  }
  ngOnInit(): void {
    this.getCategories();
  }
  controlOpenFilters(){
    if (this.opened) {
      this.opened = false;
    }else{
      this.opened = true;
    }
  }
  controlFileType(file){
   if (this.filesTypesSelected.includes(file)) {
    this.filesTypesSelected.splice(this.filesTypesSelected.indexOf(file), 1);
   }else{
    this.filesTypesSelected.push(file)
   }
  }
  controlCategories(category){
   if (this.categoriesSelected.includes(category)) {
    this.categoriesSelected.splice(this.categoriesSelected.indexOf(category), 1);
   }else{
    this.categoriesSelected.push(category)
   }
  }

  filter(data: string) {
    if (this.dataToFilter.length > 0) {
      if (this.data != "") {
        if (this.dataToFilter.length > 0) {
          if (this.dataToFilter[0] instanceof ITeacherAlumni) {
            this.dataFiltered.emit(this.dataToFilter.filter(e => e.name.toLowerCase().includes(data.toLowerCase())))
          }
          if (this.dataToFilter[0] instanceof iCourse) {
            this.dataFiltered.emit(this.dataToFilter.filter(e => e.title.toLowerCase().includes(data.toLowerCase())))
          }
        }
      } else {
        this.dataFiltered.emit(this.dataToFilter)
      }
    }
  }
  getCategories(){
    this.categoriesSE.getAll();
    this.categoriesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if (res == null) {return;}
      res.data.map(element=>{
        this.categories.push(element.name)
      })
      this._unsubInd.next("");
    })
  }
  emitCriteria() {
    this.criteria.emit(this.data)
  }
  emitSort(){
    this.sortTypesEmitter.emit(this.sortTypesSelected)
  }
  emitCategories(){
    this.categoriesEmitter.emit(this.categoriesSelected)
  }
  emitFyleType(){
    this.filesTypesEmitter.emit(this.filesTypesSelected)
  }
}
