import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { TokenManagmentService } from './TokenManagment.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DefaultBaseService {

  private _unsub = new Subject<any>();
  private methodsRefreshToken:Array<{method:Function,args:Array<any>}>=[];
  private alreadyListener=false;
  
  protected urlApi = environment.urlApi;
  protected _result=new BehaviorSubject<any>(null);
  protected _resultError=new BehaviorSubject<any>(null);
  protected _resultIndividual=new BehaviorSubject<any>(null);
  protected _resultIndividualError=new BehaviorSubject<any>(null);
  protected _resultUpdate=new BehaviorSubject<any>(null);
  protected _resultUpdateError=new BehaviorSubject<any>(null);
  protected _resultDelete=new BehaviorSubject<any>(null);
  protected _resultDeleteError=new BehaviorSubject<any>(null);
  
  /** Return del subscribe _result */
  getResult(){return this._result;}
  /** Return del subscribe _resultIndividual */
  getResultIndividual(){return this._resultIndividual;}
  /** Return del subscribe _resultIndividualError */
  getResultIndividualError(){return this._resultIndividualError;}
  /** Return del subscribe _resultError */
  getResultError(){return this._resultError;}
  /** Return del subscribe _resultUpdate */
  getResultUpdate(){return this._resultUpdate;}
  /** Return del subscribe _resultUpdateError */
  getResultUpdateError(){return this._resultUpdateError;}
  /** Return del subscribe _resultDelete */
  getResultDelete(){return this._resultDelete;}
  /** Return del subscribe _resultDeleteError */
  getResultDeleteError(){return this._resultDeleteError;}

  /** Vaciar los datos del subscribe _result */
  clearResult(){this._result.next(null);}
  /** Vaciar los datos del subscribe _resultError */
  clearResultError(){this._resultError.next(null);}
  /** Vaciar los datos del subscribe _resultIndividual */
  clearResultIndividual(){this._resultIndividual.next(null);}
  /** Vaciar los datos del subscribe _resultIndividualError */
  clearResultIndividualError(){this._resultIndividualError.next(null);}
  /** Vaciar los datos del subscribe _resultUpdate */
  clearResultUpdate(){this._resultUpdate.next(null);}
  /** Vaciar los datos del subscribe _resultUpdateError */
  clearResultUpdateError(){this._resultUpdateError.next(null);}
  /** Vaciar los datos del subscribe _resultDelete */
  clearResultDelete(){this._resultDelete.next(null);}
  /** Vaciar los datos del subscribe _resultDeleteError */
  clearResultDeleteError(){this._resultDeleteError.next(null);}

  /**
   * Método que controla todas las actualizaciones de todos los datos de todos los servicios y de todas las llamadas a la API y estandariza las llamadas
   * a la API junto a checkear el error 401 (Token lost)
   * @param {String} type El tipo de datos a donde quieres enviarlo
   * @param {Object} data La información que se quiere enviar
   * @param {Object} methodCall Variable que decide cuando es error 401, volver a refrescar la variable
   */
  protected sendNextResult(type:'result'|'resultError'|'resultIndividual'|'resultIndividualError'|'resultUpdate'|'resultUpdateError'|'resultDelete'|'resultDeleteError',data:any,methodCall?:{method:Function,args:Array<any>}){
    let clearFuntion:Function;
    if(type=='result'){
      this._result.next(data);
      clearFuntion=this.clearResult;
    }else if(type=='resultError'){
      this._resultError.next(data);
      clearFuntion=this.clearResultError;
      this.checkStatusError(data,methodCall);
    }else if(type=='resultIndividual'){
      this._resultIndividual.next(data);
      clearFuntion=this.clearResultIndividual;
    }else if(type=='resultIndividualError'){
      this._resultIndividualError.next(data);
      clearFuntion=this.clearResultIndividualError;
      this.checkStatusError(data,methodCall);
    }else if(type=='resultUpdate'){
      this._resultUpdate.next(data);
      clearFuntion=this.clearResultUpdate;
    }else if(type=='resultUpdateError'){
      this._resultUpdateError.next(data);
      clearFuntion=this.clearResultUpdateError;
      this.checkStatusError(data,methodCall);
    }else if(type=='resultDelete'){
      this._resultDelete.next(data);
      clearFuntion=this.clearResultDelete;
    }else if(type=='resultDeleteError'){
      this._resultDeleteError.next(data);
      clearFuntion=this.clearResultDeleteError;
      this.checkStatusError(data,methodCall);
    }
    setTimeout(()=>{
      this[clearFuntion.name]();
    },150);
  }

  /**
   * Método interno que se encarga de hacer que el token se refresque cuando falla un método
   * @param methodCall El método que ha dado error, puede ser nulo para evitar errores (ya que es opcional que se llave una vez que termine de refrescar el token)
   */
  private listenerRefreshToken(methodCall?:{method:Function,args:Array<any>}){
    if(methodCall!=null){
      this.methodsRefreshToken.push(methodCall);
    }else{
      console.error("Exist one method without error methodCall");
    }
    if(!this.alreadyListener){
      this.alreadyListener=true;
      TokenManagmentService.listenerRefreshToken().pipe(takeUntil(this._unsub)).subscribe(value=>{
        setTimeout(() => {
          this._unsub.next("");
          this.alreadyListener=false;
          this.reloadMethodRefreshToken();
        }, 100);
      })
      TokenManagmentService.startTokenRefresh();
    }
  }

  /**
   * Método para llamar a todos los métodos que han dado error 401
   */
  protected reloadMethodRefreshToken(){
    setTimeout(() => {
      this.methodsRefreshToken.forEach(element => {
        this[element.method.name](...element.args);
      });
      this.methodsRefreshToken=[];
    }, 1000);
  }
  
  /**
   * Método para chequear si es necesario refrescar el token. Si da error 401 refresca el token, si da error 403 por seguridad, se desloguea
   * @param {Object} data El objeto que devuelve la petición HTTP
   * @param methodCall El método donde quieres repetir la llamada que ha dado error (Para que el usuario no se de cuenta del error) y refresque el token en medio
   */
  protected checkStatusError(data:any,methodCall?:{method:Function,args:Array<any>}){
    if(data.status==401){
      if (methodCall?.method.name != "checkLogin" && methodCall != undefined) {
        this.listenerRefreshToken(methodCall)
      }
    }
    if(data.status==403){TokenManagmentService.tokenLost();}
  }

  /**
   * Set del Token
   * @param {String} token Token
   */
  protected setToken(token:string){localStorage.setItem('token', token)}

  /**
   * Cuando se inicia sesión te envia un token y un refreshToken, el refreshToken sirve para enviarlo a un método para refrescar el token
   * @param {String} refreshToken Token para refrescar el token
   */
  protected setRefreshToken(refreshToken){localStorage.setItem('refreshToken', refreshToken)}

  /**
   * Get del Token
   * @returns {String} Token de la API
   */
  protected getToken(){ return localStorage.getItem('token'); }

  /**
   * Función que se tiene que llamar cada vez que ejecutas una llamada a la API con el token
   * @returns {Object} Contiene HttpHeaders
   */
  protected getHeader(){
    return {
      headers: new HttpHeaders({
        Authorization: "Bearer "+localStorage.getItem("token")
      })
    };
  }

  /**
   * Sobretodo utilizable en los DELETE cuando tienes que enviar en un objeto.
   * @param {Array} arraySend - [1,2,3,4,5,6]
   * @param {String} objectIndex - producto_ids
   * @returns {Object} - { producto_ids: [1,2,3,4,5,6] }
   */
  protected convertArrayObject(arraySend,objectIndex){
    let send;
    send ={};
    send[objectIndex] = [];
    if (!(arraySend instanceof Array)) { 
      send[objectIndex].push(arraySend);
    } else {  
      send[objectIndex]=[...arraySend];
    }
    return send;
  }
  
  /**
   * Sobretodo sirve para cuando envias información en los DELETE. Cuando la petición DELETE admite un body, pero el método te lo pone al mismo nivel que los headers y
   * no como un parametro aparte
   * @param {Object} bodyOptions - { productId: 5, string: "Test" }
   * @returns {Object} - { headers: ... , body: { productId: 5, string: "Test" } }
   */
  protected sendBodyOptions(bodyOptions){
    if(bodyOptions!=null){
      return {headers:this.getHeader().headers,body:bodyOptions};
    }
    return this.getHeader();
  }

  /**
   * Para enviar datos en la URL como queryParams ( url?datos ). Se utiliza sobretodo en los GET cuando necesitas pasar datas
   * @param {Object} options - { num_data: 5, num_pag: 1}
   * @returns {String} - ?num_data=5,num_pag=1
   */
  protected optionsGet(options){
    if(options==null){ return '';}
    let ret="?";
    let objKey=Object.keys(options);
    for (let i = 0; i < Object.keys(options).length; i++) {
      let element = objKey[i];
      if(options[element] instanceof Array){
        let j=options[element].join(",");
        ret+=element+"="+j;
      }else{
        if(i>0){ret+="&";}
        ret+=element+"="+options[element];
      }
    }
    return ret;
  }

  constructor() { }


}
