import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourseDetailsComponent } from './Pages/CourseDetails/CourseDetails.component';
import { CourseVisualicerComponent } from './Pages/CourseVisualicer/CourseVisualicer.component';
import { DiscoverComponent } from './Pages/Discover/Discover.component';
import { LoginComponent } from './Pages/Login/Login.component';
import { MyCoursesComponent } from './Pages/MyCourses/MyCourses.component';
import { MyProfileComponent } from './Pages/MyProfile/MyProfile.component';
import { NewCourseComponent } from './Pages/NewCourse/NewCourse.component';
import { RegisterComponent } from './Pages/Register/Register.component';

const routes: Routes = [
  { path: '', component: LoginComponent, data: {state: "Login"} },
  { path: 'create-account', component: RegisterComponent, data: {title: 'Create an account',state: "Create account"}},
  { path: 'discover', component: DiscoverComponent, data: {title: 'Discover courses',state: "Dicover"}},
  { path: 'my-courses', component: MyCoursesComponent, data: {title: 'My courses',state: "My courses"}},
  { path: 'new-course', component: NewCourseComponent, data: {title: 'Add new course',state: "Add new course"}},
  { path: 'edit-course/:id', component: NewCourseComponent, data: {title: 'Edit course',state: "Edit course"}},
  { path: 'course-details/:id', component: CourseDetailsComponent, data: {title: 'Course details',state: "Course details"}},
  { path: 'my-profile', component: MyProfileComponent, data: {title: 'My profile',state: "My profile"}},
  { path: 'course-visualicer/:id', component: CourseVisualicerComponent, data: {title: 'Course visualicer',state: "Course visualicer"}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
