import {query, style, animate, group} from '@angular/animations';

export enum slideNavigationTypes{
  slideToLeft,
  slideToRight,
  slideToTop,
  slideToBottom
}
export class slideNavigationFunctions{
  protected static slideToLeft = [    
    query(':enter, :leave', style({ position: 'fixed', width:'100%' }), {optional: true}),
    group([ 
      query(':enter', [ style({ transform: 'translateX(100%)' }), animate('0.3s ease-in-out', style({ transform: 'translateX(0%)' })), ], {optional: true}),
      query(':leave', [ style({ transform: 'translateX(0%)' }), animate('0.3s ease-in-out', style({ transform: 'translateX(-100%)' })) ], {optional: true})
    ])
  ];
  protected static slideToRight = [    
    query(':enter, :leave', style({ position: 'fixed', width:'100%' }), {optional: true}),
    group([ 
      query(':enter', [ style({ transform: 'translateX(-100%)' }), animate('0.3s ease-in-out', style({ transform: 'translateX(0%)' })), ], {optional: true}),
      query(':leave', [ style({ transform: 'translateX(0%)' }), animate('0.3s ease-in-out', style({ transform: 'translateX(100%)' })) ], {optional: true})
    ])
  ];
  protected static slideToTop = [    
    query(':enter, :leave', style({ position: 'fixed', width:'100%', overflow: 'hidden', height: '100vh' }), {optional: true}),
    group([ 
      query(':enter', [ style({ transform: 'translateY(100%)' }), animate('0.3s ease-in-out', style({ transform: 'translateY(0%)' })) ], {optional: true}),
      query(':leave', [ style({ transform: 'translateY(0%)' }), animate('0.3s ease-in-out', style({ transform: 'translateY(-100%)' })), ], {optional: true}),
    ])
  ];
  protected static slideToBottom = [    
    query(':enter, :leave', style({ position: 'fixed', width:'100%', overflow: 'hidden', height: '100vh' }), {optional: true}),
    group([ 
      query(':enter', [ style({ transform: 'translateY(-100%)' }), animate('0.3s ease-in-out', style({ transform: 'translateY(0%)' })), ], {optional: true}),
      query(':leave', [ style({ transform: 'translateY(0%)' }), animate('0.3s ease-in-out', style({ transform: 'translateY(100%)' })) ], {optional: true}),
    ])
  ];
  /**
   * Transforma string en un slideNavigationTypes
   * @param {string} animationId La animación que quieres convertir
   * @returns {slideNavigationTypes}
   */
  static convertStringToEnum(animationId:any):slideNavigationTypes{
    let nextAnimation;
    switch(animationId) {
      case "slideToLeft":
        nextAnimation = slideNavigationTypes.slideToLeft;
      break;
      case "slideToRight":
        nextAnimation = slideNavigationTypes.slideToRight;
      break;
      case "slideToTop":
        nextAnimation = slideNavigationTypes.slideToTop;
      break;
      case "slideToBottom":
        nextAnimation = slideNavigationTypes.slideToBottom;
      break;
      default:
        nextAnimation = slideNavigationTypes.slideToTop;
      break;
    }
    return nextAnimation;
  }
  /**
   * Transforma slideNavigationTypes en un string
   * @param {slideNavigationTypes} animationId La animación que quieres convertir
   * @returns {string}
   */
  static convertEnumToString(animationId:slideNavigationTypes):string{
    let nextAnimation;
    switch(animationId) {
      case slideNavigationTypes.slideToLeft:
        nextAnimation = "slideToLeft";
      break;
      case slideNavigationTypes.slideToRight:
        nextAnimation = "slideToRight";
      break;
      case slideNavigationTypes.slideToTop:
        nextAnimation = "slideToTop";
      break;
      case slideNavigationTypes.slideToBottom:
        nextAnimation = "slideToBottom";
      break;
      default:
        nextAnimation = "slideToTop";
      break;
    }
    return nextAnimation;
  }
  /**
   * Recoge la animación en concreto que pertenece a cada enum
   * @param {slideNavigationTypes} animationId - Una animación a elegir del enum de sliderNavigationTypes
   * @returns {(AnimationQueryMetadata | AnimationGroupMetadata)[]}
   */
  static getAnimation(animationId:slideNavigationTypes){
    switch(animationId) {
      case slideNavigationTypes.slideToLeft:
        return this.slideToLeft;
      break;
      case slideNavigationTypes.slideToRight:
        return this.slideToRight;
      break;
      case slideNavigationTypes.slideToTop:
        return this.slideToTop;
      break;
      case slideNavigationTypes.slideToBottom:
        return this.slideToBottom;
      break;
      default:
        return this.slideToTop;
      break;
    }
  }
}