import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'Pills',
  templateUrl: './Pills.component.html',
  styleUrls: ['./Pills.component.scss']
})
export class PillsComponent implements OnInit {
  @Input() typeOfPill: string = "userPill";
  @Input() dataFilterPill: any = {grade: "", countPeople: ""};
  @Input() dataUserPill: String = "";

  constructor() { }

  ngOnInit() {
  }

}
