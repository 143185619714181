import { Component, OnInit } from '@angular/core';
import { TeacherService } from '@app/Services/Api/Teacher.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { ITeacherAlumni, ITeacherGrades } from '@app/Shared/Interfaces/iTeacher';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';
import { AlumniDataPopupComponent } from 'src/app/Popups/AlumniDataPopup/AlumniDataPopup.component';
import { PopupService } from 'src/app/Services/Utils/popup.service';

@Component({
  selector: 'MyCourses-Alumni',
  templateUrl: './MyCourses-Alumni.component.html',
  styleUrls: ['./MyCourses-Alumni.component.scss']
})
export class MyCoursesAlumniComponent extends iUnsubscribeDestroy implements OnInit {

  teacherGrades: Array<ITeacherGrades> = []
  teacherAlumnis: Array<ITeacherAlumni> = []
  teacherAlumnis_backup: Array<ITeacherAlumni> = []
  dataIsLoaded: boolean = false;
  selectedAlumni: ITeacherAlumni;
  constructor(private popupSE: PopupService,private teacherSE: TeacherService, private notificaciones: NotificacionesService) {
    super();
  }

  ngOnInit() {
    this.getGrades();
  }
  openAlumniData(){
    this.popupSE.openPopup(AlumniDataPopupComponent, this.selectedAlumni)
  }
  filterData(event){
    this.teacherAlumnis = event;
  }
  //LLAMADAS A LA API
  getGrades(){
    this.teacherSE.getGrades();
    this.teacherSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if (res == null) {return;}
      this.teacherGrades = res.data;
      this._unsubInd.next("");
      setTimeout(() => {
        this.getAlumnis();
      }, 500);
    })
  }
  getAlumnis(){
    this.teacherSE.getAlumnis();
    this.teacherSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if (res == null) {return;}
      this.teacherAlumnis = res.data
      this.teacherAlumnis_backup = res.data
      this.dataIsLoaded = true;
      this._unsubInd2.next("");
    })
  }
}
