import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { slideNavigationTypes } from '@app/Shared/Interfaces/eSliderNavigationTypes';
import { takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { AnimationControllerService } from 'src/app/Services/Utils/AnimationController.service';
import { PopupService } from 'src/app/Services/Utils/popup.service';
import { iCourse } from '../../Interfaces/iCourse';
import { iUnsubscribeDestroy } from '../../Interfaces/iUnsubscribeDestroy';

@Component({
  selector: 'HeaderNewCourse',
  templateUrl: './HeaderNewCourse.component.html',
  styleUrls: ['./HeaderNewCourse.component.css']
})
export class HeaderNewCourseComponent extends iUnsubscribeDestroy implements OnInit {
  @Input() courseName:String = "Untitled course …" 
  @Input() hasChanges: Boolean = false;
  @Input() stepActive: Number = 0;
  @Input() withoutMargin: Boolean = false;
  @Input() syncStatus:{ isLoading: boolean, isSave: boolean, lastTimeSave: string} ={ isLoading: false, isSave: true, lastTimeSave: '00:00'}
  @Output() emitReturnToStep0 = new EventEmitter<Number>()
  @Output() syncButton = new EventEmitter<any>()
  constructor(private popupSE: PopupService, private router: Router, private animSe:AnimationControllerService) {
    super();
  }

  ngOnInit() {
  }
  detectChanges(){
    if (this.hasChanges) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Discard", tipo: "changes"})
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
        if(res == null){return}
        if (res.returnValue.accionHecha) {
          this.animSe.setCurrentAnimation(slideNavigationTypes.slideToBottom);
          this.router.navigate(["/discover"])
        }
        this._unsubInd.next("");
      })
    }else{
      this.animSe.setCurrentAnimation(slideNavigationTypes.slideToBottom);
      this.router.navigate(["/discover"])
    }
  }
  returnToStep0(){
    this.emitReturnToStep0.emit(0)
  }
  sendSync(){
    this.syncButton.emit("");
  }
}
