import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from '@app/Services/Api/Courses.service';
import { LoginService } from '@app/Services/Api/Login.service';
import { UserService } from '@app/Services/Api/User.service';
import { LoaderService } from '@app/Services/Utils/loader.service';
import { iCourse } from '@app/Shared/Interfaces/iCourse';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';
import { NotificacionesService } from 'src/app/Services/Utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-CourseDetails',
  templateUrl: './CourseDetails.component.html',
  styleUrls: ['./CourseDetails.component.scss']
})
export class CourseDetailsComponent extends iUnsubscribeDestroy implements OnInit {
  urlImages = environment.urlImages
  courseId: number = this.route.snapshot.params['id'];
  dataIsLoaded: boolean = false;
  course: iCourse = new iCourse();
  relatedCourses: Array<iCourse> = []
  constructor(private notificaciones: NotificacionesService, private loginSE: LoginService, private route: ActivatedRoute, private courseSE: CoursesService, private userSE: UserService, private loader: LoaderService) {
    super();
  }
  ngOnInit() {
    if (!iStaticUtilities.isLoged()) {
      this.notificaciones.showWarning("To access this page you need to be logged in.")
      this.loginSE.logOut();
    } else {
      this.courseId = this.route.snapshot.params['id'];
      this.getCourseById();
    }
  }
 
  //FUNCIONALIDAD
  reloadCourses(){
    this.getRelatedCourses()
  }
  reloadData(){
    this.dataIsLoaded = false;
    this.ngOnInit();
  }
  rotateToMouse(e:any, element:any, type:string) {
    var card = document.getElementById(type+element) as HTMLElement;
    var bounds = card.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2
    }
    const distance = Math.sqrt(center.x**2 + center.y**2);
    
    card.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance)* 3}deg
      )
    `;
    
    (card.querySelector('.glow') as HTMLElement).style.backgroundImage = `
      radial-gradient(
        circle at
        ${center.x * 2 + bounds.width/2}px
        ${center.y * 2 + bounds.height/2}px,
        #ffffff55,
        #0000000f
      )
    `;
  }
  clearPosition(element:any, type:string){
    (document.getElementById(type+element)as HTMLElement).style.transform = '';
    (document.getElementById(type+element)as HTMLElement).style.background = '';
    //   card.style.background = '';
  }

  //LLAMADAS A LA APi


  getCourseById(){
    this.courseSE.getSingle(this.courseId);
    this.courseSE.getResultIndividual().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return}
      this.course = res.data;
      setTimeout(() => {
        this.getRelatedCourses();
      }, 500);
    })
  }
  getRelatedCourses(){
    this.courseSE.getRelated(this.courseId);
    this.courseSE.getResultRelated().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res==null){return}
      this.relatedCourses = res.data;
      this.dataIsLoaded = true;
    })
  }
  addFavourites(){
    this.userSE.addToFavourites(this.courseId);
    this.userSE.getResult().pipe(takeUntil(this._unsubInd3)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showFeedBack(res.message);
      this._unsubInd3.next("")

    })
    this.userSE.getResultError().pipe(takeUntil(this._unsubInd3)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showError(res.message);
      this._unsubInd3.next("")
    })
  }
  removeFavourites(){
    this.userSE.deleteFavouritesCourses([this.courseId]);
    this.userSE.getResultDelete().pipe(takeUntil(this._unsubInd4)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showFeedBack(res.message);
      this._unsubInd4.next("")
    })
    this.userSE.getResultDeleteError().pipe(takeUntil(this._unsubInd4)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showError(res.message);
      this._unsubInd4.next("")
    })
  }
}
