export interface IUser {
    email: string | null,
    id?: number | null,
    name: string | null,
    surname: string
    phone: string | null,
    roles: Array<string>,
}

export class IUser implements IUser {
    static convertFrontObject(ob): IUser {
      let user: IUser = new IUser();
      user.email = ob.email,
      user.id = ob.id,
      user.name = ob.name,
      user.surname = ob.name,
      user.phone = ob.phone,
      user.roles = ob.roles
      return user;
    }
}