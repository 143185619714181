import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {

  /** Evento que llama a la función de abrir el tooltip al pasar el raton por encima del elemento*/
  @Input() text: string = ""

  /** Cantidad de delay al cerrar el tooltip (OPCIONAL)*/
  @Input() delay?: number | null = null;

  /**Controla si el tooltip esta abierto*/
  opened: Boolean = false;

  constructor(public element: ElementRef) { }

  /** Evento que llama a la función de abrir el tooltip al pasar el raton por encima del elemento */
  @HostListener('mouseenter') onMouseEnter() {
    this.openTooltip();
  }

  /** Evento que llama a la función de cerrar el tooltip, cuando el elemento pierde el foco*/
  @HostListener('mouseleave') onMouseLeave() {
    if (this.delay != null) {
      setTimeout(() => {
        this.closeTooltip();
      }, this.delay);
    } else {
      setTimeout(() => {
        this.closeTooltip();
      }, 200);
    }
  }

  /** Método que calcula la posición del tooltip en función del elemento y llama al método de crear*/
  openTooltip() {
    let x = this.element.nativeElement.getBoundingClientRect().left + this.element.nativeElement.offsetWidth / 2;
    let y = this.element.nativeElement.getBoundingClientRect().top + this.element.nativeElement.offsetHeight + 6;
    this.createTooltip(x, y);
  }

  /** Método que cierra el tooltip*/
  closeTooltip() {
    this.opened = false;
    document.querySelector(".tooltip-container")?.remove()
  }

  /**
  * Método que crea el tooltip
  * @param {number} x Posicion del tooltip en el eje x
  * @param {number} y Posicion del tooltip en el eje y
  */
  createTooltip(x: number, y: number) {
    if (!this.opened) {
      let popup = document.createElement('div');
      popup.innerHTML = this.text;
      popup.setAttribute("class", "tooltip-container");
      popup.style.top = y.toString() + "px";
      popup.style.left = x.toString() + "px";
      document.body.appendChild(popup);
    }
    this.opened = true;
  }
}
