import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { iLogin } from '@app/Shared/Interfaces/iLogin';
import { environment } from 'src/environments/environment';
import { DefaultBaseService } from './DefaultBase.service';
import { TokenManagmentService } from './TokenManagment.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends DefaultBaseService{

  res: iResultHttp = { status: -1 , ok: false, message: "", data: undefined }

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  checkLogin(credentials: iLogin) { 
    this.http.post(this.urlApi + '/login/user', credentials).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: "Successfully logged in"};
        let normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.setToken(normalizedTokens["token"]);
        this.setRefreshToken(normalizedTokens["refreshToken"]);
        localStorage.setItem("userType",iStaticUtilities.getUserTypeToken());
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.checkLogin, args: [credentials] });
        this.checkStatusError(error);
      },
    });
  }
  refreshToken() { 
    this.http.post(this.urlApi + '/api/refreshToken', { refresh_token: localStorage.getItem("refreshToken") }).subscribe({
      next: (data) => {
        let normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.setToken(normalizedTokens["token"]);
        this.setRefreshToken(normalizedTokens["refreshToken"]);
        TokenManagmentService.completeRefreshToken();
      },
      error: (error) => {
        TokenManagmentService.tokenLost();
      },
    });
  }
  logOut() {
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("myUser")
    localStorage.removeItem("userType")
    this.router.navigate(["/"])
  }

}
