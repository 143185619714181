import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditorModule } from '@tinymce/tinymce-angular';

//PIPES
import { SafePipe } from './Shared/Pipes/SafePipe.pipe';
//DIRECTIVES
import { FileInputDirective } from './Shared/Directives/fileInput.directive';
import { RouterAnimationBottomDirective, RouterAnimationLeftDirective, RouterAnimationRightDirective, RouterAnimationTopDirective } from './Shared/Directives/routerAnimation.directive';
//MÓDULOS EXTERNOS
import { NgSelectModule } from '@ng-select/ng-select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NgChartsModule } from 'ng2-charts';
import {MatSliderModule} from '@angular/material/slider';
import {MatIconModule} from '@angular/material/icon';

//PAGES
import { LoginComponent } from './Pages/Login/Login.component';
import { DiscoverComponent } from './Pages/Discover/Discover.component';
import { RegisterComponent } from './Pages/Register/Register.component';
import { MyCoursesComponent } from './Pages/MyCourses/MyCourses.component';
import { NewCourseComponent } from './Pages/NewCourse/NewCourse.component';
import { NewCourseFirstFormComponent } from './Pages/NewCourse/NewCourse-FirstForm/NewCourse-FirstForm.component';
import { MyCoursesPlatformCoursesComponent } from './Pages/MyCourses/MyCourses-PlatformCourses/MyCourses-PlatformCourses.component';
import { MyCoursesCreatedCoursesComponent } from './Pages/MyCourses/MyCourses-CreatedCourses/MyCourses-CreatedCourses.component';
import { MyCoursesAlumniComponent } from './Pages/MyCourses/MyCourses-Alumni/MyCourses-Alumni.component';
import { CourseDetailsComponent } from './Pages/CourseDetails/CourseDetails.component';
import { CourseVisualicerComponent } from './Pages/CourseVisualicer/CourseVisualicer.component';
import { NewCourseEditorComponent } from './Pages/NewCourse/NewCourse-Editor/NewCourse-Editor.component';
import { MyProfileComponent } from './Pages/MyProfile/MyProfile.component';
//POPUPS
import { AlumniDataPopupComponent } from './Popups/AlumniDataPopup/AlumniDataPopup.component';
import { ConfirmPopupComponent } from './Popups/ConfirmPopup/ConfirmPopup.component';
import { UploadFilePopupComponent } from './Popups/UploadFilePopup/UploadFilePopup.component';
//COMPONENTS
import { AppComponent } from './app.component';
import { CountrySelectorComponent } from './Shared/Components/CountrySelector/CountrySelector.component';
import { HeaderComponent } from './Shared/Layout/Header/header.component';
import { CarrouselComponent } from './Shared/Components/Carrousel/carrousel.component';
import { SearchComponent } from './Shared/Components/Search/search.component';
import { CardComponent } from './Shared/Components/Card/Card.component';
import { FooterComponent } from './Shared/Layout/Footer/Footer.component';
import { HeaderNewCourseComponent } from './Shared/Layout/HeaderNewCourse/HeaderNewCourse.component';
import { IntraPageNavegationComponent } from './Shared/Layout/IntraPageNavegation/IntraPageNavegation.component';
import { PillsComponent } from './Shared/Components/Pills/Pills.component';
import { GradesChartComponent } from './Shared/Components/GradesChart/GradesChart.component';
import { CategoriesChartComponent } from './Shared/Components/CategoriesChart/CategoriesChart.component';
import { ButtonsEditorComponent } from './Pages/NewCourse/NewCourse-Editor/holders/buttonsEditor/buttonsEditor.component';
import { ActionEditorComponent } from './Pages/NewCourse/NewCourse-Editor/holders/actionEditor/actionEditor.component';
import { PillElementComponent } from './Pages/NewCourse/NewCourse-Editor/holders/pillElement/pillElement.component';
import { IconLoaderComponent } from './Shared/Components/IconLoader/IconLoader.component';
import { LoadingComponent } from './Popups/loading/loading.component';
import { FloattingButtonComponent } from './Shared/Components/FloattingButton/FloattingButton.component';
import { TimeConversorPipe } from './Shared/Pipes/timeConversor.pipe';
import { TooltipDirective } from './Shared/Directives/Tooltip.directive';
import { ToggleComponent } from './Shared/Components/toggle/toggle.component';
import { InfoSpotContentComponent } from './Popups/InfoSpotContent/InfoSpotContent.component';
@NgModule({
  declarations: [
    AppComponent,
    //Pages
    LoginComponent,
    RegisterComponent,
    DiscoverComponent,
    MyCoursesComponent,
    NewCourseComponent,
    NewCourseFirstFormComponent,
    MyCoursesPlatformCoursesComponent,
    MyCoursesCreatedCoursesComponent,
    MyCoursesAlumniComponent,
    CourseVisualicerComponent,
    NewCourseEditorComponent,
    MyProfileComponent,
    //POPUPS
    LoadingComponent,
    //COMPONENTS
    CountrySelectorComponent,
    HeaderComponent,
    CarrouselComponent,
    SearchComponent,
    CardComponent,
    FooterComponent,
    HeaderNewCourseComponent,
    IntraPageNavegationComponent,
    PillsComponent,
    CourseDetailsComponent,
    AlumniDataPopupComponent,
    GradesChartComponent,
    CategoriesChartComponent,
    ConfirmPopupComponent,
    UploadFilePopupComponent,
    ButtonsEditorComponent,
    ActionEditorComponent,
    PillElementComponent,
    IconLoaderComponent,
    FloattingButtonComponent,
    ToggleComponent,
    InfoSpotContentComponent,
    //Directives
    FileInputDirective,
    RouterAnimationBottomDirective,
    RouterAnimationLeftDirective,
    RouterAnimationTopDirective,
    RouterAnimationRightDirective,
    ActionEditorComponent,
    TooltipDirective,
    //PIPE
    SafePipe,
    TimeConversorPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    NgSelectModule,
    MatCheckboxModule,
    NgChartsModule,
    MatSliderModule,
    MatIconModule,
    EditorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
