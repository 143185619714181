import { iBaseObject } from "./iBase";

export interface iDate {
    year: number,
    month: number,
    day: number,
    hour: number,
    minutes: number,
    seconds: number
}
export class iDate extends iBaseObject implements iDate {
    constructor(backendFecha?: string) {
        super();
        if (backendFecha != null) {
            if (this.constructor.name == backendFecha.constructor.name) {
                this[iDate.clone(backendFecha)["clonedObj"]]
            } else {
                this.assignData(iDate.phpConvert(backendFecha))
            }
        }
    }

    /**
    * Método interno para asignar valores a 'this' en un objeto iDate 
    * @param {iDate} iDateObj Objeto retornado de la conversión de una fecha de php
    */
    private assignData(iDateObj) {
        this.year = Number(iDateObj.year);
        this.month = Number(iDateObj.month);
        this.day = Number(iDateObj.day);
        this.hour = Number(iDateObj.hour);
        this.minutes = Number(iDateObj.minutes);
        this.seconds = Number(iDateObj.seconds)
    }

    /**
    * Convertir segundos a un objeto iDate añadiendole los parametros horas, minutos, segundos. 
    * @param {number} seconds Segundos a convertir
    * @returns {iDate} Objeto iDate (hour,minutes,seconds)
    */
    static secondsConverter(seconds: number): iDate {
        let p = new iDate();
        let hours = ~~(seconds / 3600);
        let min = ~~((seconds % 3600) / 60);
        let seg = ~~seconds % 60;
        // Hours, minutes and seconds
        p.hour = hours;
        p.minutes = min;
        p.seconds = seg;
        return p;
    }

    /**
    * Convertir un objeto Date de JavaScript a un objeto iDate completo
    * @param {Date} date Fecha de JavaScript
    * @returns {iDate} Objeto iDate
    */
    static javascriptConvert(date: Date): iDate {
        let convertedFromJs = new iDate();
        convertedFromJs.year = date.getFullYear();
        convertedFromJs.month = date.getMonth() + 1;
        convertedFromJs.day = date.getDate();
        convertedFromJs.hour = date.getHours();
        convertedFromJs.minutes = date.getMinutes();
        convertedFromJs.seconds = date.getSeconds();
        return convertedFromJs;
    }

    /**
    * Convertir una fecha de PHP a un objeto iDate completo
    * @param {string} phpDate Fecha en formato php
    * @returns {iDate} Objeto iDate
    */
    static phpConvert(phpDate: string): iDate {
        let phpConvertedDate = new iDate();
        var auxiliarDate = new Date(phpDate);

        phpConvertedDate.year = auxiliarDate.getFullYear();
        phpConvertedDate.month = (auxiliarDate.getMonth() + 1);
        phpConvertedDate.day = auxiliarDate.getDate();
        phpConvertedDate.hour = auxiliarDate.getHours();
        phpConvertedDate.minutes = auxiliarDate.getMinutes();
        phpConvertedDate.seconds = auxiliarDate.getSeconds();

        return phpConvertedDate;
    }

    /**
    * Convertir un objeto Date de JavaScript a fecha de PHP
    * @param {Date} date
    * @returns {string} string -> fecha convertida a php
    */
    static toPhp(date: Date): string {
        return date.getFullYear() + "-" + iDate.cerosAdd(date.getMonth() + 1) + "-" + iDate.cerosAdd(date.getDate()) + "T" + iDate.cerosAdd(date.getHours()) + ":" + iDate.cerosAdd(date.getMinutes()) + ":" + iDate.cerosAdd(date.getSeconds())
    }

    /**
    * Convertir un objecto iDate a uno Date de JavaScript
    * @returns {Date} yyyy-mm-dd hh:mm:ss
    */
    toJavascript(): Date {
        if (this.hour == null) { this.hour = 0; }
        if (this.minutes == null) { this.minutes = 0; }
        if (this.seconds == null) { this.seconds = 0; }
        return new Date(this.year + "-" + this.month + "-" + this.day + " " + this.hour + ":" + this.minutes + ":" + this.seconds)
    }

    /**
    * Convertir los parámetros horas, minutos y segundos de un iDate a una cedena de texto
    * @returns {string} Cadena de texto hh:mm:ss
    */
    toStringHours(): string {
        return iDate.cerosAdd(this.hour) + ":" + iDate.cerosAdd(this.minutes) + ":" + iDate.cerosAdd(this.seconds);
    }

    /**
     * Formatear fecha a diferentes formatos
     * @param {string} format Formato de la fecha: 
     * (EU) => "dd/mm/yyyy"
     * (USA) => "mm/dd/yyyy"
     * (JAP) => "yyyy/mm/dd"
     * @returns {string}
     */
    toStringDate(format: "EU" | "USA" | "JAP" = "EU"): string {
        let dateFormatted: string = ""
        switch (format) {
            case "EU":
                dateFormatted = iDate.cerosAdd(this.day) + "-" + iDate.cerosAdd(this.month) + "-" + this.year
                break;
            case "USA":
                dateFormatted = iDate.cerosAdd(this.month) + "-" + iDate.cerosAdd(this.day) + "-" + this.year
                break;
            case "JAP":
                dateFormatted = this.year + "-" + iDate.cerosAdd(this.month) + "-" + iDate.cerosAdd(this.day)
                break;
            default:
                console.error("Date format not valid");
                dateFormatted = iDate.cerosAdd(this.day) + "-" + iDate.cerosAdd(this.month) + "-" + this.year
                break;
        }
        return dateFormatted;
    }

    /**
     * Añadir un 0 delante del elemento si es menor que 10
     * @param {number} number  Número a convertir
     * @returns {string} 
     */
    static cerosAdd(number: number): string {
        let pad = "0";
        let length = 2;
        return (new Array(length + 1).join(pad) + number).slice(-length);
    }

    /**
    * Devolver la semana o semanas del año dada una fecha en función si el tipo de control es semanal o mensual
    * @param {date} date
    * @param {string} controlType Semana devuelve el numero de la semana || Mensual devuelve las semanas del mes 
    * @returns {(number|Array)}  Number si es semanas || Array si es mensual
    */
    static getWeek(date: Date, controlType: "weekly" | "monthly" = "weekly"): number | Array<number> {
        let week: any = null;
        var firstDayYear = new Date(date.getFullYear(), 0, 1);
        switch (controlType) {
            case "weekly":
                week = Math.floor((((date.getTime() - firstDayYear.getTime()) / 86400000) + firstDayYear.getDay() + 1) / 7)
                break;
            case "monthly":
                date.setDate(1);
                week = []
                var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                var firstWeek = Math.floor((((date.getTime() - firstDayYear.getTime()) / 86400000) + firstDayYear.getDay() + 1) / 7);
                var lastWeek = Math.floor((((lastDayOfMonth.getTime() - firstDayYear.getTime()) / 86400000) + firstDayYear.getDay() + 1) / 7)
                for (let weekLoop = firstWeek; weekLoop <= lastWeek; weekLoop++) {
                    week.push(weekLoop)
                }
                break;
            default:
                week = Math.floor((((date.getTime() - firstDayYear.getTime()) / 86400000) + firstDayYear.getDay() + 1) / 7)
                break;
        }
        return week;
    }

    /**
    * Devolver la semana o semanas del año dada una fecha obtenida de un objeto iDate en función si el tipo de control es semanal o mensual
    * @param {string} controlType - weekly -> devuelve el numero de la semana || monthly -> devuelve las semanas del mes 
    * @returns {(number|Array)} - number -> si es semanas || Array -> si es mensual
    */
    getWeek(controlType: "weekly" | "monthly" = "weekly"): number | Array<number> {
        return iDate.getWeek(this.toJavascript(), controlType);
    }

    /**
    * Devuelve un array de fechas en función de la cantidad de semanas desde el último Lunes a partir de una fecha dada
    * @param {number} quantity - Cantidad de días desde el último lunes  
    * @returns {(Array<Date>)} - Array de fechas
    */
    static getDaysByWeeks(quantity: number, date: Date): Array<Date> {
        var days: any = []
        var dates = (() => { return new Date(date!.setDate(date.getDate() - date.getDay() - -1)) })();
        return days = Array(quantity * 7).fill(dates).map((dates, i) => { if (i !== 0) { dates.setDate(dates.getDate() + 1); } return new Date(dates) });
    }

    /**
    * Devuelve un array de fechas en función de la cantidad de semanas desde el último Lunes a partir de un objeto iDate
    * @param {number} quantity - Cantidad de días desde el último lunes  
    * @returns {(Array<Date>)} - Array de fechas
    */
    getDaysByWeeks(quantity: number) {
        return iDate.getDaysByWeeks(quantity, this.toJavascript());
    }

    /**
    * Devuelve un array de fechas con la suma de dias en función de la cantidad dada a partir de una fecha
    * @param {number} quantity - Cantidad de días a sumar 
    * @returns {(Array<Date>)} - Array de fechas
    */
    static getDaysByQuantity(quantity: number, date: Date): Array<Date> {
        var days: any = []
        return days = Array(quantity).fill(date).map((dates, i) => { if (i !== 0) { dates.setDate(dates.getDate() + 1); } return new Date(dates) });
    }

    /**
    * Devuelve un array de fechas con la suma de dias en función de la cantidad dada a partir de un un objeto iDate
    * @param {number} quantity - Cantidad de días a sumar 
    * @returns {(Array<Date>)} - Array de fechas
    */
    getDaysByQuantity(quantity: number) {
        return iDate.getDaysByQuantity(quantity, this.toJavascript());
    }

    /**
    * Devuelve un array de fechas los días entre una fecha de inicio y una fecha de fin
    * @param {Date} starDate - Fecha de inicio
    * @param {Date} endDate - Fecha de fin 
    * @param {number} steps - Cantidad de pasos. Ej: steps = 2 -> "17/10/2022" - "19/10/2022"  
    * @returns {(Array<Date>)} - Array de fechas
    */
    static getDaysFromInterval(starDate: Date, endDate: Date, steps: number = 1): Array<Date> {
        const dateArray: Array<Date> = [];
        while (new Date(starDate) <= new Date(endDate)) {
            dateArray.push(new Date(starDate));
            starDate.setUTCDate(starDate.getUTCDate() + steps);
        }
        return dateArray;
    }

}