
export interface iHotspot {
    id: number,
    uuid?: string,
    content: Array<iHotspotData>,
    position:{x: number, y: number, z: number},
    obj?: any,
    title: string
}
export interface iHotspotData {
    id: number,
    title: string,
    type: eHotspotDataType,
    indice: number,
    content: any
}
export enum eHotspotDataType {
    VIDEO, AUDIO, PDF, IMAGE, TEXT, FORM
}
export class iHotspot implements iHotspot {
    static convertFrontCollection(ob): Array<iHotspot> {
        let iPlayerHotspotsDataCollection: Array<iHotspot> = [];
        ob.forEach(element => {
            let hotspot = new iHotspot();
            hotspot.id = element.id;
            hotspot.title = element.title;
            if(element.position instanceof Array){
                hotspot.position = {x: element.position[0], y: element.position[1], z:element.position[2]}
            }else{
                hotspot.position = {x: element.position.x, y: element.position.y, z:element.position.z}
            }
            hotspot.content = iHotspotData.convertFrontCollection(element.infospotContents)
            iPlayerHotspotsDataCollection.push(hotspot);
        });
        return iPlayerHotspotsDataCollection;
    }
}
export class iHotspotData implements iHotspotData{
    static empty(){
        return {
            id: -1,
            title: 'Untitled section',
            type: eHotspotDataType.TEXT,
            indice: -1,
            content: ''
        }
    }
    static convertFrontCollection(ob): Array<iHotspotData> {
        let iPlayerHotspotsDataCollection: Array<iHotspotData> = [];
        ob.forEach(element => {
            let hotspotData = new iHotspotData();
            hotspotData.id = element.id;
            hotspotData.title = element.title;
            hotspotData.type = element.type,
            hotspotData.indice = element.indice
            hotspotData.content = element.content
            iPlayerHotspotsDataCollection.push(hotspotData);
        });
        return iPlayerHotspotsDataCollection;
    }
}

export function hostpotDataTypeTransform(type:eHotspotDataType){
    let stringRet = '';
    switch (type) {
        case eHotspotDataType.VIDEO:
            stringRet='Video';
        break;
        case eHotspotDataType.AUDIO:
            stringRet='Audio';
        break;
        case eHotspotDataType.PDF:
            stringRet='Pdf';
        break;
        case eHotspotDataType.IMAGE:
            stringRet='Image';
        break;
        case eHotspotDataType.TEXT:
            stringRet='Text';
        break;
    }
    return stringRet;
}
