import { TokenManagmentService } from "@app/Services/Api/TokenManagment.service";

export class iStaticUtilities {
  /**
   * Convierte un base64 en un FormData para enviar a la base de datos
   * @param {string} base64 
   * @returns {FormData}
   */
  static base64ToFormData(base64) {
    let formData = new FormData();
    if (base64 instanceof Array) {
      for (let i = 0; i < base64.length; i++) {
        let extension = base64[i].split(";")[0].split("/")[1];
        let file = this.base64ToBlob(base64[i]);
        formData.append("upload" + i, file, "fichero" + i + "." + extension);
      }
    } else {
      let extension = base64.split(";")[0].split("/")[1];
      let file = this.base64ToBlob(base64);
      formData.append("upload", file, "fichero." + extension);
    }
    return formData;
  }

  /**
   * Convierte un base64 en un Blob
   * @param {string} base64 
   * @returns {Blob}
   */
  static base64ToBlob(base64) {
    let splitDataURI = base64.split(',')
    let byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    let mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    let ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  /**
   * Convierte un código de color hexadecimal a HSL (HSL es muy util para manejar tonalidades de un mismo color)
   * @param {string} hex - #157961
   * @returns {string} - hsl(166, 70%, 28%)
   */
  static hexToHSL(hex) {
    //H=H.replace("#","");
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (hex.length == 4) {
      r = +("0x" + hex[1] + hex[1]);
      g = +("0x" + hex[2] + hex[2]);
      b = +("0x" + hex[3] + hex[3]);
    } else if (hex.length == 7) {
      r = +("0x" + hex[1] + hex[2]);
      g = +("0x" + hex[3] + hex[4]);
      b = +("0x" + hex[5] + hex[6]);
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

    if (delta == 0)
      h = 0;
    else if (cmax == r)
      h = ((g - b) / delta) % 6;
    else if (cmax == g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
      h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(0);
    l = +(l * 100).toFixed(0);

    return "hsl(" + h + "," + s + "%," + l + "%)";
  }

  /**
   * Convierte un código de color HSL a hexadecimal
   * @param {number} h  - 166
   * @param {number} s  - 70
   * @param {number} l  - 28
   * @returns #157961
   */
  static HSLToHex(h, s, l) {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }
    // Having obtained RGB, convert channels to hex
    let rP = Math.round((r + m) * 255).toString(16);
    let gP = Math.round((g + m) * 255).toString(16);
    let bP = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (rP.length == 1)
      rP = "0" + r;
    if (gP.length == 1)
      gP = "0" + g;
    if (bP.length == 1)
      bP = "0" + b;

    return "#" + rP + gP + bP;
  }

  /** Normaliza un entero o un string aun boolean */
  static normalizeBoolean(object): boolean {
    if (typeof object == "boolean") { return object; }
    if (typeof object == "string") { return (object == "1" || object == "true") ? true : false; }
    if (typeof object == "number") { return (object == 1) ? true : false; }
    if (object == null) { return false; }
    return false;
  }

  /**
   * Aplica un capitalize a un string
   * @param {string} str - beaslBase
   * @returns Beaslbase
   */
  static toCapitalizeCase(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  /**
   * Mueve un elemento de un indice a otro indice dentro del mismo array
   * @param {Array} array - [1,2,3]
   * @param {number} old_index 1
   * @param {number} new_index 0
   * @returns [2,1,3]
   */
  static moveArray(array: Array<any>, old_index: number, new_index: number): Array<any> {
    if (new_index >= array.length) {
      var k = new_index - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(new_index, 0, array.splice(old_index, 1)[0]);
    return array; // for testing
  }

  /**
   * Normaliza las keys de un objeto para que respete un cammelCase
   * @param {Object} object - {id_tarea: 5, proyecto_id: 3, PAL:1467, BE_ASE:'asfas'}
   * @returns {Object} {idTarea: 5, idProyecto: 3, pal:1467, beAse:'asfas'}
   */
  static normalizeNames(object): any {
    let sendObj = {};
    let obj = Object.keys(object);
    for (let i = 0; i < Object.keys(object).length; i++) {
      let val = obj[i];
      //
      if (val.includes("_")) {
        let partes = val.split("_");
        if (val.toLocaleLowerCase().includes("id_") || val.toLocaleLowerCase().includes("_id")) {
          //Encontrar si está al principio o al final
          let indexID = -1;
          for (let i = 0; i < partes.length; i++) {
            if (partes[i] == "id") {
              indexID = i;
              partes[i] = partes[i].toLowerCase();
            } else {
              partes[i] = this.toCapitalizeCase(partes[i]);
            }
          }
          if (indexID != -1) {
            partes = this.moveArray(partes, indexID, 0);
          }
        } else {
          for (let i = 0; i < partes.length; i++) {
            if (i == 0) {
              partes[i] = partes[i].charAt(0).toLocaleLowerCase() + partes[i].slice(1);
            } else {
              partes[i] = this.toCapitalizeCase(partes[i]);
            }
          }
        }
        val = partes.join("");
      } else if (RegExp(/Id$/).test(val)) {
        val = val.replace(/Id$/, "").toLocaleLowerCase();
        val = "id" + this.toCapitalizeCase(val);
      } else {
        val = val.charAt(0).toLocaleLowerCase() + val.slice(1);
      }
      //
      sendObj[val] = object[obj[i]];
    }
    return sendObj;
  }

  /**
   * Normaliza las keys de un objeto dentro de un array para que respete un cammelCase
   * @param {Object} object - [{id_tarea: 5, proyecto_id: 3, PAL:1467, BE_ASE:'asfas'}]
   * @returns {Object} [{idTarea: 5, idProyecto: 3, pal:1467, beAse:'asfas'}]
   */
  static normalizeNamesArray(object){
    let normalizedArray: Array<any> = [];
    Object.keys(object).forEach(element => {
      normalizedArray.push(iStaticUtilities.normalizeNames(object[element]));
    });
    return normalizedArray;
  }

  /**
   * Para que el código no sea tan denso, está funciona comprueba si el valor no está vacío, si no lo es activa la función
   * @param {any} value - El valor que se quiere comprobar
   * @param {Function} fn - La función que se llamará si la comprobación sale verdadera
   * @param {Function|undefined} fnElse - Parametro opcional para una función cuando no está bien
   * @param {Array<string>} fnElse - Parametro opcional para poder definir variables extras que no sean iguales a
   */
  static isEmpty(value: any, fn: Function, fnElse?: Function, notEqualsExtra: Array<any> = []) {
    if (value != null) {
      if (typeof value == "string") {
        if (
          value != "" && 
          notEqualsExtra.filter(e=>value==e).length == 0
        ) { fn() }
        else { if (fnElse != null) { fnElse(); } }
      } else if (value instanceof Array) {
        if (value.length != 0) { fn(); }
        else { if (fnElse != null) { fnElse(); } }
      } else {
        if(notEqualsExtra.filter(e=>value==e).length == 0){
          fn();
        }
        else{ if (fnElse != null) { fnElse(); } }
      }
    } else {
      if (fnElse != null) { fnElse(); }
    }
  }

  /**
   * Función para esperar x milisegundos en una función async haciendo un await
   * @param {number} ms - Cantidad de milisegundos
   * @returns {Promise}
   */
  static sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  static getUserTypeToken() {
    let p: any = [];
    let roles: Array<any> = TokenManagmentService.getParseToken(localStorage.getItem("token")).roles;
    if (roles.includes("ROLE_TEACHER")) {
      p.push(1);
    } else if (roles.includes("ROLE_USER")) {
      p.push(0);
    }
    return JSON.stringify(p);
  }

  static isLoged() {
    let isLoged = false;
    if (localStorage.getItem("token") != null || localStorage.getItem("token") != undefined) {
      if (localStorage.getItem("userType") != null || localStorage.getItem("userType") != undefined) {
        if (localStorage.getItem("userType") == '[0]' || localStorage.getItem("userType") == '[1]') {
          isLoged = true;
        }
      }
    }
    return isLoged;
  }

  static isTeacher() {
    return localStorage.getItem("userType") == "[1]"
  }

}
