import { iBaseObject } from "./iBase";
import { iMedia } from "./iMedia";
import { iStaticUtilities } from "./iStaticUtilities";

export interface iCourse {
  id?: number,
  title: string,
  description: string,
  creationDate?: string,
  rating?: number,
  image?: string| {url:string} | null,
  isPublished?: boolean,
  type?: string,
  categories: Array<any>,
  media?: any,
  owner?: any,
  duration?: number,
  complete?: boolean,
  favorite?: boolean
}
export interface iStartedCourse {
  id: number,
  title: string,
  rating: number,
  qualification: number,
  idLastCompleteFrame: number,
  progess: number | null,
  duration: number,
  image: string | null,
  favorite?: boolean
}
export interface iCompletedCourses {
  id: number,
  title: string,
  qualification: number
}

export interface iPlayerCourse {
  id: number,
  media: Array<iMedia>,
  title: string,
  completed?: boolean
}

export class iCourse implements iCourse {
  static convertFrontCollection(ob): Array<iCourse> {
    let iCourseCollection: Array<iCourse> = [];
    ob.forEach(element => {
      let course = new iCourse();
      course.id = element.id;
      course.title = element.title;
      course.description = element.description;
      course.creationDate = element.creationDate;
      course.rating = element.rating,
        course.isPublished = iStaticUtilities.normalizeBoolean(element.isPublished);
      course.type = element.type;
      course.categories = element.category;
      course.media = element.idMedia;
      course.duration = element.duration;
      course.image = element.image;
      course.complete = element.complete;
      course.favorite = element.favorite
      iCourseCollection.push(course);
    });

    return iCourseCollection;
  }
  static convertFrontObject(ob): iCourse {
    let course = new iCourse();
    course.id = ob.id;
    course.categories = ob.categories;
    course.creationDate = ob.creationDate;
    course.description = ob.description;
    course.duration = ob.duration;
    course.isPublished = ob.isPublished;
    course.media = ob.media;
    course.owner = ob.owner;
    course.rating = ob.rating;
    course.title = ob.title;
    course.complete = ob.complete;
    course.favorite = ob.favorite

    if (ob.image != null) {
      course.image = ob.image.url
    } else {
      course.image = ob.image;
    }
    return course;
  }
}

export class iStartedCourse implements iStartedCourse {
  static convertFrontCollection(ob): Array<iStartedCourse> {
    let iCourseCollection: Array<iStartedCourse> = [];
    ob.forEach(element => {
      let startedCourse = new iStartedCourse();
      startedCourse.id = element.id;
      startedCourse.title = element.title;
      startedCourse.rating = element.rating;
      startedCourse.duration = element.duration;
      startedCourse.progess = Number(element.porcentaje);
      startedCourse.idLastCompleteFrame = element.idLastCompleteFrame;
      startedCourse.image = element.image;
      startedCourse.favorite = element.favorite
      iCourseCollection.push(startedCourse);
    });

    return iCourseCollection;
  }
  static convertFrontObject(ob): iStartedCourse {
    let startedCourse = new iStartedCourse();
    startedCourse.id = ob.id;
    startedCourse.title = ob.title;
    startedCourse.rating = ob.rating;
    startedCourse.duration = ob.duration;
    startedCourse.idLastCompleteFrame = ob.idLastCompleteFrame;
    startedCourse.progess = Number(ob.porcentaje);
    startedCourse.image = ob.image;
    startedCourse.favorite = ob.favorite
    return startedCourse;
  }
}

export class iCompletedCourses implements iCompletedCourses {
  static convertFrontCollection(ob): Array<iCompletedCourses> {
    let iCompletedCoursesCollection: Array<iCompletedCourses> = [];
    ob.forEach(element => {
      let completedCourse = new iCompletedCourses();
      completedCourse.id = element.id;
      completedCourse.title = element.title;
      completedCourse.qualification = element.qualification;
      iCompletedCoursesCollection.push(completedCourse);
    });
    return iCompletedCoursesCollection;
  }
}

export class iPlayerCourse implements iPlayerCourse {
  static convertFrontObject(ob): iPlayerCourse{
    let playerCourse = new iPlayerCourse();
    playerCourse.id = ob.id;
    playerCourse.title = ob.title;
    playerCourse.completed = ob.complete
    playerCourse.media = iMedia.convertFrontCollection(iStaticUtilities.normalizeNamesArray(ob.media));
    return playerCourse;
  }
}