import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'buttonsEditor',
  templateUrl: './buttonsEditor.component.html',
  styleUrls: ['./buttonsEditor.component.scss']
})
export class ButtonsEditorComponent implements OnInit {

  @Input() isSelected=false;
  @Input() image="/assets/Icons/plusWhite.svg";
  @Input() label="";
  @Input() isDisabled=false;
  @Input() isDropdown=false;
  @Input() dropdownColumnStyle=false;
  @Input() compactStyleBtn=false;
  @Output() action=new EventEmitter();
  opened=false;

  constructor() { }

  ngOnInit() {
  }

  @HostListener("document:mousedown", ['$event'])
  listen($event){
    let elementPath=$event.composedPath();
    //comprobar que sea iterable
    if(!elementPath){
      return;
    }
    for (let elem of elementPath) {
      if(elem.tagName == "BODY"){
        break;
      }
      if(elem.tagName == "ACTIONEDITOR"){
        break;
      }
      if(elem.classList.contains("pillContainer") && elem.classList.contains("selected")){
        return;
      }
    }
    this.opened=false;    
  }

  sendAction(){
    if(! this.isDisabled){
      if(this.isDropdown){
        this.opened=!this.opened;
      }
      this.action.emit();
    }
  }

}
