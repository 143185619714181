import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { iFile } from '@app/Shared/Interfaces/iFile';

@Component({
  selector: 'app-UploadFilePopup',
  templateUrl: './UploadFilePopup.component.html',
  styleUrls: ['./UploadFilePopup.component.scss']
})
export class UploadFilePopupComponent implements OnInit {
  type:String = "";
  pillName: String = "";
  file:iFile = {base64: "", name: "", type: ""};
  fileName:String = "";
  fileFormat:String = "";
  isLoadingFile=false;
  constructor(public dialogRef: MatDialogRef<UploadFilePopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    this.type = this.modalData.type;
    this.pillName = this.modalData.name
    if (this.modalData.image != undefined) {
      this.file = this.modalData.image
      this.fileName = String(this.file.name)
      this.fileFormat = String(this.file.type)
      
    }
    if (this.modalData.video != undefined) {
      this.file = this.modalData.video
      this.fileName = String(this.file.name)
      this.fileFormat = String(this.file.type)
    }
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
  setFile(file:any){
    this.changeLoading();
    this.fileName = file[0].name
    this.file = file[0]
    this.fileFormat = file[0].type
  }
  checkIfFormatIsValid(format:String){
    var valid = true;
    if (this.type == "Video") {
      if (format != "video/mp4") {
        valid = false;
      }
    }
    if (this.type == "Image") {
      if (format != "image/jpeg" && format != "image/png") {
        valid = false;
      }
    }
    return valid;
  }
  changeLoading(){
    this.isLoadingFile = !this.isLoadingFile;
  }
  getAccept():Array<string>{
    if(this.type=='Image'){
      return ['image/jpeg', 'image/png'];
    }else if(this.type=='Video'){
      return ['video/mp4'];
    }
    return [];
  }
}
