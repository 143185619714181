import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/Services/Api/Login.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private loginSE: LoginService) { }

  ngOnInit() {
  }

  logOut(){
    this.loginSE.logOut();
  }
}
