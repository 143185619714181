import { iStaticUtilities } from "./iStaticUtilities";

export interface iCategory{
    id: number,
    name: string,
    type: string
}
export class iCategory implements iCategory{
    
  static convertFrontCollection(ob): Array<iCategory> {
    let iCourseCollection: Array<iCategory> = [];
    ob.forEach(element => {
      iCourseCollection.push(this.convertFrontObject(element));
    });

    return iCourseCollection;
  }
  static convertFrontObject(ob): iCategory {
    let startedCourse = new iCategory();
    let obStandard = iStaticUtilities.normalizeNames(ob);
    startedCourse.id = obStandard.id;
    startedCourse.name = obStandard.name;
    startedCourse.type = obStandard.type;
    return startedCourse;
  }
}