import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeConversor'
})
export class TimeConversorPipe implements PipeTransform {

  transform(value: any): any {

    return this.getMinutes(value/60) +":"+  this.getSeconds(value/60);
  }
  getSeconds(data: number): any{
    let seconds = data.toFixed(2).substring(2)
    return seconds;
  }
  getMinutes(data: number):any{
    let minutes = this.addZeros(Math.floor(data))
    return minutes;
  }
  addZeros(data: number){
    let dataConverted = ""
    if (data < 10) {
      dataConverted = "0"+data
    }else{
      dataConverted = String(data);
    }
    return dataConverted;
  }
}
