import { Component, OnInit } from '@angular/core';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';

@Component({
  selector: 'app-MyCourses',
  templateUrl: './MyCourses.component.html',
  styleUrls: ['./MyCourses.component.scss']
})
export class MyCoursesComponent implements OnInit {

  navigationItems:Array<String> = ["Created courses", "Platform courses", "Alumni"]
  navigationItemActive:Number = 1;
  isTeacher:boolean = iStaticUtilities.isTeacher();
  constructor() { }

  ngOnInit() {
  }

  pageActive(event:Number){
    this.navigationItemActive = event;
  }
}
