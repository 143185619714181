import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { DefaultBaseService } from './DefaultBase.service';

@Injectable({
  providedIn: 'root'
})
export class MediaCoursesService extends DefaultBaseService {
  
  res: iResultHttp = { status: -1, ok: false, message: "", data: undefined }

  constructor(private http: HttpClient) { 
    super();
  }

  edit(course,pill){
    let formData=new FormData();
    formData.append('url',pill.file.file);
    formData.append('course',course);
    formData.append('title',pill.name);
    this.http.put(this.urlApi + '/api/media/'+pill.id, formData, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: "Successfully logged in", data:data["data"]};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.edit, args: [course,pill] });
        this.checkStatusError(error);
      },
    });
  }

  create(course,pill){
    let formData=new FormData();
    formData.append('url',pill.file.file);
    formData.append('course',course);
    formData.append('title',pill.name);
    formData.append('indice',pill.indice);
    this.http.post(this.urlApi + '/api/media', formData, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: "Successfully logged in", data:data["data"]};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [course,pill] });
        this.checkStatusError(error);
      },
    });

  }
  delete(IDs: Array<number|string>){
    let idsObjs = this.convertArrayObject(IDs, "media_ids");
    this.http.delete(this.urlApi+"/api/media", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data};
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [IDs] });
        this.checkStatusError(error);
      },
    });
  }

  addUserMedia(progress){
    this.http.post(this.urlApi + '/api/user/media/progress', progress, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: ""};
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.addUserMedia, args: [progress] });
        this.checkStatusError(error);
      },
    });
  }
  getCompletedMedias(courseId){
    this.http.get(this.urlApi + '/api/user/media/completed/'+courseId, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: "", data: data};
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getCompletedMedias, args: [courseId] });
        this.checkStatusError(error);
      },
    });
  }
}
