<ng-container *ngIf="typeOfPill == 'filterPill'">
  <div class="filterPill">
    <div>
      <span>
        {{dataFilterPill.grade}}
      </span>
    </div>
    <div>
      <span>
        {{dataFilterPill.countPeople}}
      </span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="typeOfPill != 'filterPill'">
  <div class="userPill">
    <div>{{dataUserPill.split("")[0].charAt(0).toUpperCase()}}{{dataUserPill.split("")[1].charAt(0).toUpperCase()}}</div>
    <span>{{dataUserPill}}</span>
  </div>
</ng-container>