import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { ITeacherAlumni, ITeacherGrades } from '@app/Shared/Interfaces/iTeacher';
import { environment } from 'src/environments/environment';
import { DefaultBaseService } from './DefaultBase.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherService extends DefaultBaseService{

  res: iResultHttp = { status: -1, ok: false, message: "", data: undefined }

  constructor(private http: HttpClient) {
    super();
  }

  getGrades(){
    this.http.get(this.urlApi + '/api/user/teacher/grades/', this.getHeader()).subscribe({
      next: (data) => {
        let normalizedTeacherGrades: Array<ITeacherGrades> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: ITeacherGrades.convertFrontCollection(normalizedTeacherGrades)};
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getGrades, args: [] });
        this.checkStatusError(error);
      },
    });
  }
  getAlumnis(){
    this.http.get(this.urlApi + '/api/user/teacher/alumni/', this.getHeader()).subscribe({
      next: (data) => {
        let normalizedTeacherAlumnis: Array<ITeacherAlumni> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: ITeacherAlumni.convertFrontCollection(normalizedTeacherAlumnis)};
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getAlumnis, args: [] });
        this.checkStatusError(error);
      },
    });
  }
}
