<div class="returnContainer" *ngIf="!isLoadingData && lastRoute != null" [routerLink]="[lastRoute]">
  <mat-icon>keyboard_backspace</mat-icon>
  <span>Back to</span>
</div>
<div class="returnContainer" *ngIf="!isLoadingData && lastRoute == null" [routerLink]="['/discover']">
  <mat-icon>keyboard_backspace</mat-icon>
  <span>Back to</span>
</div>

<div class="canvasContainer">
  <div class="loaderContainer" *ngIf="isLoadingData">
    <iconLoader></iconLoader>
    <p>Loading media, this may take a while...</p>
  </div>
  <p class="noData" *ngIf="course.media.length == 0 && !isLoadingData">There are no media for this course yet.</p>

</div>

<div class="timelineContainer" *ngIf="!isLoadingData && course['media'].length != 0" (click)=" $event.stopPropagation()" [class.expanded]="timelineExpanded" >
  <div class="timelineControlsContainer">
    <div class="timelineControlsContainer_left">
      <button type="button" (click)="controlVideo()" class="playPause" *ngIf="course.media[actualMediaIndex].type != 'Image'">
        <ng-container *ngIf="!played">
          <mat-icon>play_arrow</mat-icon>
          Play course
        </ng-container>
        <ng-container *ngIf="played">
          <mat-icon>pause</mat-icon>
          Pause course
        </ng-container>
      </button>
      <span *ngIf="course.media[actualMediaIndex].type != 'Image'" ><b>{{actualPanorama.options.videoElement.currentTime | timeConversor}}/{{actualPanorama.options.videoElement.duration | timeConversor}}</b></span>
      <span>Media {{this.actualMediaIndex+1}}: <b>{{this.course.media[actualMediaIndex].title}}</b></span>
    </div>
    <div class="timelineControlsContainer_right">
      <button type="button" class="frameControl" (click)="previousFrame()" *ngIf="course.media[actualMediaIndex].type != 'Image'">
        <mat-icon>skip_previous</mat-icon>
        Previous frame
      </button>
      <button type="button" class="frameControl" (click)="nextFrame()" *ngIf="course.media[actualMediaIndex].type != 'Image'">
        Next frame
        <mat-icon>skip_next</mat-icon>
      </button>
      <button type="button" class="hamburguer" (click)="timelineExpanded = true;" *ngIf="!timelineExpanded">
        <mat-icon>dehaze</mat-icon>
      </button>
      <button type="button" class="hamburguer" (click)="timelineExpanded = false;lateralScrollingWithWheel();$event.stopPropagation();"  *ngIf="timelineExpanded">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="sliderContainer"  *ngIf="course.media[actualMediaIndex].type != 'Image'">
    <img src="/assets/Icons/frame.svg" [ngStyle]="{'left': ((((frame.seconds! * 100)/actualPanorama.options.videoElement.duration)))+'%'}" *ngFor="let frame of course.media[actualMediaIndex].frames" alt="">
    <mat-slider [max]="actualPanorama.options.videoElement.duration" step="0.1" [value]="actualPanorama.options.videoElement.currentTime" (input)="moveIntoVideo($event.value);"></mat-slider>
  </div>
  
  <div class="mediaLibContainer">
    <div class="mediaLibHeader">
      <div class="info">
        <span>{{course.title}}</span>
        <mat-icon>keyboard_arrow_right</mat-icon>
        <span>{{course.media[actualMediaIndex].title}}</span>
      </div>
      <button *ngIf="course.media[actualMediaIndex].type != 'Image'" class="playPause" (click)="resetVideo()">
        <mat-icon>loop</mat-icon>
        Play from start
      </button>
    </div>
    <div class="mediaSelector">
      <div class="media" *ngFor="let media of course.media; let i = index" [class.active]="actualMediaIndex == i" (click)="changeMedia(i)"  tooltip [text]="media.title">
        <span *ngIf="!completedMedias.includes(media.id)">{{media.title}}</span>
        <span *ngIf="completedMedias.includes(media.id)">
          <mat-icon>check_circle_outline</mat-icon>
          {{media.title}}
        </span>
      </div>
    </div>
    <div class="framesContainer">
      <p class="noData" *ngIf="course.media[actualMediaIndex].frames.length == 0">No frames available yet</p>
      <div class="frame" *ngFor="let frame of course.media[actualMediaIndex].frames; let i = index;">
        <div class="frame_left">
          <div>
            <div class="ball"></div>
            <span>Frame {{frame.id}}</span>
          </div>
          
          <span>{{frame.title}}</span>
        </div>
        <div class="frame_right">
          <span *ngIf="!completedFrames.includes(frame.id)">Not completed</span>
          <span *ngIf="completedFrames.includes(frame.id)">
            <mat-icon>done</mat-icon>
            Completed
          </span>
          <span (click)="moveIntoVideo(frame.seconds); activeFrame = i" *ngIf="course.media[actualMediaIndex].type != 'Image'">
            <mat-icon>play_arrow</mat-icon>
            Play
          </span>
          <span *ngIf="course.media[actualMediaIndex].type != 'Image'">
            <b>{{frame.seconds | timeConversor}}</b>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="infospotElem" #infospotEl style="display:none">
</div>

