<div class="pillContainer" [ngClass]="{selected: (isSelected||opened), disabled: isDisabled, compactStyleBtn:compactStyleBtn}">
  <button (click)="sendAction();$event.stopPropagation()" class="imageContainer" [ngClass]="{selected: (isSelected||opened)}">
    <div class="image">
      <span>
        <img [src]="image" alt="">
      </span>
    </div>
    <span class="pillEtiqueta">{{label}}</span>
    <i class="material-icons" *ngIf="isDropdown">expand_more</i>
  </button>
  <div class="pillSelectTypePillContainer" [ngClass]="{opened: opened, dropdownColumnStyle: dropdownColumnStyle}">
    <ng-content></ng-content>
  </div>
</div>