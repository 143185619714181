import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iFrame } from '@app/Shared/Interfaces/iFrame';
import { iHotspot, iHotspotData } from '@app/Shared/Interfaces/iHotspot';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { DefaultBaseService } from './DefaultBase.service';

@Injectable({
  providedIn: 'root'
})
export class FramesService extends DefaultBaseService {

  res: iResultHttp = { status: -1, ok: false, message: "", data: undefined }

  constructor(private http: HttpClient) {
    super();
  }
  create(frame:iFrame){
    this.http.post(this.urlApi+"/api/frame", frame, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data["data"]};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [frame] });
        this.checkStatusError(error);
      },
    });
  }
  update(frame:iFrame){
    this.http.put(this.urlApi+"/api/frame/"+frame.id, frame, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data["data"]};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [frame] });
        this.checkStatusError(error);
      },
    });
  }
  delete(IDs: Array<number|string>){
    let idsObjs = this.convertArrayObject(IDs, "frames_ids");
    this.http.delete(this.urlApi+"/api/frame", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data["data"]};
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [IDs] });
        this.checkStatusError(error);
      },
    });
  }
}
