import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@app/Services/Api/User.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { ICategoriesStatistics, IGradesStatistics } from '@app/Shared/Interfaces/iStatistics';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-AlumniDataPopup',
  templateUrl: './AlumniDataPopup.component.html',
  styleUrls: ['./AlumniDataPopup.component.scss']
})
export class AlumniDataPopupComponent extends iUnsubscribeDestroy implements OnInit {
  elementActive:Number = 0
  navigationItems:Array<String> = ["All the grades", "Top categories"]
  gradesStatistics: Array<IGradesStatistics> = []
  categoriesStatistics:  Array<ICategoriesStatistics> = []
  dataIsLoaded: boolean = false;

  constructor(public dialogRef: MatDialogRef<AlumniDataPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private userSE: UserService, private notifications: NotificacionesService) {
    super();
  }

  ngOnInit() {
    this.getGradesStatistics();
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  pageActive(event:Number){
    this.elementActive = event;
  }
  //LLAMADAS A LA API
  getCategoriesStatistics(){
    this.userSE.getCategoriesStatistics(this.modalData.id);
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.dataIsLoaded = true;
      this.categoriesStatistics = res.data
      this._unsubInd.next("")
    })
  }
  getGradesStatistics(){
    this.userSE.getGradesStatistics(this.modalData.id);
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.gradesStatistics = res.data
      this._unsubInd.next("")
      setTimeout(() => {
        this.getCategoriesStatistics();
      }, 500);
    })
  }
}
