import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iCourse, iPlayerCourse } from '@app/Shared/Interfaces/iCourse';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DefaultBaseService } from './DefaultBase.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesService extends DefaultBaseService{
  _resultRelatedCourses = new BehaviorSubject<any>(null);
  res: iResultHttp = { status: -1, ok: false, message: "", data: undefined }

  
  protected _resultForPlayer=new BehaviorSubject<any>(null);
  getResultForPlayer(){return this._resultForPlayer;}
  clearResultForPlayer(){this._resultForPlayer.next(null);}

  constructor(private http: HttpClient) {
    super();
  }

  getByFilter(name: string = "",num_devoluciones : number, num_pagina : number = 1, category:string = "" ,video:boolean = true, image: boolean = true, sort:string = "recent"){
    this.http.get(this.urlApi + '/api/courses/filter/'+"?name="+name+"&num_devoluciones="+num_devoluciones+"&num_pagina="+num_pagina+"&category="+category+"&video="+video+"&image="+image+"&sort="+sort, this.getHeader()).subscribe({
      next: (data) => {
        let normalizedCourses: Array<iCourse> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: iCourse.convertFrontCollection(normalizedCourses)};     
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        console.log(num_devoluciones, 1, category, video, image, sort);
        this.sendNextResult('resultError', this.res, { method: this.getByFilter, args: [name,num_devoluciones, 1, category, video, image, sort] });
        this.checkStatusError(error);
      },
    });
  }
  getSingle(id: number){
    this.http.get(this.urlApi + '/api/courses/'+id, this.getHeader()).subscribe({
      next: (data) => {
        let normalizedCourse = iStaticUtilities.normalizeNames(data);
        this.res = { status: 200, ok: true, message: "", data: iCourse.convertFrontObject(normalizedCourse)};     
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultIndividualError', this.res, { method: this.getSingle, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getRelated(id:number){
    this.http.get(this.urlApi + '/api/courses/related/'+id+'?num_devoluciones=4&num_pagina=1', this.getHeader()).subscribe({
      next: (data) => {
        let normalizedCourses: Array<iCourse> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: iCourse.convertFrontCollection(normalizedCourses)};   
        this.customSendNextResult('resultRelatedCourses', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getRelated, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getCourseForPlayer(id: number){
    this.http.get(this.urlApi + '/api/courses/player/'+id, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: iPlayerCourse.convertFrontObject(data)};   
        this._resultForPlayer.next(this.res);
        setTimeout(()=>{
          this.clearResultForPlayer();
        },150);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getCourseForPlayer, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  getLastCompleteFrame(courseId: number, mediaId: number){
    this.http.get(this.urlApi + '/api/courses/lastframe/'+courseId+"/"+mediaId, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data};   
        this.sendNextResult('resultIndividual', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultIndividualError', this.res, { method: this.getLastCompleteFrame, args: [courseId] });
      }
    });
  }
  create(course:iCourse){
    if(typeof course.image == "string" && course.image.startsWith("data:image")){
      course.image = { url: course.image }
    }
    this.http.post(this.urlApi + '/api/courses', course, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: data["message"], data: iCourse.convertFrontObject(data["data"])};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [course] });
        this.checkStatusError(error);
      },
    });
  }
  completeCourse(data:any){
    this.http.post(this.urlApi + '/api/user/qualification', data, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: data["message"]};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.completeCourse, args: [data] });
        this.checkStatusError(error);
      },
    });
  }
  publishCourse(courseId: number){
    this.http.put(this.urlApi + '/api/courses/publish/'+courseId,{},this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: data["message"], data: ""};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.publishCourse, args: [courseId] });
        this.checkStatusError(error);
      },
    });
  }
  edit(course:iCourse){
    if(typeof course.image == "string" && course.image.startsWith("data:image")){
      course.image = { url: course.image }
    }
    this.http.put(this.urlApi + '/api/courses/'+course.id, course, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: data["message"], data: iCourse.convertFrontObject(data["data"])};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res= { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.edit, args: [course] });
        this.checkStatusError(error);
      },
    });
  }
  customSendNextResult(type: 'resultRelatedCourses', data: any) {
    let clearFuntion: Function;
    if (type == 'resultRelatedCourses') {
      this._resultRelatedCourses.next(data);
      clearFuntion = this.clearResultRelated;
    } 
    setTimeout(() => {
      this[clearFuntion.name]();
    }, 150);
  }
  getResultRelated() { return this._resultRelatedCourses; }
  clearResultRelated() { this._resultRelatedCourses.next(null); }
}
