<header></header>
<div class="courseDetailsGeneralContainer">
  <div class="courseDetailsHeader">
    <div>
      <button [routerLink]="['/discover']">
        < Atrás</button>
          <p *ngIf="dataIsLoaded">{{course.title}}</p>
          <p *ngIf="!dataIsLoaded">Loading title...</p>
    </div>
    <div>
      <!-- <button (click)="addFavourites(); $event.stopPropagation()"  *ngIf="!data.favorite">
        <img src="/assets/Icons/botonMas.svg" alt="">
      </button>
      <button (click)="removeFavourites(); $event.stopPropagation()" [class.favorite]="data.favorite"   *ngIf="data.favorite">
        <img src="/assets/Icons/favorito.svg" alt="">
      </button> -->
    </div>
  </div>
  <div class="courseDetailsBody">
    <div>
      <img onerror="this.src='/assets/Images/Images/placeholder.jpg';" [src]="urlImages+course.image" alt="">
    </div>
    <div *ngIf="dataIsLoaded">
      <span>Created by:
        <u *ngIf="dataIsLoaded"> {{course.owner.name}} {{course.owner.surname}}</u>
      </span>
      <div>
        <span>Duration:
          <ng-container *ngIf="dataIsLoaded">
            {{course.duration}} minutes
          </ng-container>
        </span>
        <span>Added on
          <ng-container *ngIf="dataIsLoaded">
            {{ course.creationDate | date: 'MMMM' }} {{ course.creationDate | date: 'YYYY' }}
          </ng-container>
        </span>
      </div>
      <div class="categoriesContainer">
        <ng-container *ngIf="dataIsLoaded">
          <div *ngFor="let category of course.categories">
            <span>{{category.name}}</span>
          </div>
        </ng-container>
      </div>
      <p *ngIf="dataIsLoaded">{{course.description}}</p>
      <button type="button" [routerLink]="['/course-visualicer/'+courseId]">
        <img src="../../../assets/Icons/playIcon.svg" alt="">
        <span *ngIf="!course.complete">Start course</span>
        <span *ngIf="course.complete">Re-visualise course</span>
      </button>
    </div>
    <div *ngIf="!dataIsLoaded">
      <iconLoader class="centered"></iconLoader>
    </div>
  </div>
  <p>Related courses</p>
  <div class="relatedCoursesContainer">
    <div *ngIf="!dataIsLoaded">
      <iconLoader class="centered"></iconLoader>
    </div>
    <ng-container *ngIf="dataIsLoaded">
      <p *ngIf="relatedCourses.length == 0" class="noData">No related courses available</p>
      <card [size]="'big'" [id]="'r'+i" [data]="courses" (updateCourses)="reloadCourses()" (click)="reloadData()"
        (mousemove)="rotateToMouse($event, i, 'r')" (mouseleave)="clearPosition(i, 'r')"
        *ngFor="let courses of relatedCourses; let i = index"></card>
    </ng-container>
  </div>
</div>
<footer></footer>