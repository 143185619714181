import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iHotspot, iHotspotData } from '@app/Shared/Interfaces/iHotspot';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { DefaultBaseService } from './DefaultBase.service';

@Injectable({
  providedIn: 'root'
})
export class ContentInfospotService extends DefaultBaseService {

  res: iResultHttp = { status: -1, ok: false, message: "", data: undefined }

  constructor(private http: HttpClient) {
    super();
  }

  create(hotspotContent:any){
    let formData=new FormData();
    if(typeof hotspotContent.content == "string"){
      formData.append('content',hotspotContent.content);
    }else{
      formData.append('content',hotspotContent.content[0].file);
    }
    formData.append('infospot',hotspotContent.infospot);
    formData.append('title',hotspotContent.title);
    formData.append('indice',hotspotContent.indice);
    formData.append('type',hotspotContent.type);
    this.http.post(this.urlApi+"/api/infospot/content", formData, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [hotspotContent] });
        this.checkStatusError(error);
      },
    });
  }
  update(hotspotContent:any){
    let formData=new FormData();
    if(typeof hotspotContent.content == "string"){
      formData.append('content',hotspotContent.content);
    }else{
      formData.append('content',hotspotContent.content[0].file);
    }
    formData.append('infospot',hotspotContent.infospot);
    formData.append('title',hotspotContent.title);
    formData.append('indice',hotspotContent.indice);
    formData.append('id',hotspotContent.id);
    formData.append('type',hotspotContent.type);
    this.http.put(this.urlApi+"/api/infospot/content/"+hotspotContent.id, formData, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [hotspotContent] });
        this.checkStatusError(error);
      },
    });
  }
  delete(IDs: Array<number|string>){
    let idsObjs = this.convertArrayObject(IDs, "infospotContents_ids");
    this.http.delete(this.urlApi+"/api/infospot/content", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data};
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [IDs] });
        this.checkStatusError(error);
      },
    });
  }
}
