import { Injectable } from '@angular/core';
import { slideNavigationFunctions, slideNavigationTypes } from '@app/Shared/Interfaces/eSliderNavigationTypes';

@Injectable({
  providedIn: 'root'
})
export class AnimationControllerService {

  currentAnimation: any = slideNavigationFunctions.convertEnumToString(slideNavigationTypes.slideToTop);
  currentAnimationId:slideNavigationTypes = slideNavigationTypes.slideToTop;
  public animations: any;

  constructor() {  }
  
  /**
   * Cambia la animación de la próxima navegación de la ruta
   * @param {slideNavigationTypes} animationId - Animación
   */
  setCurrentAnimation(animationId:slideNavigationTypes) {
    let nextAnimation = "";
    let isDuplicate = false;
    if(typeof animationId == "string"){
      animationId = slideNavigationFunctions.convertStringToEnum(animationId);
    }

    nextAnimation = slideNavigationFunctions.convertEnumToString(animationId)

    if(this.currentAnimation && (this.currentAnimation.indexOf("Duplicate") > -1)) {
      isDuplicate = true;
    }

    // Si es la misma animación y se duplica, no funcionará
    if((animationId == this.currentAnimationId) && !isDuplicate) {
        nextAnimation = nextAnimation + "Duplicate";
    }
    this.currentAnimation = nextAnimation;
    this.currentAnimationId = animationId;
  }

  /**
   * Devuelve el string actual de la animación
   * @returns {string}
   */
  getCurrentAnimation() {
    return this.currentAnimation;
  }

}
