<ng-container *ngIf="pill!=null">
  <div class="pill" [ngClass]="{selected: isSelected}" [attr.tabindex]="(pill.file!=null && getFile() != '')? 0 : null" (click)="sendClick()" (keypress)="sendClick()">
    <div>
      <div>
        <img src="/assets/Icons/videoWhite.svg" alt="" *ngIf="pill.type == 'Video'">
        <img src="/assets/Icons/imageWhite.svg" alt="" *ngIf="pill.type == 'Image'">
      </div>
      <p>{{pill.name}}</p>
    </div>
    <button class="uploadFileContainer" (click)="sendAddFile();$event.stopPropagation()" *ngIf="isEmptyFile()">
      <span>Upload your file</span>
    </button>
    <!-- UPLOADED FILE -->
    <ng-container *ngIf="getFile() != ''">
      <div class="imageMiniatureContainer" *ngIf="pill.type == 'Image'" [ngStyle]="{'background': 'url('+getFile()+')'}"></div>
      <ng-container *ngIf="pill.type == 'Video'">
        <div class="videoMiniatureContainer">
          <div class="loadingMiniature" *ngIf="getMiniature() == null">
            <iconLoader size="small"></iconLoader>
            <span>Loading...</span>
          </div>
          <img *ngIf="getMiniature() != null" [src]="getMiniature() | safe" alt="">
          <button class="expandButton" (click)="sendToggleExpand(); $event.stopPropagation();" [ngClass]="{constrain: isExpanded}">
            <span>{{isExpanded ? 'Constrain' : 'Expand' }}</span>
            <img src="/assets/Icons/downArrowIcon.svg" alt="">
          </button>
        </div>

      </ng-container>
    </ng-container>
    <!-- UPLOADED FILE -->
  </div>
  <!-- EXPANDED PILL -->
  <ng-container *ngIf="isExpanded">
    <div class="frameContainer" [ngClass]="{opened: isExpanded, empty:pill.frames.length == 0}">
      <p *ngIf="pill.frames.length == 0">
        You have not set any frame yet.
      </p>
      <div class="frame" *ngFor="let frame of pill.frames; let i = index" (click)="sendSelectFrame(frame)" [class.frameActive]="selectedFrame.id == frame.id "><!--&& framePropertiesOpen-->
        <img src="/assets/Icons/frameSeparator.svg" alt="">
        <img src="/assets/Icons/frameIconColor.svg" alt="">
        <span>Frame {{frame.seconds}}: {{frame.title}}</span>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="pill==null"><h3>Error in code</h3></ng-container>