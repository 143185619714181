<div class="registerGeneralContainer" [class.registerForm]="!validRegister">
  <div class="formGeneralContainer"*ngIf="!validRegister">
    <img src="../../../assets/Images/Logos/logoWithTextLogin360Visi.png" alt="">
    <p>Sign up in our platform.</p>
    <p>Create and account and access to our knowledge.</p>
    <form action="">
      <input [class.error]="nameError" class="inputData" type="text" name="name" placeholder="Name" [(ngModel)]="name">
      <input [class.error]="surnameError" class="inputData" type="text" name="surname" placeholder="Surname" [(ngModel)]="surname">
      <div class="phoneContainer">
        <CountrySelector (emitCountry)="setPhoneCode($event)"></CountrySelector>
        <input  [class.error]="phoneNumberError" class="inputData" type="Number" name="phoneNumber" placeholder="Phone number" [(ngModel)]="phoneNumber" >
      </div>
      <input  [class.error]="emailError" class="inputData" type="email" name="email" placeholder="E-Mail" [(ngModel)]="email">
      <div class="dobleContainer">
        <input [class.error]="passwordError" class="inputData" type="password" name="password" placeholder="Password" [(ngModel)]="password">
        <input [class.error]="repeatPasswordError"  class="inputData" type="password" name="confirmPassword" placeholder="Confirm your password" [(ngModel)]="repeatPassword">
      </div>
      <div class="typeOfUserContainer">
        <div [class.selected]="userType == 'ROLE_USER'" (click)="userType = 'ROLE_USER'"><span>I am student</span></div>
        <div [class.selected]="userType == 'ROLE_TEACHER'" (click)="userType = 'ROLE_TEACHER'"><span>I am teacher</span></div>
      </div>
      <mat-checkbox [(ngModel)]="termsAccepted" name="termsAccepted" class="register">I accept the terms and conditions for this website.</mat-checkbox>
      <button class="btn-login-register" (click)="validateRegister()" type="submit" [class.disabled]="!termsAccepted">Create account</button>
    </form>
    <span>Do you already have an account? - <a [routerLink]="['/']" >Log in</a></span>
  </div>
  <div *ngIf="validRegister" class="accountSuccefulyCreatedContainer">
    <p>Account successfully created.</p>
    <p>Review your email and confirm your email.</p>
    <button class="btn-login-register" type="button" [routerLink]="['/']">Finish</button>
  </div>
</div>
