import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'toggle',
  providers : [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleComponent),
    multi: true
  }],
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements ControlValueAccessor, OnInit {

  /** Para controlar el tamaño, admite cualquier string para que se le pueda poner custom. Por defecto exiten: normal y big */
  @Input() size:string="normal";

  //INTERNAL VALUES
  private _onChange = (_: any) => {};
  private _onTouched = () => { };
  _disabled;
  model=false;

  constructor() { }

  /**
   * Controlar la actualización del ngModel desde el componente
   * @param {any} obj - Dato que se actualiza
   */
  writeValue(obj: any): void {
    this.model=obj;
  }
  
  /**
   * Controla automáticamente el estado disabled
   * @param {boolean} isDisabled - Cambiar el estado del disabled
   */
  setDisabledState?(isDisabled: boolean): void {
    this._disabled=isDisabled;
  }

  /**
   * Registrar el onChange del ngModel. Actualiza el dato desde el componente, es muy parecido al EventEmitter
   * @param {Fuction} fn - Función onChange
   */
  registerOnChange(fn: any): void {
    this._onChange=fn;
  }

  /**
   * Registra el onTouched. Es muy parecido al EventEmitter
   * @param {Fuction} fn - Funcion onTouched
   */
  registerOnTouched(fn: any): void {
    this._onTouched=fn;
  }

  ngOnInit() {
  }

  /**
   * Detecta cuando cambia el ngModel del elemento interior
   * @param {any} $event - El valor actualizado
   */
  onModelChange($event){
    this.model=$event;
    this._onChange($event);
  }

}
