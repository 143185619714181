<div class="alumniDataGeneralContainer">
  <div class="headerPopup">
    <span>{{modalData.name}}</span>
    <div class="closeContainer" (click)="forceClosePopup()">
      <img src="../../../assets/Icons/closeIconBlack.svg" alt="">
    </div>
  </div>
  <div class="bodyPopup">
    <IntraPageNavegation [navigationItems]="navigationItems" [elementActive]="elementActive"
      (selectedElement)="pageActive($event)"></IntraPageNavegation>
    <div>
      <ng-container *ngIf="elementActive == 0">
        <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
        <p class="noData" *ngIf="gradesStatistics.length == 0 && dataIsLoaded">No data available yet</p>
        <GradesChart [data]="gradesStatistics" *ngIf="dataIsLoaded && gradesStatistics.length !=0"></GradesChart>
      </ng-container>
      <ng-container *ngIf="elementActive == 1">
        <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
        <p class="noData" *ngIf="categoriesStatistics.length == 0 && dataIsLoaded">No data available yet</p>
        <CategoriesChart [data]="categoriesStatistics" *ngIf="dataIsLoaded && categoriesStatistics.length !=0"></CategoriesChart>
      </ng-container>

    </div>
  </div>
</div>