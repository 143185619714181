import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmPopupComponent } from '@app/Popups/ConfirmPopup/ConfirmPopup.component';
import { InfoSpotContentComponent } from '@app/Popups/InfoSpotContent/InfoSpotContent.component';
import { CoursesService } from '@app/Services/Api/Courses.service';
import { LoginService } from '@app/Services/Api/Login.service';
import { MediaCoursesService } from '@app/Services/Api/MediaCourses.service';
import { UserService } from '@app/Services/Api/User.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { PopupService } from '@app/Services/Utils/popup.service';
import { iPlayerCourse } from '@app/Shared/Interfaces/iCourse';
import { iFrame } from '@app/Shared/Interfaces/iFrame';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-CourseVisualicer',
  templateUrl: './CourseVisualicer.component.html',
  styleUrls: ['./CourseVisualicer.component.scss']
})
export class CourseVisualicerComponent extends iUnsubscribeDestroy implements OnInit, OnDestroy {

  @ViewChild("infospotEl") infospotElClone: ElementRef;

  @HostListener("document:click", ['$event'])
  onClickDocument($event) {
    if ($event.composedPath()[0].tagName == 'CANVAS') {
      if (this.course.media[this.actualMediaIndex].type != "Image") {
        if (!this.cleared) {
          this.actualViewer.panorama.toggleInfospotVisibility(false);
          this.selectedFrame.infospots.forEach(element => {
            element["element"].show();
          });
        } else {
          this.actualViewer.panorama.toggleInfospotVisibility(false);
        }
      } else {
        this.actualViewer.panorama.toggleInfospotVisibility(true);
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.timelineExpanded = false;
    }
    if (event.code === "Space") {
      this.controlVideo();
    }
  }

  courseId: number = this.route.snapshot.params['id']
  course: iPlayerCourse = { media: [], id: -1, title: "" }
  urlImages = environment.urlImages
  selectedFrame: iFrame = new iFrame();
  isLoadingData: Boolean = true;
  played: boolean = false;
  timelineExpanded = false;
  activeFrame: number = -1
  cleared = true;
  completedHostpots: Array<any> = []
  lastCompletedFrame: number = -1;
  stoped: boolean = false;
  completedFrames: Array<any> = [];
  completedMedias: Array<any> = [];
  lastRoute: any = localStorage.getItem("lastRoute");
  // PANOLENS VARIABLES
  PANOLENS = require("panolens-three");
  THREE = this.PANOLENS.THREE;
  actualPanorama: any = null;
  actualViewer: any = null;
  actualMediaIndex: number = 0
  isOpenEditHospotElements: boolean = false;
  isEditHospotInformation: boolean = false;
  infoSpots: Array<any> = []

  constructor(private notificaciones: NotificacionesService, private loginSE: LoginService, private route: ActivatedRoute, private courseSE: CoursesService, private popupSE: PopupService, private userSE: UserService, private mediaSE: MediaCoursesService, private router: Router) {
    super();
  }

  override ngOnDestroy(): void {
    document.querySelector("body")?.classList.remove("visualicer");
    localStorage.removeItem("lastRoute")
  }
  ngOnInit() {
    if (!iStaticUtilities.isLoged()) {
      this.notificaciones.showWarning("To access this page you need to be logged in.")
      this.loginSE.logOut();
    } else {
      document.querySelector("body")?.classList.add("visualicer");
      this.courseId = this.route.snapshot.params['id'];
      this.lastRoute = localStorage.getItem("lastRoute");

      this.getCourseForPlayer();
      console.log(this.lastRoute);
    }
  }

  //PANORAMA CONTROL
  async createPanoramaElement(mediaIndex: number = 0) {
    this.actualMediaIndex = mediaIndex;
    let viewer = new this.PANOLENS.Viewer({
      container: document.querySelector(".canvasContainer") as HTMLElement,
    });
    let panorama;
    if (this.course.media[mediaIndex].type == "Video") {
      panorama = new this.PANOLENS.VideoPanorama(this.urlImages+this.course.media[mediaIndex].url, { muted: false, loop: false });
    } else {
      panorama = new this.PANOLENS.ImagePanorama(this.urlImages+this.course.media[mediaIndex].url);
    }
    this.updateSizeCanvas();
    this.actualPanorama = panorama;
    viewer.add(panorama);
    this.actualViewer = viewer;
    if (this.course.media[mediaIndex].type == "Video") {
      this.actualPanorama.getVideoElement().addEventListener("timeupdate", (ev) => {
        this.updateTimeVideo(mediaIndex);
      });
    }
    
    this.isLoadingData = false;
    await iStaticUtilities.sleep(300)
    setTimeout(() => {
      this.lateralScrollingWithWheel();
      this.course.media[mediaIndex].frames.forEach(element => {
        element.infospots.forEach(is => {
          this.createHotspot(is)
        });
      });
    }, 1000);

  }

  async updateSizeCanvas() {
    await iStaticUtilities.sleep(10);
    let elm: any = document.querySelector(".canvasContainer > canvas");
    let width = elm?.offsetWidth;
    let height = elm?.offsetHeight;

    this.actualViewer.renderer.domElement.style.width = width;
    this.actualViewer.renderer.domElement.style.height = height;
    this.actualViewer.onWindowResize(width, height);
  }

  updateTimeVideo(mediaIndex: number) {
    let currentTime = this.actualPanorama.videoElement.currentTime.toFixed(0);
    let framesFind = this.course.media[mediaIndex].frames.filter(e => {
      return e.seconds?.toFixed(0) == currentTime
    });

    if (framesFind.length > 0) {
      this.cleared = false;
      if (framesFind.length == 1) {
        if (!this.stoped) {
          this.stoped = true;
          this.played = false;
          this.actualPanorama.pauseVideo();
        }
        this.selectedFrame = framesFind[0];
        setTimeout(() => {
        
          this.selectedFrame.infospots.forEach(element => {
            if (this.lastCompletedFrame == this.selectedFrame.id || this.completedFrames.includes(this.selectedFrame.id)) {
              element["element"].material.color.set("#1D125F");
            }
            element["element"].show();
          })

          this.selectedFrame.infospots[0]["element"].focus();
          document.querySelector("canvas")?.click();
          console.log(this.actualPanorama);
        }, 1500);
      }
    } else {
      this.selectedFrame = { id: -1, title: "", infospots: [], seconds: 0 };
      this.completedHostpots = []
      this.clearHotspots();
      this.stoped = false;
    }
    if (this.actualPanorama.videoElement.duration == this.actualPanorama.videoElement.currentTime) {
      if (!this.completedMedias.includes(this.course.media[this.actualMediaIndex].id)) {
        if (!this.course.completed && localStorage.getItem("lastRoute") == null) {
          this.isCompletedMedia();          
        }
      }
    }
  }
  addHotspotsToImage() {
    this.selectedFrame = this.course.media[this.actualMediaIndex].frames[0];
      this.selectedFrame.infospots.forEach(infoSpot => {
        if (this.lastCompletedFrame == this.selectedFrame.id || this.completedFrames.includes(this.selectedFrame.id)) {
            infoSpot["element"].material.color.set("#1D125F");
        }
        infoSpot["element"].show();

      })
      this.selectedFrame.infospots[0]["element"].focus();
  }
  createHotspot(hotspots) {
    console.log(hotspots.position.x, hotspots.position.y, hotspots.position.z)
    let infospot  = new this.PANOLENS.Infospot();


    infospot.position.set(hotspots.position.x, hotspots.position.y, hotspots.position.z);
    infospot.addHoverElement(this.infospotElClone.nativeElement, 60);
    infospot.addHoverText(hotspots.title, 60)
    infospot.material.color.set("#FF8600");
    this.actualPanorama.add(infospot);
    hotspots["element"] = infospot;
    hotspots["uuid"] = infospot.uuid
    this.overrideInfospotEvents(infospot);
    this.infoSpots.push(infospot)
  }

  overrideInfospotEvents(infospot) {
    infospot._listeners.click = [];
    infospot._listeners.click.push((ev) => {
      // ev.mouseEvent.preventDefault()
      this.openInfoSpot(ev.target.uuid)
    });
  }

  openInfoSpot(uuid: string) {
    let hotspotSelected = this.selectedFrame.infospots.filter(e => { return e["uuid"] == uuid })
    if (hotspotSelected[0]["content"].length > 0) {
      this.popupSE.openPopup(InfoSpotContentComponent, { id: hotspotSelected[0].id, title: hotspotSelected[0].title })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
        if (res == null) { return }
        if (res.returnValue.completed) {
          hotspotSelected[0]["element"].material.color.set("#1D125F");
          this.completedHostpots.push(hotspotSelected[0])
          if (this.selectedFrame.infospots != undefined && this.selectedFrame.infospots.length != 0) {
            if (this.selectedFrame.infospots.length == this.completedHostpots.length) {
              this.lastCompletedFrame = this.selectedFrame.id
              this.addUserPorgress()
              if(this.course.media[this.actualMediaIndex].type == "Image"){
                setTimeout(() => {
                  if (this.completedFrames.length == this.course.media[this.actualMediaIndex].frames.length) {
                    this.isCompletedMedia();
                  }
                }, 3000);
              }
            }
          }
          // let nextHostspot = this.selectedFrame.infospots[this.selectedFrame.infospots.indexOf(hotspotSelected[0]) + 1];
          // if (nextHostspot != null || nextHostspot != null) {
          //   nextHostspot["element"].focus();
          // }
        }
        this._unsubInd2.next("")
      })
    }
  }

  //FUNCIONALIDADES
  
  moveIntoVideo(event) {
    this.actualPanorama.options.videoElement.currentTime = event
    this.course.media[this.actualMediaIndex].frames.forEach(element => {
      if (element.seconds == this.actualPanorama.options.videoElement.currentTime) {
        this.activeFrame = this.course.media[this.actualMediaIndex].frames.indexOf(element);
      }
    })
    setTimeout(() => {
      this.updateTimeVideo(this.actualMediaIndex)
    }, 500);
  }

  controlVideo() {
    if (this.played) {
      this.played = false;
      this.actualPanorama.pauseVideo();
    } else {
      this.played = true;
      this.actualPanorama.playVideo();
    }
  }

  resetVideo() {
    this.actualPanorama.resetVideo()
    this.activeFrame = -1
  }

  lateralScrollingWithWheel() {
    (document.querySelector(".mediaSelector") as HTMLElement).addEventListener("wheel", (evt) => {
      evt.preventDefault();
      (document.querySelector(".mediaSelector") as HTMLElement).scrollLeft += evt.deltaY;
    })
  }
  changeMedia(i) {
    this.actualViewer.dispose();
    this.clearCanvas();
    this.actualViewer.destroy();
    this.createPanoramaElement(i)
    this.getLastCompleteFrame(true);
  }
  clearCanvas() {
    if ((document.querySelector(".canvasContainer > canvas") as HTMLElement) != null) {
      (document.querySelector(".canvasContainer > canvas") as HTMLElement).nextSibling?.nextSibling?.remove();
      (document.querySelector(".canvasContainer > canvas") as HTMLElement).nextSibling?.remove();
      (document.querySelector(".canvasContainer > canvas") as HTMLElement).remove();
      this.actualPanorama = null;
      this.activeFrame = -1
    }
  }
  clearHotspots() {
    this.actualViewer.panorama.toggleInfospotVisibility(false);
    this.cleared = true;
  }

  nextFrame() {
    if (this.activeFrame < this.course.media[this.actualMediaIndex].frames.length) {
      if (this.activeFrame != this.course.media[this.actualMediaIndex].frames.length - 1) {
        this.activeFrame++;
      }
      this.clearHotspots();
      this.moveIntoVideo(this.course.media[this.actualMediaIndex].frames[this.activeFrame].seconds)
    }
  }

  previousFrame() {
    if (this.activeFrame != 0 && this.activeFrame != -1) {
      this.activeFrame--
      this.clearHotspots();
      this.moveIntoVideo(this.course.media[this.actualMediaIndex].frames[this.activeFrame].seconds)
    }
  }

  isLastMedia() {
    if (this.course.media[this.course.media.length - 1].id == this.course.media[this.actualMediaIndex].id) {
      return true;
    } else {
      return false;
    }
  }
  isCompletedMedia() {
    if (this.course.media[this.actualMediaIndex].frames.length == this.completedFrames.length) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Continue", tipo: "course", type: "custom", text: "Congratulations, you have completed this media! \n \nClick continue to go to the next one" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
        if (res == null) { return; }
        this.addUserMediaProgress();
        if (!this.isLastMedia()) {
          this.changeMedia(this.actualMediaIndex + 1)
        }
        this.popupSE.clearData();
        this._unsubInd4.next("")
      })
    } else {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Continue", tipo: "course", type: "custom", text: "You have not finished all the frames in this media, if you re-enter you will continue from the last completed frame.\n\n Are you sure you want to continue?" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd4)).subscribe(res => {
        if (res == null) { return; }
        if (!this.isLastMedia()) {
          this.changeMedia(this.actualMediaIndex + 1)
        }
        this.popupSE.clearData();
        this._unsubInd4.next("")
      })
    }
  }
  isCompletedCourse() {
    let auxCompletedMedia = [...new Set(this.completedMedias)];

    if (this.course.media.length == auxCompletedMedia.length) {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Finish", tipo: "course", type: "custom", text: "Congratulations, you have Finish this course! \n \nClick finish to end the course" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd8)).subscribe(res => {
        if (res == null) { return; }
        this.completeCourse();
        this._unsubInd8.next("")
      })
    } else {
      this.popupSE.openPopup(ConfirmPopupComponent, { accion: "Close", tipo: "course", type: "custom", text: "You have not finished all medias in this course.\n\n Are you sure you want to close?" })
      this.popupSE.returnData().pipe(takeUntil(this._unsubInd8)).subscribe(res => {
        if (res == null) { return; }
        this._unsubInd8.next("")
      })
    }
  }
  //LLAMADA A LA API
  getCourseForPlayer() {
    this.courseSE.getCourseForPlayer(this.courseId);
    this.courseSE.getResultForPlayer().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return }
      this.course = res.data;
      if (this.course.media.length != 0) {
        this.createPanoramaElement();
      } else {
        this.isLoadingData = false;
      }
        this.getLastCompleteFrame(true)
      this._unsubInd.next("");
    })
  }

  addUserPorgress() {
    if (!this.course.completed && localStorage.getItem("lastRoute") == null) {
      this.userSE.addUserPorgress({ student: Number(localStorage.getItem("myUser")), course: Number(this.courseId), lastCompleteFrame: Number(this.lastCompletedFrame), currentMedia: this.course.media[this.actualMediaIndex].id });
    }
    this.userSE.getResult().pipe(takeUntil(this._unsubInd6)).subscribe(res => {
      if (res == null) { return; }
      this.getLastCompleteFrame();
      this._unsubInd6.next("")
    })
  }
  addUserMediaProgress() {
    if (!this.course.completed && localStorage.getItem("lastRoute") == null) {
      this.mediaSE.addUserMedia({ user: Number(localStorage.getItem("myUser")), course: Number(this.courseId), media: this.course.media[this.actualMediaIndex].id })
    }
    this.mediaSE.getResult().pipe(takeUntil(this._unsubInd6)).subscribe(res => {
      if (res == null) { return; }
      setTimeout(() => {
        this.getCompletesMedias();
      }, 500);

      this._unsubInd6.next("")
    })
  }
  getLastCompleteFrame(firstTime: boolean = false) {
    this.courseSE.getLastCompleteFrame(this.courseId, this.course.media[this.actualMediaIndex].id);
    this.courseSE.getResultIndividual().pipe(takeUntil(this._unsubInd3)).subscribe(res => {
      if (res == null) { return }
      this.getCompletesMedias();

      if (res.data.lastFrame != null) {
        if (res.data.completedFrames[0] != 0) {
          this.completedFrames = res.data.completedFrames
        } else {
          this.completedFrames = []
        }
        this.lastCompletedFrame = res.data.lastFrame;
        let activeFrameAuxiliar = this.course.media[this.actualMediaIndex].frames.filter(e => { return e.id == res.data.lastFrame });
        if (firstTime && activeFrameAuxiliar[0] != undefined && this.course.media[this.actualMediaIndex].type != "Image") {
          this.moveIntoVideo(activeFrameAuxiliar[0].seconds)
        }
        
      }
      if (this.course.media[this.actualMediaIndex].type == "Image") {
        setTimeout(() => {
          this.addHotspotsToImage();
        }, 1000);
      }
      
      this._unsubInd3.next("");
    })
  }
  getCompletesMedias() {
    this.completedMedias = []
    this.mediaSE.getCompletedMedias(this.courseId);
    this.mediaSE.getResult().pipe(takeUntil(this._unsubInd5)).subscribe(res => {
      if (res == null) { return }
      if (res.data.completedMedia != null) {
        if (res.data.completedMedia[0] != 0) {
          this.completedMedias = res.data.completedMedia
        }
        if (this.completedMedias.length == this.course.media.length) {
          if (!this.course.completed && localStorage.getItem("lastRoute") == null) {
            this.isCompletedCourse();
          }
        }
      }
      this._unsubInd5.next("");
    })
  }
  completeCourse() {
    this.courseSE.completeCourse({
      qualification: 10,
      student: Number(localStorage.getItem("myUser")),
      course: Number(this.courseId)
    })
    this.courseSE.getResultUpdate().pipe(takeUntil(this._unsubInd7)).subscribe(res => {
      if (res == null) { return; }
      setTimeout(() => {
        this.router.navigate(["/discover"])
      }, 500);
      this._unsubInd7.next("")
    })
  }
}
