import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'iconLoader',
  templateUrl: './IconLoader.component.html',
  styleUrls: ['./IconLoader.component.scss']
})
export class IconLoaderComponent implements OnInit {

  @Input() size = "normal";
  classSize="CargarIconoNormalSize";

  constructor() { }

  ngOnInit() {
    this.checkSize();
  }

  checkSize(){
    if(this.size=="small"){
      this.classSize="CargarIconoSmallSize";
    }else if(this.size=="normal"){
      this.classSize="CargarIconoNormalSize";
    }else if(this.size=="grande"||this.size=="big"){
      this.classSize="CargarIconoBigSize";
    }else{
      new Error(this.size+" no es un tamaño válido. Los tamaños válidos son: small, normal, grande/big");
    }
  }
}
