import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from '@app/Services/Api/Courses.service';
import { LoaderService } from '@app/Services/Utils/loader.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { iCourse } from '@app/Shared/Interfaces/iCourse';
import { iDate } from '@app/Shared/Interfaces/iDate';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-NewCourse',
  templateUrl: './NewCourse.component.html',
  styleUrls: ['./NewCourse.component.css']
})
export class NewCourseComponent extends iUnsubscribeDestroy implements OnInit {

  courseName:String = "Untitled course...";
  isCreated=false;
  hasChanges: Boolean = false;
  stepActive:Number = 0
  course: iCourse = {
    title: "", categories: [], description: "",
  }
  subSync=new Subject<void>();
  syncStatus:{ isLoading: boolean, isSave: boolean, lastTimeSave: string} ={ isLoading: false, isSave: true, lastTimeSave: '00:00'}
  constructor(private loader:LoaderService, private coursesSe:CoursesService, private notifications:NotificacionesService, private actRoute:ActivatedRoute) { 
    super();
  }

  ngOnInit() {
    let j = iDate.javascriptConvert(new Date());
    this.syncStatus.lastTimeSave = iDate.cerosAdd(j.hour)+":"+iDate.cerosAdd(j.minutes);
    let courseId = this.actRoute.snapshot.params['id'];
    if(courseId != null){
      this.isCreated = true;
      this.stepActive = 1;
      this.coursesSe.getSingle(courseId);
      this.coursesSe.getResultIndividual().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if(value==null){return;}
        this.course = value.data;
        this.courseName = this.course.title;
        
      })
      this.coursesSe.getResultIndividualError().pipe(takeUntil(this._unsub)).subscribe(value=>{
        if(value==null){return;}
        this.notifications.showError(value.message);
      })
    }
  }
  getCourseName(event:String){
    this.courseName = event;
  }
  getHasChanges(event: Boolean){
    this.hasChanges = event;
  }
  firstFormData(event:iCourse){
    this.loader.open();
    if(this.isCreated){
      event.id = this.actRoute.snapshot.params['id'];
      this.coursesSe.edit(event);
    }else{
      this.coursesSe.create(event);
    }
    this.coursesSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe((val)=>{
      if(val==null){return;}
      this.course = val.data;
      this.stepActive = 1;
      this.loader.closeSlow();
    });
    this.coursesSe.getResultUpdateError().pipe(takeUntil(this._unsubInd)).subscribe((val)=>{
      if(val==null){return;}
      this.notifications.showError(val.message);
      this.loader.closeSlow();
    });
  }
  updateStep(event: Number){
    this.stepActive = event
  }
  sendSync(){
    this.subSync.next();
  }
}
