import { Directive, ElementRef, HostListener } from '@angular/core';
import { AnimationControllerService } from 'src/app/Services/Utils/AnimationController.service';
import { slideNavigationTypes } from '../Interfaces/eSliderNavigationTypes';

@Directive({
  selector: '[routerAnimationTop]'
})
export class RouterAnimationTopDirective {

  constructor(public animCon: AnimationControllerService) { }

  @HostListener('click')
  onMouseClick(){
    this.animCon.setCurrentAnimation(slideNavigationTypes.slideToTop);
  }

}

@Directive({
  selector: '[routerAnimationBottom]'
})
export class RouterAnimationBottomDirective {

  constructor(public animCon: AnimationControllerService) { }

  @HostListener('click')
  onMouseClick(){
    this.animCon.setCurrentAnimation(slideNavigationTypes.slideToBottom);
  }

}

@Directive({
  selector: '[routerAnimationLeft]'
})
export class RouterAnimationLeftDirective {

  constructor(public animCon: AnimationControllerService) { }

  @HostListener('click')
  onMouseClick(){
    this.animCon.setCurrentAnimation(slideNavigationTypes.slideToLeft);
  }

}

@Directive({
  selector: '[routerAnimationRight]'
})
export class RouterAnimationRightDirective {

  constructor(public animCon: AnimationControllerService) { }

  @HostListener('click')
  onMouseClick(){
    this.animCon.setCurrentAnimation(slideNavigationTypes.slideToRight);
  }

}
