<div class="firstFormGeneralContainer">
  <h3>{{isEdit?'Edit':'Add new'}} course</h3>
  <div class="navegationSubmenu">
    <div class="circleNav active">
      <span>Course title</span>
    </div>
    <div class="separatorNav" [ngClass]="{active: elementActive >= 1}"></div>
    <div class="circleNav" [ngClass]="{active: elementActive >= 1}">
      <span>Category</span>
    </div>
    <div class="separatorNav" [ngClass]="{active: elementActive >= 2}"></div>
    <div class="circleNav" [ngClass]="{active: elementActive >= 2}">
      <span>Description</span>
    </div>
  </div>
  <div class="formContainer">
    <div class="formContainer-right">
      <ng-container *ngIf="elementActive == 0">
        <div class="formContainer-right-top">
          <p>Hi {{getUserName()}},</p>
          <p>Welcome to the course editor and creator.</p>
          <p>Please, select a title for your course in the box below.</p>
        </div>
        <input [class.invalid]="courseNameError" type="text" placeholder="Untitled course" [(ngModel)]="courseName" (input)="sendCourseName(); detectChanges()"
          name="courseName">
        <div class="buttonContainer">
          <button (click)="validatePaso0()" type="button" class="floatRight">NEXT</button>
        </div>
      </ng-container>
      <ng-container *ngIf="elementActive == 1">
        <div class="topicContainer">
          <span [class.empty]="topicsError">Choose a topic for your course:</span>
          <div class="topics">
             <span class="topic" [ngClass]="{active: isTopicSelected(topic.name)}" *ngFor="let topic of topics" (click)="manageTopics(topic.name);detectChanges()">{{topic.name}}</span>
          </div>
        </div>
        <div class="buttonContainer separation">
          <button type="button" (click)="elementActive = 0" class="floatLeft">Back</button>
          <button (click)="validatePaso1()" type="button" class="floatRight">NEXT</button>
        </div>
      </ng-container>
      <ng-container *ngIf="elementActive == 2">
        <div class="dobleContent">
          <div class="descriptionContainer">
            <span [class.empty]="descriptionError">Add a description:</span>
            <textarea name="description" [(ngModel)]="description" (input)="detectChanges()" placeholder="Introduce your description below" cols="30" rows="10"></textarea>
            <span>Course duration: <input [class.invalid]="courseDurationError" type="time" [(ngModel)]="courseDuration" (input)="detectChanges()" name="courseDuration"></span>
          </div>
          <div class="imageCourseContainer">
            <span>Cover image</span>
            <div class="drangAndDrop" fileInput [accept]="['image/*']" (loadingFile)="isLoadingImage = !isLoadingImage" (ficheroChange)="setFileContent($event)">
              <img class="imagePreview" [src]="imagePreview">
              <div *ngIf="!isLoadingImage">
                <img src="/assets/Icons/uploadFileIcon.svg" alt="">
                <p>
                  Drop your file here!
                </p>
              </div>
              <div *ngIf="isLoadingImage">
                <iconLoader></iconLoader>
              </div>
            </div>
          </div>
        </div>
        <div class="buttonContainer separation">
          <button type="button" (click)="elementActive = 0" class="floatLeft">Back</button>
          <button type="button" (click)="validarPaso2()" class="floatRight">Done</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>