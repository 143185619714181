<div class="createdCoursesGeneralContainer">
  <p>Last added courses</p>
  <div class="lastAddedCoursesContainer">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <p class="noData" *ngIf="lastCreatedCourses.length == 0">You have not created any courses yet</p>

        <card [size]="'big'" [data]="lastCreatedCourse" [action]="'edit'" [id]="'e'+i"
        (mousemove)="rotateToMouse($event, i, 'e')" (mouseleave)="clearPosition(i, 'e')"
        *ngFor="let lastCreatedCourse of lastCreatedCourses; let i = index"></card>
    </ng-container>
  </div>
  <search [placeholder]="'Search course by specialty and file support'" [dataToFilter]="createdCourses_backup" (dataFiltered)="filteredData($event)"></search>
  <div class="editCoursesContainer">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <p class="noData" *ngIf="createdCourses.length == 0">You have not created any courses yet</p>
      <card [size]="'big'" [data]="createdCourse" [action]="'edit'" [id]="'e'+i"
        (mousemove)="rotateToMouse($event, i, 'e')" (mouseleave)="clearPosition(i, 'e')"
        *ngFor="let createdCourse of createdCourses; let i = index"></card>
    </ng-container>
  </div>
</div>