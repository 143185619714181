import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/Services/Api/Login.service';
import { UserService } from '@app/Services/Api/User.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { iCompletedCourses, iCourse, iStartedCourse } from '@app/Shared/Interfaces/iCourse';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'MyCourses-PlatformCourses',
  templateUrl: './MyCourses-PlatformCourses.component.html',
  styleUrls: ['./MyCourses-PlatformCourses.component.scss']
})
export class MyCoursesPlatformCoursesComponent extends iUnsubscribeDestroy implements OnInit {
  activeProgressBar: Boolean = true;
  startedCourses: Array<iStartedCourse> = []
  favouriteCourses: Array<iCourse> = []
  gradesCourses: Array<iCompletedCourses> = []

  dataIsLoaded: boolean = false;
  constructor(private notifications: NotificacionesService, private loginSE: LoginService, private userSE: UserService) {
    super();
  }

  ngOnInit() {
    if (!iStaticUtilities.isLoged()) {
      this.notifications.showWarning("To access this page you need to be logged in.")
      this.loginSE.logOut();
    } else {
        this.getMyCourses();
        this.getCompletedCourses();
        this.getFavouritesCourses();
    }
  }

  override ngOnDestroy(): void {
    this.userSE.clearResult();
  }
  rotateToMouse(e:any, element:any, type:string) {
    var card = document.getElementById(type+element) as HTMLElement;
    var bounds = card.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2
    }
    const distance = Math.sqrt(center.x**2 + center.y**2);
    
    card.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance)* 3}deg
      )
    `;
    
    (card.querySelector('.glow') as HTMLElement).style.backgroundImage = `
      radial-gradient(
        circle at
        ${center.x * 2 + bounds.width/2}px
        ${center.y * 2 + bounds.height/2}px,
        #ffffff55,
        #0000000f
      )
    `;
  }
  clearPosition(element:any, type:string){
    (document.getElementById(type+element)as HTMLElement).style.transform = '';
    (document.getElementById(type+element)as HTMLElement).style.background = '';
    //   card.style.background = '';
  }
  reloadCourses(){
    // this.dataIsLoaded = false;
    this.getMyCourses()
    this.getCompletedCourses();
    this.getFavouritesCourses();
  }
  //LLAMADAS A LA API
  getMyCourses(){
    this.userSE.getMyCourses();
    this.userSE.getResultMyCourses().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.startedCourses = res.data;
      this._unsubInd.next("")
    })
  
  }
  getCompletedCourses(){
    this.userSE.getCompletedCourses();
    this.userSE.getResultCompleted().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res==null){return;}
      this.gradesCourses = res.data;
      this._unsubInd2.next("")
    })

  }
  getFavouritesCourses(){
    this.userSE.getFavouritesCourses();
    this.userSE.getResultFavorites().pipe(takeUntil(this._unsubInd3)).subscribe(res=>{
      if(res==null){return;}
      this.favouriteCourses = res.data;
      this.dataIsLoaded = true;
      this._unsubInd3.next("")
    })
  }
}
