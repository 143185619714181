import { iHotspot } from "./iHotspot";
import { iStaticUtilities } from "./iStaticUtilities";

export interface iFrame {
    id: number,
    title: String,
    infospots: Array<iHotspot>,
    seconds?: number
}

export class iFrame implements iFrame{
    static convertFrontCollection(ob): Array<iFrame> {
      let iFramesCollection: Array<iFrame> = [];
      ob.forEach(element => {
        let frame = new iFrame();
        frame.id = element.id;
        frame.title = element.title;
        frame.seconds = element.seconds;
        frame.infospots = iHotspot.convertFrontCollection(iStaticUtilities.normalizeNamesArray(element.infospots))
        iFramesCollection.push(frame);
      });
      return iFramesCollection;
    }
  }