import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/Services/Api/Login.service';
import { UserService } from '@app/Services/Api/User.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { iCompletedCourses, iStartedCourse } from '@app/Shared/Interfaces/iCourse';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { ICategoriesStatistics, IGradesStatistics } from '@app/Shared/Interfaces/iStatistics';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-MyProfile',
  templateUrl: './MyProfile.component.html',
  styleUrls: ['./MyProfile.component.scss']
})
export class MyProfileComponent extends iUnsubscribeDestroy implements OnInit {
  activeProgressBar: Boolean = true;
  limit = 3
  dataIsLoaded: boolean = false;
  startedCourses: Array<iStartedCourse> = []
  gradesCourses: Array<iCompletedCourses> = []
  gradesStatistics: Array<IGradesStatistics> = []
  categoriesStatistics:  Array<ICategoriesStatistics> = []
  constructor(private notifications: NotificacionesService, private loginSE: LoginService, private userSE: UserService) {
    super();
  }

  ngOnInit() {
    if (!iStaticUtilities.isLoged()) {
      this.notifications.showWarning("To access this page you need to be logged in.")
      this.loginSE.logOut();
    } else {
      this.getGradesStatistics();
      this.getMyCourses();
      this.getCompletedCourses();
    }
  }

  rotateToMouse(e:any, element:any, type:string) {
    var card = document.getElementById(type+element) as HTMLElement;
    var bounds = card.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2
    }
    const distance = Math.sqrt(center.x**2 + center.y**2);

    card.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance)* 3}deg
      )
    `;

    (card.querySelector('.glow') as HTMLElement).style.backgroundImage = `
      radial-gradient(
        circle at
        ${center.x * 2 + bounds.width/2}px
        ${center.y * 2 + bounds.height/2}px,
        #ffffff55,
        #0000000f
      )
    `;
  }
  clearPosition(element:any, type:string){
    (document.getElementById(type+element)as HTMLElement).style.transform = '';
    (document.getElementById(type+element)as HTMLElement).style.background = '';
    //   card.style.background = '';
  }

  //LLAMADAS A LA API
  getCategoriesStatistics(){
    this.userSE.getCategoriesStatistics(Number(localStorage.getItem("myUser")));
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.categoriesStatistics = res.data
      this._unsubInd.next("")
      setTimeout(() => {
        this.getMyCourses();
      }, 500);
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.notifications.showError(res.message)
    })
  }
  getGradesStatistics(){
    this.userSE.getGradesStatistics(Number(localStorage.getItem("myUser")));
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.gradesStatistics = res.data
      this._unsubInd.next("")
      setTimeout(() => {
        this.getCategoriesStatistics();
      }, 500);
    })

  }
  getMyCourses(){
    this.userSE.getMyCourses();
    this.userSE.getResultMyCourses().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.startedCourses = res.data;
      this._unsubInd.next("")
    })
  
  }
  getCompletedCourses(){
    this.userSE.getCompletedCourses();
    this.userSE.getResultCompleted().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res==null){return;}
      this.gradesCourses = res.data;
      this.dataIsLoaded = true;
      this._unsubInd2.next("");
    })

  }
}
