import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
@Component({
  selector: 'CategoriesChart',
  templateUrl: './CategoriesChart.component.html',
  styleUrls: ['./CategoriesChart.component.scss']
})
export class CategoriesChartComponent implements OnInit {

  @ViewChild(BaseChartDirective) chartRadar: BaseChartDirective | undefined;
  @Input() data: any = [];

  //RADAR
  public radarChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    font: { family: 'Rubik' },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },

    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 15,
          }
        },
        ticks: {
          display: false // Hides the labels in the middel (numbers)
        }
      }
    }
  };
  public radarChartLabels: string[] = [];

  public radarChartData: ChartData<'radar'> = {
    labels: this.radarChartLabels,
    datasets: [
      {
        data: [],
        backgroundColor: 'rgba(39,24,126, 0.3)',
        pointBackgroundColor: '#27187E',
        borderColor: '#27187E',
      },
    ]
  };
  public radarChartType: ChartType = 'radar';
  constructor() { }

  ngOnInit() {
    this.setLabels()
    this.setData();
  }
  setLabels(){
    this.data.map(element=>{
      this.radarChartLabels.push(element.category)
    })
  }
  setData(){
    this.data.forEach(element => {
      if (this.radarChartData.labels!.includes(String(element.category))) {
        this.radarChartData!.datasets[0].data[this.radarChartData!.labels!.indexOf(String(element.category))] = element.contador
      }
    })
  }
}
