export interface IGradesStatistics {
    qualification: number,
    contador: number
}
export interface ICategoriesStatistics {
    category: string,
    contador: number
}

export class IGradesStatistics implements IGradesStatistics{
    static convertFrontCollection(ob): Array<IGradesStatistics> {
        let iStatisticsCollection: Array<IGradesStatistics> = [];
        ob.forEach(element => {
          let gradeStatistic = new IGradesStatistics();
          gradeStatistic.qualification = element.qualification;
          gradeStatistic.contador = element.contador;
          iStatisticsCollection.push(gradeStatistic);
        });
    
        return iStatisticsCollection;
      }
}
export class ICategoriesStatistics implements ICategoriesStatistics{
    static convertFrontCollection(ob): Array<ICategoriesStatistics> {
        let iStatisticsCollection: Array<ICategoriesStatistics> = [];
        ob.forEach(element => {
          let categoriesStatistic = new ICategoriesStatistics();
          categoriesStatistic.category = element.category;
          categoriesStatistic.contador = element.contador;
          iStatisticsCollection.push(categoriesStatistic);
        });
    
        return iStatisticsCollection;
      }
}