import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'IntraPageNavegation',
  templateUrl: './IntraPageNavegation.component.html',
  styleUrls: ['./IntraPageNavegation.component.scss']
})
export class IntraPageNavegationComponent implements OnInit {

  @Input() navigationItems: Array<String> = []
  @Input() elementActive: Number = 1
  @Output() selectedElement = new EventEmitter<Number>()
  constructor() { }

  ngOnInit() {
  }
  emitSelectedNavigationItem(selectedNavigationItem:Number){
    this.elementActive = selectedNavigationItem;
    this.selectedElement.emit(selectedNavigationItem);
  }
}
