import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { iPill } from '@app/Shared/Interfaces/iPill';

@Component({
  selector: 'pill',
  templateUrl: './pillElement.component.html',
  styleUrls: ['./pillElement.component.scss']
})
export class PillElementComponent implements OnInit {

  @Input() pill:iPill | undefined;
  @Input() isSelected:boolean = false;
  @Input() isExpanded:boolean = false;
  @Input() selectedFrame

  @Output() toggleExpand = new EventEmitter();
  @Output() addFile = new EventEmitter();
  @Output() selectFrame = new EventEmitter();
  @Output() action = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  sendClick(){
    this.action.emit(this.pill);
  }

  sendAddFile(){
    this.addFile.emit(this.pill);
  }
  sendToggleExpand(){
    this.toggleExpand.emit(this.pill);
  }
  sendSelectFrame(frame){
    this.selectFrame.emit(frame);
  }
  getFile(){
    if(typeof this.pill?.file == "string"){
      return this.pill?.file;
    }else{
      return this.pill?.file!.base64;
    }
  }
  isEmptyFile(){
    return typeof this.pill?.file != 'string' && this.pill?.file!.base64 == '';
  }
  getMiniature(){
    if(typeof this.pill?.file != 'string'){
      return this.pill?.file?.miniature;
    }else{
      return this.pill?.thumbnail;
    }
    return null;
  }

}
