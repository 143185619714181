import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/Services/Api/User.service';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';
import { NotificacionesService } from 'src/app/Services/Utils/notificaciones.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'card',
  templateUrl: './Card.component.html',
  styleUrls: ['./Card.component.scss']
})
export class CardComponent extends iUnsubscribeDestroy implements OnInit {
  urlImages = environment.urlImages
  @Input() size: string = ""
  @Input() data: any = null
  @Input() id:any = 0
  @Input() activeProgressBar:Boolean = false;
  @Input() cardType:String = "course"
  @Input() action:String = ""
  @Output() updateCourses: EventEmitter<boolean> = new EventEmitter();

  constructor(private notificaciones: NotificacionesService, private userSE: UserService, private router: Router) {
    super();
  }

  ngOnInit() {
  }

  startCourse(){
    if (!this.data.complete) {
      this.router.navigate(['/course-visualicer/'+this.data.id])
    }
  }

  addFavourites(){
    this.userSE.addToFavourites(this.data.id);
    this.userSE.getResult().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showFeedBack(res.message);
      this._unsubInd2.next("")
        this.updateCourses.emit(true)
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showError(res.message);
      this._unsubInd2.next("")
    })
  }
  removeFavourites(){
    this.userSE.deleteFavouritesCourses(this.data.id);
    this.userSE.getResultDelete().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showFeedBack(res.message);
      this._unsubInd2.next("")
        this.updateCourses.emit(true)
    })
    this.userSE.getResultDeleteError().pipe(takeUntil(this._unsubInd2)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showError(res.message);
      this._unsubInd2.next("")
    })
  }
}
