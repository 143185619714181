<header></header>
<IntraPageNavegation *ngIf="isTeacher" [navigationItems]="navigationItems" (selectedElement)="pageActive($event)"></IntraPageNavegation>
<FloattingButton *ngIf="isTeacher"></FloattingButton>
<ng-container *ngIf="navigationItemActive == 0">
    <MyCourses-CreatedCourses></MyCourses-CreatedCourses>
</ng-container>
<ng-container *ngIf="navigationItemActive == 1">
    <MyCourses-PlatformCourses></MyCourses-PlatformCourses>
</ng-container>
<ng-container *ngIf="navigationItemActive == 2">
    <MyCourses-Alumni></MyCourses-Alumni>
</ng-container>
<footer></footer>