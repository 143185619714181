import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'GradesChart',
  templateUrl: './GradesChart.component.html',
  styleUrls: ['./GradesChart.component.scss']
})
export class GradesChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() data: any = [];
  constructor() { }

  ngOnInit() {
    this.setData();
  }
  //BARRAS
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    font: { family: 'Rubik' },

    scales: {
      x: {
        grid: {
          lineWidth: 0,
        }, ticks: {
          color: "#000000",
          font: {
            weight: '700',
          },
        },
      },
      y: {
        min: 0,
        ticks: { // and this
          callback: function (value, index, values) {
            return value;
          },
        },
        grid: {
          color: '#dfe0f5',
          drawBorder: false,
        },
      }
    },

    plugins: {
      legend: {
        display: false,
      },

      datalabels: {
        display: false,
      },

    }

  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    DataLabelsPlugin
  ];

  public barChartData: ChartData<'bar'> = {
    labels: ["1","2","3","4","5","6","7","8","9","10"],
    datasets: [
      {
        data: [],
        barThickness: 40,
        backgroundColor: '#3726A8',
        hoverBackgroundColor: '#3726A8',
        borderWidth: 2,
        borderRadius: 8,
        borderColor: '#3726A8',
        hoverBorderColor: '#3726A8',
        borderSkipped: false,
      }
    ]
  };
  setData() {
    this.data.forEach(element => {
      if (this.barChartData!.labels!.includes(String(element.qualification))) {
        this.barChartData!.datasets[0].data[this.barChartData!.labels!.indexOf(String(element.qualification))] = element.contador
      }
    })
  }

}
