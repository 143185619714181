<div class="alumniGeneralContainer">
  <search [placeholder]="'Search for a student'" [dataToFilter]="teacherAlumnis_backup" (dataFiltered)="filterData($event)"></search>
  <div class="filterGradePills">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <p class="noData" *ngIf="teacherGrades.length == 0">No ratings available yet</p>
      <Pills [typeOfPill]="'filterPill'" [dataFilterPill]="{grade:teacherGrade.qualification, countPeople: teacherGrade.contador}"  *ngFor="let teacherGrade of teacherGrades"></Pills>
    </ng-container>
  </div>
  <div class="alumnisPilss">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <p class="noData" *ngIf="teacherAlumnis.length == 0">No alumnis available yet</p>
      <Pills (click)="selectedAlumni = teacherAlumni;openAlumniData()" [dataUserPill]="teacherAlumni.name" *ngFor="let teacherAlumni of teacherAlumnis"></Pills>
    </ng-container>
  
  </div>
</div>