import { iStaticUtilities } from "./iStaticUtilities";

export class iBaseObject {
    /**
     * @param {Object} object Sirve para clonar un objeto en otra instancia
     */
    constructor(object?){
        if(object!=null && this.constructor.name==object.constructor.name){
            for (const obj of Object.keys(object)) {
                let value=obj;
                this[value]=object[value];
            }
        }
    }
    /**
     * Sirve para comparar objetos del mismo tipo, ignorando la instancia y solo comparando los valores
     * @param {Object} object - Objeto a comparar con el actual
     * @returns {boolean}
     */
    equals(object){
        if(this.constructor.name!=object.constructor.name){ return false;}
        for (const value of Object.keys(this)) {
            if(value in object){
                if(object[value]!=this[value]){
                    return false;
                }
            }else{
                return false;
            }
        }
        return true;
    }
    /**
     * Normaliza un booleano si es string o entero y se espera un booleano
     * @param {any} object - Valor a comprobar
     * @returns {boolean}
     */
    protected normalizeBoolean(object):boolean{
        return iStaticUtilities.normalizeBoolean(object);
    }
    /**
     * Utilizable únicamente en métodos estaticos, normaliza un booleano si es string o entero y se espera un booleano
     * @param {any} object - Valor a comprobar
     * @returns {boolean}
     */
    protected static normalizeBoolean(object):boolean{
        return iStaticUtilities.normalizeBoolean(object);
    }
    /**
     * Normaliza los nombres de las keys de un objeto a camelCase
     * @param {Object} object - {id_tarea: 5, proyecto_id: 3, PAL:1467, BE_ASE:'asfas'}
     * @returns {Object} {idTarea: 5, idProyecto: 3, pal:1467, beAse:'asfas'}
     */
    protected normalizeNames(object){
        return iStaticUtilities.normalizeNames(object);
    }
    /**
     * Utilizable únicamente en métodos estaticos, Normaliza los nombres de las keys de un objeto a camelCase
     * @param {Object} object - {id_tarea: 5, proyecto_id: 3, PAL:1467, BE_ASE:'asfas'}
     * @returns {Object} {idTarea: 5, idProyecto: 3, pal:1467, beAse:'asfas'}
     */
    protected static normalizeNames(object){
        return iStaticUtilities.normalizeNames(object);
    }
    /**
     * Método para clonar un objeto dado y retorna una copia 
     * @param {Object} oldObj - Objeto a clonar
     * @returns {Object} - retorna un objeto con el viejo objeto y el nuevo
     */
     protected static clone(oldObj): Object{
        return {oldObj: oldObj, clonedObj: Object.assign({}, oldObj)};
    }
}
