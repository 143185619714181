import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ConfirmPopup',
  templateUrl: './ConfirmPopup.component.html',
  styleUrls: ['./ConfirmPopup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  accion = ""
  tipo = ""
  type:"default" | "custom" = "default"
  text = ""
  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any,) { }

  ngOnInit() {
    this.accion = this.modalData.accion;
    this.tipo = this.modalData.tipo
    if (this.modalData.type != null) {
      this.type = this.modalData.type;
      this.text = this.modalData.text;
    }
   
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }
}
