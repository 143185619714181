<ng-container *ngIf="cardType =='course'">
  <div class="card" [routerLink]="['/course-details/'+data.id]" routerLinkActive="router-link-active"
    [class.big]="size=='big'" [id]="id" [class.medium]="size=='medium'" [class.small]="size=='small'">
    <div class="glow"></div>
    <div class="progressContainer" *ngIf="activeProgressBar">
      <span>Progress {{data.progess}}%</span>
      <progress max="100" [value]="data.progess"></progress>
    </div>
    <img class="imgCard" onerror="this.src='/assets/Images/Images/placeholder.jpg';" [src]="urlImages+data.image"
      alt="">
    <p>{{data.title}}</p>
    <div class="duration">
      <span>{{data.duration}} Minutes</span>
      <span>5 Tests</span>
    </div>
    <div class="buttons"  *ngIf="action != 'edit'">
      <button (click)="addFavourites(); $event.stopPropagation()" *ngIf="!data.favorite">
        <img src="/assets/Icons/botonMas.svg" alt="">
      </button>
      <button (click)="removeFavourites(); $event.stopPropagation()" [class.favorite]="data.favorite"   *ngIf="data.favorite">
        <img src="/assets/Icons/favorito.svg" alt="">
      </button>
      <button [class.completed]="data.complete" (click)="startCourse();$event.stopPropagation();">
        <img *ngIf="!data.complete" src="/assets/Icons/playIcon.svg" alt="">
        <span *ngIf="action != 'edit' && action != 'continue' && !data.complete">Start</span>
        <span *ngIf="action == 'continue' && !data.complete"  >Continue</span>
        <span *ngIf="data.complete">Completed</span>
      </button>
    </div>
    <div class="buttons" *ngIf="action == 'edit'">
      <button class="editButton" [routerLink]="['/edit-course/'+data.id]"><span>Edit course</span></button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="cardType =='grades'">
  <div class="card grade" [class.big]="size=='big'" [id]="id" [class.medium]="size=='medium'"
    [class.small]="size=='small'">
    <div class="glow"></div>
    <img class="imgCard" src="../../../../assets/Icons/Trophy.svg" alt="">
    <p>{{data.title}}</p>
    <div class="gradeContainer">
      <img src="../../../../assets/Icons/medal.svg" alt="">
      <span>{{data.qualification}}</span>
    </div>

  </div>
</ng-container>