import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs';
import { trigger, transition } from '@angular/animations';
import { AnimationControllerService } from './Services/Utils/AnimationController.service';
import { slideNavigationFunctions, slideNavigationTypes } from './Shared/Interfaces/eSliderNavigationTypes';
import { TokenManagmentService } from './Services/Api/TokenManagment.service';
import { iUnsubscribeDestroy } from './Shared/Interfaces/iUnsubscribeDestroy';
import { LoginService } from './Services/Api/Login.service';
import { NotificacionesService } from './Services/Utils/notificaciones.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition("* => slideToLeft", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)),
      transition("* => slideToRight", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)),
      transition("* => slideToTop", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)),
      transition("* => slideToBottom", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToBottom)),
      transition("* => slideToLeftDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)),
      transition("* => slideToRightDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)),
      transition("* => slideToTopDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)),
      transition("* => slideToBottomDuplicate", slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToBottom)),
    ])
  ],
})
export class AppComponent extends iUnsubscribeDestroy implements OnInit {
  title = '360Tool-FrontEnd';
  oldStatus = "";

  constructor(private router: Router, private titleService: Title, public animService: AnimationControllerService, private loginSE: LoginService, private notifications: NotificacionesService) {
    super();
  }
  ngOnInit() {

    TokenManagmentService.listenerTokenLost().pipe(takeUntil(this._unsub)).subscribe(res=>{
      this.notifications.showError("Lost security token, please log in again.")
      this.loginSE.logOut();
    })

    TokenManagmentService.listenerStartRefreshToken().pipe(takeUntil(this._unsub)).subscribe(res=>{
      this.loginSE.refreshToken()
    })

    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd), map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
      ).subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`360 ViSi - ${title}`);
        }
      });
  }
}
