import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { IUser } from '@app/Shared/Interfaces/iUser';
import { iRegister } from '@app/Shared/Interfaces/iRegister';
import { environment } from 'src/environments/environment';
import { DefaultBaseService } from './DefaultBase.service';
import { iCompletedCourses, iCourse, iStartedCourse } from '@app/Shared/Interfaces/iCourse';
import { ICategoriesStatistics, IGradesStatistics } from '@app/Shared/Interfaces/iStatistics';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends DefaultBaseService {

  res: iResultHttp = { status: -1, ok: false, message: "", data: undefined }

  _resultMyCourses = new BehaviorSubject<any>(null);
  _resultFavourites = new BehaviorSubject<any>(null);
  _resultCompleted = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    super();
  }

  getMyInformation() {
    this.http.get(this.urlApi + '/api/user/me/', this.getHeader()).subscribe({
      next: (data) => {
        let normalizedUser = iStaticUtilities.normalizeNames(data);
        this.res = { status: 200, ok: true, message: "", data: IUser.convertFrontObject(normalizedUser) };
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getMyInformation, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  create(data: iRegister) {
    this.http.post(this.urlApi + '/user', data).subscribe({
      next: (data) => {
        this.res = { status: data["status"], ok: true, message: data["message"] };
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.create, args: [data] });
        this.checkStatusError(error);
      },
    });
  }

  getMyCourses() {
    this.http.get(this.urlApi + '/api/user/courses/', this.getHeader()).subscribe({
      next: (data) => {
        let normalizezMyCourses: Array<iStartedCourse> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: iStartedCourse.convertFrontCollection(normalizezMyCourses) };
        this.customSendNextResult('resultMyCourses', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getMyCourses, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getCategoriesStatistics(userId: number) {
    this.http.get(this.urlApi + '/api/user/statistics/categories/' + userId, this.getHeader()).subscribe({
      next: (data) => {
        let normalizedStatistics: Array<iStartedCourse> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: ICategoriesStatistics.convertFrontCollection(normalizedStatistics) };
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getCategoriesStatistics, args: [userId] });
        this.checkStatusError(error);
      },
    });
  }

  getGradesStatistics(userId: number) {
    this.http.get(this.urlApi + '/api/user/statistics/grades/' + userId, this.getHeader()).subscribe({
      next: (data) => {
        let normalizedStatistics: Array<iStartedCourse> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: IGradesStatistics.convertFrontCollection(normalizedStatistics) };
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getGradesStatistics, args: [userId] });
        this.checkStatusError(error);
      },
    });
  }

  getCompletedCourses() {
    this.http.get(this.urlApi + '/api/user/completed', this.getHeader()).subscribe({
      next: (data) => {
        let normalizedCompletedCourses: Array<iCompletedCourses> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: iCompletedCourses.convertFrontCollection(normalizedCompletedCourses) };
        this.customSendNextResult('resultCompleted', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getCompletedCourses, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  getCreatedCourses() {
    this.http.get(this.urlApi + '/api/user/teacher/', this.getHeader()).subscribe({
      next: (data) => {
        let normalizedCreatedCourses: Array<iCourse> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: iCourse.convertFrontCollection(normalizedCreatedCourses) };
        this.sendNextResult('result', this.res);

      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getCreatedCourses, args: [] });
        this.checkStatusError(error);
      },
    });
  }

  addToFavourites(id: number) {
    this.http.post(this.urlApi + '/api/user/favourite/' + id, {}, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: data["message"], data: "" };
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.addToFavourites, args: [id] });
        this.checkStatusError(error);
      },
    });
  }

  getFavouritesCourses() {
    this.http.get(this.urlApi + '/api/user/favs/', this.getHeader()).subscribe({
      next: (data) => {
        let normalizedFavouriteCourses: Array<iCourse> = iStaticUtilities.normalizeNamesArray(data);
        this.res = { status: 200, ok: true, message: "", data: iCourse.convertFrontCollection(normalizedFavouriteCourses) };
        this.customSendNextResult('resultFavorites', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getFavouritesCourses, args: [] });
        this.checkStatusError(error);
      },
    });
  }
  deleteFavouritesCourses(id: Array<number>) {
    var courses_ids: any = []
    let send = { courses_ids: courses_ids };
    if (!(id instanceof Array)) { send.courses_ids.push(id); } else { send.courses_ids = id; }
    this.http.delete(this.urlApi + '/api/user/favs/delete', this.sendBodyOptions(send)).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: data["message"], data: "" };
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultDeleteError', this.res, { method: this.deleteFavouritesCourses, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  addUserPorgress(progress: any) {
    this.http.post(this.urlApi + '/api/user/progress', progress, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: data["message"], data: "" };
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.addUserPorgress, args: [progress] });
        this.checkStatusError(error);
      },
    });
  }


  customSendNextResult(type: 'resultMyCourses'  | 'resultCompleted'  | 'resultFavorites', data: any) {
    let clearFuntion: Function;
    if (type == 'resultMyCourses') {
      this._resultMyCourses.next(data);
      clearFuntion = this.clearResultMyCourses;
    }  else if (type == 'resultCompleted') {
      this._resultCompleted.next(data);
      clearFuntion = this.clearResultCompleted;
    }  else if (type == 'resultFavorites') {
      this._resultFavourites.next(data);
      clearFuntion = this.clearResultFavorites;
    }
    setTimeout(() => {
      this[clearFuntion.name]();
    }, 150);
  }
  getResultMyCourses() { return this._resultMyCourses; }
  getResultFavorites() { return this._resultFavourites; }
  getResultCompleted() { return this._resultCompleted; }
  clearResultMyCourses() { this._resultMyCourses.next(null); }
  clearResultFavorites() { this._resultFavourites.next(null); }
  clearResultCompleted() { this._resultCompleted.next(null); }

}
