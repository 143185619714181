import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/Services/Utils/loader.service';
import { NotificacionesService } from 'src/app/Services/Utils/notificaciones.service';
import { iCountry } from '@app/Shared/Interfaces/iCountry';
import { iRegister } from '@app/Shared/Interfaces/iRegister';
import { UserService } from '@app/Services/Api/User.service';
import { takeUntil } from 'rxjs';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { Router } from '@angular/router';
import { sha256 } from '@app/Shared/Interfaces/sha256';

@Component({
  selector: 'app-Register',
  templateUrl: './Register.component.html',
  styleUrls: ['./Register.component.scss']
})
export class RegisterComponent extends iUnsubscribeDestroy implements OnInit {
  
  name:string = ""
  nameError: boolean = false;
  surname: string = ""
  surnameError: boolean = false;
  phoneNumber: number | null = null;
  phoneNumberError: boolean = false;
  email:string = ""
  emailError: boolean = false;
  password:string = ""
  passwordError:boolean = false;
  repeatPassword:string = ""
  repeatPasswordError: boolean = false;
  phoneCountryCode: string = "34" 
  termsAccepted: boolean = false;
  userType: string = "";
  validRegister: boolean = false;
  constructor(private loader: LoaderService, private notificaciones: NotificacionesService, private userSE: UserService, private router: Router) {
    super();
  }

  ngOnInit() {
  }
  
  setPhoneCode(event:iCountry){
    this.phoneCountryCode = event.code;
  }

  validarName(){
    if (this.name == "") {
      this.nameError = true;
    }else{
      this.nameError = false;
    }
  }
  validarSurname(){
    if (this.surname == "") {
      this.surnameError = true;
    }else{
      this.surnameError = false;
    }
  }
  validarPhoneNumber(){
    if (this.phoneNumber == null) {
      this.phoneNumberError = true;
    }else{
      this.phoneNumberError = false;
    }
  }
  validarEmail(){
    if (this.email == "") {
      this.emailError = true;
    }else{
      this.emailError = false;
    }
  }
  validarPassword(){
    if (this.password == "") {
      this.passwordError = true;
    }else{
      this.passwordError = false;
    }
  }
  validarRepeatPassword(){
    if (this.repeatPassword == "") {
      this.repeatPasswordError = true;
    }else{
      this.repeatPasswordError = false;
    }
  }
  validateRegister(){
    this.validarName();
    this.validarSurname();
    this.validarPhoneNumber();
    this.validarEmail();
    this.validarPassword();
    this.validarRepeatPassword();
    if (!this.nameError && !this.phoneNumberError && !this.emailError && !this.passwordError && !this.repeatPasswordError && !this.surnameError) {
      if (this.validEmail(this.email)) {
        this.emailError = false;
        if (this.password != this.repeatPassword) {
          this.passwordError = true;
          this.repeatPasswordError = true;
          this.notificaciones.showError("Passwords entered do not match");
        }else{
          this.passwordError = false;
          this.repeatPasswordError = false;
          if (this.userType == "") {
            this.notificaciones.showError("You must select what type of user you are");
          }else{
            if (this.termsAccepted == false) {
              this.notificaciones.showError("You must accept the terms and conditions");
            }else{
              var register:iRegister = {
                name: this.name,
                surname: this.surname,
                phone: Number(this.phoneNumber),
                email: this.email,
                password: sha256(this.password),
                role: this.userType
              }
              this.sendRegister(register)
            }
          }
        }
      }else{
        this.emailError = true;
        this.notificaciones.showError("The 'email' field does not have a valid format")
      }
    }else{
      this.notificaciones.showError("There are errors in the fields, please check them.");
    }
  }

  sendRegister(registerData: iRegister){
    this.userSE.create(registerData);
    this.loader.open();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return};
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(res.message);
      this.router.navigate(["/"])
      this._unsubInd.next("");
    })
    this.userSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return};
      this.loader.closeSlow();
      this.notificaciones.showError(res.message);
      this._unsubInd.next("");
    })
  }

  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }
}
