import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/Services/Api/Login.service';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/Services/Utils/loader.service';
import { NotificacionesService } from 'src/app/Services/Utils/notificaciones.service';
import { iLogin } from '@app/Shared/Interfaces/iLogin';
import { sha256 } from '@app/Shared/Interfaces/sha256';
import { UserService } from '@app/Services/Api/User.service';

@Component({
  selector: 'app-Login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.scss']
})
export class LoginComponent extends iUnsubscribeDestroy implements OnInit {

  email = "";
  password: string = "";
  emailError: boolean = false;
  passwordError: boolean = false;

  constructor(private notificaciones: NotificacionesService, private loader: LoaderService, private router: Router, private loginSE: LoginService, private userSE: UserService) {
    super();
  }

  ngOnInit() {
  }

  validateEmail() {
    if (this.email == "") {
      this.emailError = true;
    } else {
      this.emailError = false;
    }
  }
  validatePassword() {
    if (this.password == "") {
      this.passwordError = true;
    } else {
      this.passwordError = false;
    }

  }
  validateLogin() {
    this.validateEmail();
    this.validatePassword();
    if (!this.emailError && !this.passwordError) {
      if (this.validEmail(this.email)) {
        this.emailError = false;
        var login: iLogin = {
          username: this.email,
          password: sha256(this.password)
        }
        this.login(login)
      } else {
        this.emailError = true;
        this.notificaciones.showError("The 'email' field does not have a valid format")
      }
    } else {
      this.notificaciones.showError("There are errors in the fields, please check them.");
    }
  }

  validEmail(email: string) {
    var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return String(email).search(filter) != -1;
  }

  //LLAMADAS A LA API
  private login(credentials: iLogin) {
    this.loginSE.checkLogin(credentials);
    this.loader.open();
    this.loginSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showFeedBack(res.message)
      this._unsubInd.next("");
      this.getMyInformation();
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return }
      this.loader.closeSlow();
      this.notificaciones.showError(res.message)
      this._unsubInd.next("");
    })
  }

  getMyInformation() {
    this.userSE.getMyInformation();
    this.userSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return }
      localStorage.setItem("myUser", res.data.id)
      localStorage.setItem("nameUser", res.data.name)
      this._unsubInd.next("");
      this.router.navigate(["/discover"])
    })
    this.loginSE.getResultError().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return }
      this.notificaciones.showError(res.message)
      this._unsubInd.next("");
    })
  }
}
