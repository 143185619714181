<div class="infosPotContentGeneralContainer">
  <div class="infosPotContentHeader">
    <span>{{modalData.title}}</span>
    <div (click)="isCompletedHotspot()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="infosPotContentBody" [class.notLoadedData]="!isDataLoaded">
    <iconLoader *ngIf="!isDataLoaded"></iconLoader>
    <ng-container *ngIf="isDataLoaded">
      <div class="content" *ngFor="let infoContent of infoContents">
        <div class="contentTitle" (click)="contentSelected = infoContent;controlContainer();">
          <span tooltip [text]="infoContent.order+'. '+infoContent.title">{{infoContent.order}}.
            {{infoContent.title}}</span>
          <div class="switchOpener">
            <mat-icon *ngIf="!ingoContentsOpeneds.includes(infoContent.id)">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="ingoContentsOpeneds.includes(infoContent.id)">keyboard_arrow_up</mat-icon>
          </div>
        </div>

        <div class="contentData" *ngIf="infoContent.type == 'Text'"
          [class.opened]="ingoContentsOpeneds.includes(infoContent.id)" [innerHTML]="infoContent.content"></div>
        <div class="contentData" [class.opened]="ingoContentsOpeneds.includes(infoContent.id)"
          *ngIf="infoContent.type == 'Image'">
          <img [src]="urlMedia+infoContent.content" onerror="this.src='/assets/Images/Images/placeholder.jpg';" alt="">
        </div>
        <div class="contentData" [class.opened]="ingoContentsOpeneds.includes(infoContent.id)"
          *ngIf="infoContent.type == 'Video'">
          <video controls>
            <source [src]="urlMedia+infoContent.content">
          </video>
        </div>
        <div class="contentData" [class.opened]="ingoContentsOpeneds.includes(infoContent.id)"
          *ngIf="infoContent.type == 'Audio'">
          <audio controls [src]="urlMedia+infoContent.content">
          </audio>
        </div>

      </div>
    </ng-container>
  </div>
</div>