export interface ITeacherGrades {
    qualification: number,
    contador: number
}
export interface ITeacherAlumni {
    id: number,
    name: string
}

export class ITeacherGrades implements ITeacherGrades {
    static convertFrontCollection(ob): Array<ITeacherGrades> {
        let iTeacherGradesCollection: Array<ITeacherGrades> = [];
        ob.forEach(element => {
            let grade = new ITeacherGrades();
            grade.contador = element.contador;
            grade.qualification = element.qualification
            iTeacherGradesCollection.push(grade);
        });
        return iTeacherGradesCollection;
    }
    static convertFrontObject(ob): ITeacherGrades {
        let grade = new ITeacherGrades();
        grade.contador = ob.contador;
        grade.qualification = ob.qualification
        return grade;
    }
}
export class ITeacherAlumni implements ITeacherAlumni {
    static convertFrontCollection(ob): Array<ITeacherAlumni> {
        let iTeacherAlumniCollection: Array<ITeacherAlumni> = [];
        ob.forEach(element => {
            let alumni = new ITeacherAlumni();
            alumni.id = element.id;
            alumni.name = element.name
            iTeacherAlumniCollection.push(alumni);
        });
        return iTeacherAlumniCollection;
    }
    static convertFrontObject(ob): ITeacherAlumni {
        let alumni = new ITeacherAlumni();
        alumni.id = ob.id;
        alumni.name = ob.name
        return alumni;
    }
}