<div class="headerNewCourseGeneralContainer" [ngClass]="{withoutMargin: withoutMargin}">
  <div class="headerNewCourse-left">
    <div>
      <img src="assets/Images/Logos/360ViSiLogoNewCourse.svg" alt="">
    </div>
    <div class="titleCourse" [class.editorActive]="stepActive != 0">
      <div [title]="courseName">
        <img src="assets/Icons/bookIcon.svg" alt="">
        <span>{{(courseName!='')? courseName: 'Untitled course...'}}</span>
      </div>
      <button type="button"><span (click)="returnToStep0()">Edit course information</span></button>
    </div>
    <div class="saveAutomatic">
      <ng-container *ngIf="!syncStatus.isSave">
        <span  *ngIf="syncStatus.isLoading" class="material-icons-outlined rotateInfinite">autorenew</span>
        <button *ngIf="!syncStatus.isLoading" (click)="sendSync()"><img src="assets/Icons/cloudSync.svg" class="icon"></button>
        <span>Last synchronised: {{syncStatus.lastTimeSave}}</span>
      </ng-container>
      <ng-container *ngIf="syncStatus.isSave">
        <span class="material-icons-outlined">cloud_done</span>
        <span>Synchronised with the cloud</span>
      </ng-container>
    </div>
  </div>
  <div class="headerNewCourse-rigth">
    <button type="button" (click)="detectChanges()">
      <img src="assets/Icons/logOutWhite.svg" alt="">
      <span>LEAVE</span>
    </button>
  </div>
</div>