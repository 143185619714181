import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoursesService } from '@app/Services/Api/Courses.service';
import { LoginService } from '@app/Services/Api/Login.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { iCourse } from '@app/Shared/Interfaces/iCourse';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';
import { AnimationControllerService } from 'src/app/Services/Utils/AnimationController.service';

@Component({
  selector: 'app-Discover',
  templateUrl: './Discover.component.html',
  styleUrls: ['./Discover.component.scss']
})
export class DiscoverComponent extends iUnsubscribeDestroy implements OnInit {

  courses: Array<iCourse> = []
  isTeacher: boolean = iStaticUtilities.isTeacher();
  dataIsLoaded: boolean = false;
  paginationLoaded: boolean = true;
  num_dev: number = 4
  searchLoader: boolean = false;
  oldAmountCourses: number = 0;
  isAllLoaded: boolean = false;

  //CRITERIOS DE BUSQUEDA
  filter: string = ""
  sort: string = "recent"
  video360: boolean = false;
  image360: boolean = false;
  categories: string = ""
  constructor(public animCon: AnimationControllerService, public router: Router, private notifications: NotificacionesService, private loginSE: LoginService, private coursesSE: CoursesService) {
    super();
  }

  ngOnInit() {
    if (!iStaticUtilities.isLoged()) {
      this.notifications.showWarning("To access this page you need to be logged in.")
      this.loginSE.logOut();
    } else {
      this.getCoursesByFilter();
      this.activatePagination()
    }
  }

  //FUNCTIONALITIES
  reloadCourses() {
    this.getCoursesByFilter()
  }
  activatePagination() {

    window.onscroll = (ev)=>{
      if (this.courses.length > 0) {
        this.oldAmountCourses = this.courses.length;
        if (!this.isAllLoaded && ( ((window.innerHeight + window.scrollY)) >= document.body.scrollHeight) ) {
          this.num_dev += 4;
          this.paginationLoaded = false
          this.getCoursesByFilter();
        }
      }
    };
  }

  rotateToMouse(e: any, element: any) {
    var card = document.getElementById(element) as HTMLElement;
    var bounds = card.getBoundingClientRect();
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2
    }
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    card.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance) * 3}deg
      )
    `;

    (card.querySelector('.glow') as HTMLElement).style.backgroundImage = `
      radial-gradient(
        circle at
        ${center.x * 2 + bounds.width / 2}px
        ${center.y * 2 + bounds.height / 2}px,
        #ffffff55,
        #0000000f
      )
    `;
  }
  clearPosition(element: any) {
    (document.getElementById(element) as HTMLElement).style.transform = '';
    (document.getElementById(element) as HTMLElement).style.background = '';
    //   card.style.background = '';
  }

  getCriteria(event) {
    this.filter = event;
    this.searchLoader = true;
    this.getCoursesByFilter()
  }
  filterByFilesType(event) {
    this.searchLoader = true;
    if (event.includes("360 images")) {
      this.image360 = true;
    } else {
      this.image360 = false;
    }
    if (event.includes("360 video")) {
      this.video360 = true;
    } else {
      this.video360 = false;
    }
    this.getCoursesByFilter();
  }
  sortBy(event) {
    this.searchLoader = true;
    switch (event) {
      case "Relevance":
        this.sort = "relevance";
        break;
      case "Recent":
        this.sort = "recent";
        break;
      case "Older":
        this.sort = "older";
        break;
      case "Alphabetical A-Z":
        this.sort = "az";
        break;
      case "Alphabetical Z-A":
        this.sort = "za";
        break;
    }
    this.getCoursesByFilter()
  }
  filterByCategories(event) {
    this.categories = ""
    event.map(element => {
      this.categories += element + ","
    })
    this.categories = this.categories.slice(0, -1)
    this.getCoursesByFilter();
  }
  //LLAMADAS A LA API
  getCoursesByFilter() {
    this.coursesSE.getByFilter(this.filter, this.num_dev, 1, this.categories, this.video360, this.image360, this.sort);
    this.coursesSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res => {
      if (res == null) { return };
      if(this.oldAmountCourses == res.data.length){
        this.isAllLoaded = true;
      }
      this.courses = res.data;
      this.dataIsLoaded = true;
      this.paginationLoaded = true
      this.searchLoader = false;
      this.coursesSE.clearResult();
      this._unsubInd.next("");
    })
  }
}
