<div class="platformCoursesGeneralContainer">
  <p>Courses in progress</p>
  <div class="coursesInProgressContainer">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <span class="noData" *ngIf="startedCourses.length == 0">You do not have any courses yet</span>
      <card [size]="'big'" [data]="startedCourse" [activeProgressBar]="activeProgressBar" [id]="'p'+i"
        (mousemove)="rotateToMouse($event, i, 'p')" (updateCourses)="reloadCourses()" (mouseleave)="clearPosition(i, 'p')" [action]="'continue'"
        *ngFor="let startedCourse of startedCourses; let i = index"></card>
    </ng-container>
  </div>
  <p>Favorite courses</p>
  <div class="favouritesCoursesContainer">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <span class="noData" *ngIf="favouriteCourses.length == 0">You do not have any favourites courses yet</span>
      <card [size]="'big'" [id]="'f'+i" [data]="favouriteCourse" (updateCourses)="reloadCourses()" (mousemove)="rotateToMouse($event, i, 'f')"
      (mouseleave)="clearPosition(i, 'f')" *ngFor="let favouriteCourse of favouriteCourses; let i = index"></card>
    </ng-container>

  </div>
  <p>Grades and diplomas</p>
  <div class="gradesDiplomasContainer">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <span class="noData" *ngIf="gradesCourses.length == 0">You have no degrees or diplomas yet</span>
      <card [size]="'medium'" [data]="gradeCourse" [cardType]="'grades'" [id]="'g'+i" (mousemove)="rotateToMouse($event, i, 'g')"
      (mouseleave)="clearPosition(i, 'g')" *ngFor="let gradeCourse of gradesCourses; let i = index"></card>
    </ng-container>

  </div>
</div>