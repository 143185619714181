import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[fileInput]'
})
export class FileInputDirective {
  @Input("fileInput") fileInput:any;
  @Input("fichero") fichero:any;
  @Input("multiple") multiple:boolean=false;
  @Input("accept") accept:Array<String>=[];
  @Input("masInfo") masInfo:boolean=false;
  @Input("sendFileList") sendFileList:boolean=false;
  @Output("loadingFile") loadingFile=new EventEmitter<boolean>();
  @Output("ficheroChange") fileChange=new EventEmitter<any>();

  constructor(private element:ElementRef,private renderer: Renderer2, @Inject(DOCUMENT) private document:any) { }

  @HostListener('keypress',['$event'])
  clickTabNavegation($event){
    if($event.key=='Enter'){
      let input = $event.target.querySelector("input[type='file']");
      if(input!=null){
        input.click();
      }
    }
  }

  ngOnInit(): void {
    if(!this.element.nativeElement.hasAttribute("tabIndex")){
      this.renderer.setAttribute(this.element.nativeElement,"tabindex","0");
    }

    if(this.fileInput!=null&&this.fileInput!=""){
      if(this.fileInput=='false'||this.fileInput==false){return;}
    }
    if(typeof this.masInfo=="string"){
      this.masInfo=this.masInfo=="true";
    }
    if(typeof this.sendFileList=="string"){
      this.sendFileList=this.sendFileList=="true";
    }
    let input=this.renderer.createElement('input');
    this.renderer.addClass(input,"fileInput");
    this.renderer.setAttribute(input,"tabindex","-1");
    if(this.accept!=null&&this.accept.length>0){
      if(typeof this.accept == "string"){
        this.renderer.setAttribute(input,"accept",this.accept);
      }else if(this.accept instanceof Array){
        if(this.accept.length!=0){
          let elm="";
          for (let i = 0; i < this.accept.length; i++) {
            let element = this.accept[i];
            if(i!=0){elm+=", ";}
            elm+=element;
          }
          this.renderer.setAttribute(input,"accept",elm);
        }
      }else{
        console.error("Atributo 'accept' invalido ",this.accept);
      }
    }
    this.renderer.setAttribute(input,"type","file");
    if(this.multiple){ this.renderer.setAttribute(input,"multiple","true");}
    this.renderer.listen(input,"input",($event)=>{
      this.loadingFile.emit(true);
      this.sendData($event);
    });
    this.renderer.addClass(this.element.nativeElement,"fileInputParent");
    this.renderer.appendChild(this.element.nativeElement,input);
  }
  async sendData($event:any){
    let eve=await this.setEvent($event);
    let col=await this.convertBase64($event);
    if(this.masInfo){
      let i=0;
      col=col.map(e=>{
        let retu=$event.target.files[i];
        i++;
        return {base64:e,name:retu.name,type:retu.type, file:retu};
      });
    }
    if(this.sendFileList){
      this.fileChange.emit(eve);
    }else{
      this.fileChange.emit(col);
    }
    this.element.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
    await this.sleep(200);
    $event.target.value="";
  }
  sleep(ms:any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async setEvent($event:any){
    let files=$event.target.files;
    let imagenes:Array<any>=[];
    for (let i = 0; i < files.length; i++) {
      let element = files[i];
      imagenes.push(element);
    }
    return imagenes;
  }
  async convertBase64($event:any){
    let files=$event.target.files;
    let images:Array<any>=[];
    if(this.multiple){
      for (let i = 0; i < files.length; i++) {
        let send=await this.loadFile(files[i]);
        images.push(send);
      }
    }else{
      let send=await this.loadFile(files[0]);
      images.push(send);
    }
    return images;
  }
  async loadFile(file:any){
    var mimeType = file.type;

    return await this.renderFile(file);
  }
  async renderFile(file:File){
    return new Promise<any>((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event: any) => {
            var data = reader.result!.toString();
            resolve(data);
        };
    });
  }
}
