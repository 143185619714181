<div class="confirmGenralContainer">
  <div class="header">
    <p>{{accion}} {{tipo}}</p>
    <div (click)="forceClosePopup()">
      <img src="../../../assets/Icons/closeIconBlack.svg" alt="">
    </div>
  </div>
  <div class="body">
    <p *ngIf="type=='default'">¿Are you sure you want to {{accion}} {{tipo}}?</p>
    <p class="customText" *ngIf="type=='custom'">{{text}}</p>
  </div>
  <div class="footer">
    <button type="button" class="secondary" (click)="forceClosePopup()">Cancel</button>
    <ng-container *ngIf="this.modalData.elementos != null">
      <button type="button"   (click)="closePopup({accionHecha: true, elemento: this.modalData.elementos})">{{accion}}</button>
    </ng-container>
    <ng-container *ngIf="this.modalData.elementos == null">
      <button type="button"(click)="closePopup({accionHecha: true})">{{accion}}</button>
    </ng-container>
  </div>