<div class="courseEditorGeneralContainer">
  <div class="menuNavegation">
    <div class="menuNavegation-left">
      <buttonsEditor label="Add new file" image="/assets/Icons/plusWhite.svg" [isDropdown]="true">
        <actionEditor (click)="addNewPill('Video')" label="Video 360" image="/assets/Icons/videoIcon.svg"></actionEditor>
        <actionEditor (click)="addNewPill('Image')" label="Imagen 360" image="/assets/Icons/imageIcon.svg"></actionEditor>
      </buttonsEditor>
      <div class="separatorPill"></div>
      <div class="multiplePills">
        <buttonsEditor label="Create an interactive frame" *ngIf="isSelectedFile && selectedPill.type == 'Video'" image="/assets/Icons/newFrameIcon.svg" (action)="addNewFrame()" [isDisabled]="!checkUploadSelectedPill()"></buttonsEditor>
        <!--<buttonsEditor label="Add interactions" image="/assets/Icons/addInteractionsIcon.svg" [isDropdown]="true" [dropdownColumnStyle]="true" [isDisabled]="!isSelectedFile">
          <actionEditor label="Video post" image="/assets/Icons/vidoPostIcon.svg"></actionEditor>
          <actionEditor label="Audio track" image="/assets/Icons/imageIcon.svg"></actionEditor>
          <actionEditor label="PDF document" image="/assets/Icons/pdfIcon.svg"></actionEditor>
          <actionEditor label="Image" image="/assets/Icons/imageNormalIcon.svg"></actionEditor>
          <actionEditor label="Link content" image="/assets/Icons/linkIcon.svg"></actionEditor>
          <actionEditor label="Text post" image="/assets/Icons/textPost.svg"></actionEditor>
          <actionEditor label="Advanced post" image="/assets/Icons/advancedPost.svg"></actionEditor>
        </buttonsEditor>-->
        <buttonsEditor label="Undo" image="/assets/Icons/undoIcon.svg" [compactStyleBtn]="true" [isDisabled]="historyActions.length == 0"></buttonsEditor>
        <buttonsEditor label="Redo" image="/assets/Icons/forwardIcon.svg" [compactStyleBtn]="true" [isDisabled]="undoHistory.length == 0"></buttonsEditor>
      </div>
    </div>
    <div class="menuNavegation-right">
      <div class="multiplePills">
        <buttonsEditor label="Preview" image="/assets/Icons/previewIcon.svg" (action)="previewElement()" [isDisabled]="!isSelectedFile"></buttonsEditor>
        <buttonsEditor label="Publish" image="/assets/Icons/publishIcon.svg" (action)="publishCourse()" [isDisabled]="pills.length == 0"></buttonsEditor>
      </div>
      <div class="separatorPill"></div>
      <div class="multiplePills">
        <buttonsEditor (action)="openLibraries()" label="Library" image="/assets/Icons/libraryIcon.svg" [isSelected]="isOpenedLibraries" [isDisabled]="hotsposts.length == 0"></buttonsEditor>
        <buttonsEditor (action)="openProperties()" label="Properties" image="/assets/Icons/settingsIcon.svg" [isSelected]="isOpenedProperties"  [isDisabled]="!isSelectedFile"></buttonsEditor>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="navbar">
      <div class="sectionHeader">
        <img src="/assets/Icons/indexIcon.svg" alt="">
        <span>INDEX</span>
      </div>
      <div class="navbarBody">
        <div class="expandConstrainContainer">
          <button (click)="expandAll()">
            <img src="/assets/Icons/expandIcon.svg" alt="">
            <span>Expand all</span>
          </button>
          <button (click)="constrainAll()">
            <img src="/assets/Icons/expandIcon.svg" alt="">
            <span>Constrain all</span>
          </button>
        </div>
        <div class="pillsContainer">
          <div class="emptyPills" *ngIf="pills.length == 0">
            <span></span>
            <p>You have not created any pill yet.</p>
          </div>
          <div class="centerLoader" *ngIf="isLoadingPills">
            <iconLoader size="big"></iconLoader>
          </div>
          <ng-container *ngFor="let pill of pills">
            <pill (action)="changePil(pill)" 
              (toggleExpand)="toggleExpandPill(pill)" 
              (addFile)="addFile(pill)" 
              (selectFrame)="changeFrame(pill,$event)"
              [pill]="pill"
              [isSelected]="selectedPill.id == pill.id" 
              [selectedFrame]="selectedFrame"
              [isExpanded]="pillsExpanded.includes(pill)"
            ></pill>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="videoEditor" [ngClass]="{propertiesLibraryOpened: isOpenedProperties || isOpenedLibraries || isOpenEditHospotElements, propertiesContentOpened: isOpenEditHospotElements}">
      <div class="sectionHeader">
        <img src="/assets/Icons/layerIcon.svg" alt="">
        <span>MEDIUM</span>
      </div>
      <div class="videoEditorBody" [ngClass]="{rounded: !isOpenedProperties, full: isSelectedFile}" #videoContainer>
        <div class="loadingFile" *ngIf="isLoadingResource">
          <iconLoader size="big"></iconLoader>
          <p><b>Note: </b><i>Depending on the size of the content it may take longer</i></p>
        </div>
        <div class="canvasContainer" [ngClass]="{fileIn: isSelectedFile || isLoadingResource, image:selectedPill.type=='Image', video:selectedPill.type=='Video'}">
          <div class="messageContainer" *ngIf="!isSelectedFile && !isLoadingResource">
            <p>You can edit your 360 media files here.</p>
          </div>
          <div class="videoController" *ngIf="isSelectedFile && selectedPill.type == 'Video'">
          </div>
          <div class="frameTimeline" *ngIf="isSelectedFile && selectedPill.type == 'Video'"></div>
        </div>
      </div>
    </div>
    <div class="propertiesContainer rightContentEditor" *ngIf="isOpenedProperties">
      <div class="sectionHeader">
        <img src="/assets/Icons/propertiesColor.svg" alt="">
        <span>PROPERTIES</span>
      </div>
      <ng-container *ngIf="!isFramePropertiesOpen">
        <div class="propertiesBody righContentBody">
          <div class="dataContainer">
            <label for="">Pill title</label>
            <input type="text" [(ngModel)]="selectedPill.name" (change)="updatePill()">
          </div>
          <!--<div class="dataContainer">
            <label for="">Attached file:</label>
            <a *ngIf="selectedPill.type == 'video' &&  (typeof selectedPill.file == 'string' || selectedPill.file!.base64 != '')"
              [title]="selectedPill.file!.name">{{selectedPill.file!.name}}</a>
            <a class="emptyFile" *ngIf="selectedPill.type == 'video' && checkEmptyFileBase64()">No video
              attached</a>
            <a *ngIf="selectedPill.type == 'Image'" [title]="selectedPill.file!.name">{{selectedPill.file!.name}}</a>
            <a class="emptyFile" ngIf="selectedPill.type == 'Image' && checkEmptyFileBase64()">No image attached</a>
          </div>-->
          <div class="dataContainer">
            <button class="regularBtn" *ngIf="false" type="button" (click)="addFile(selectedPill)">Change file</button>
            <button class="regularBtn" type="button" (click)="deletePill()">Delete pill</button>
          </div>
          <button class="clearFrames regularBtn" type="button" [ngClass]="{disabled: selectedPill.frames.length == 0}">Clean all
            frames</button>
        </div>
      </ng-container>
      <ng-container *ngIf="isFramePropertiesOpen">
        <div class="propertiesBody righContentBody">
          <div class="dataContainer">
            <label for="">Frame title</label>
            <input type="text" [(ngModel)]="selectedFrame.title">
          </div>
          <div class="dataContainer">
            <button class="regularBtn" type="button" (click)="removeFrame()">Delete frame</button>
            <button class="regularBtn" type="button" (click)="clearHotspots()">Clean hotspots</button>
          </div>
          <button class="clearFrames regularBtn" type="button" (click)="clearFrame()">Reset properties</button>
        </div>
      </ng-container>
    </div>
    <div class="librariesContainer rightContentEditor" *ngIf="isOpenedLibraries">
      <div class="sectionHeader">
        <img src="/assets/Icons/libraryColor.svg" alt="">
        <span>LIBRARY</span>
      </div>
      <div class="propertiesBody righContentBody">
      </div>
    </div>
    <div class="editHotspotElements rightContentEditor" *ngIf="isOpenEditHospotElements">
      <div class="sectionHeader">
        <img src="/assets/Icons/propertiesColor.svg" alt="">
        <span>CONTENT</span>
        <button class="sectionBtn" (click)="closeContentInfoSpot()">
          <i class="material-icons">close</i>
          <span>Close</span>
        </button>
      </div>
      <div class="righContentBody contentRightBody">
        <ng-container *ngIf="isEditHospotInformation">
          <div class="dataContainer">
            <label for="">Hospot title</label>
            <input type="text" placeholder="Untitled hotspot" [(ngModel)]="hospotTitle"  (keyup)="changeHostpotUpdate()" (change)="changeHostpotUpdate()">
          </div>
          <div class="dataContainer">
            <button class="regularBtn" type="button" (click)="deleteHotspot()">Delete</button>
            <button class="regularBtn" type="button" (click)="cleanHostpotContent()">Clean Content</button>
          </div>
        </ng-container>
        <ng-container *ngIf="!isEditHospotInformation">
          <div class="dataContainer">
            <label for="">Data title</label>
            <input type="text" placeholder="Untitled section" [(ngModel)]="hotspotDataSelected.title" (keyup)="changeHotspotDataUpdate()" (change)="changeHotspotDataUpdate()">
            <button class="sectionBtn big" (click)="deleteSection()">
              <i class="material-icons">delete</i>
              <span>Remove section</span>
            </button>
          </div>
          <div class="dataContainer">
            <label for ="">Type content</label>
            <ng-select [items]="allTypesDatas" [(ngModel)]="hotspotDataSelected.type" (change)="changeHotspotDataUpdate()" [clearable]="false">
              <ng-template ng-label-tmp let-item="item">
                {{getTypeDataHotspotString(item)}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{getTypeDataHotspotString(item)}}
              </ng-template>
            </ng-select>
          </div>
          <div class="dataContainer" *ngIf="hotspotDataSelected.type == getHotspotDataType('text')">
            <editor
              [init]="{
                skin: 'bootstrap',
                menubar: false
              }"
              plugins="lists link anchor charmap"
              toolbar="blocks | bold italic bullist numlist | link charmap"
              [ngModel]="contentCacheData.editorText"
              (ngModelChange)="changeEditorText($event)"
              (onChange)="feedbackTextEditor()"
            ></editor>
          </div>
          <div class="dataContainer" *ngIf="hotspotDataSelected.type == getHotspotDataType('image')">
            <div class="previewContent" *ngIf="contentCacheData.imageFile != null">
              <ng-container *ngIf="checkTypeOfString(contentCacheData.imageFile)">
                <img [src]="urlImages+contentCacheData.imageFile">
              </ng-container>
              <ng-container *ngIf="!checkTypeOfString(contentCacheData.imageFile)">
                <img *ngFor="let imgOb of contentCacheData.imageFile" [src]="imgOb.base64">
              </ng-container>
            </div>
            <div class="drangAndDrop" fileInput [accept]="['image/*']" (loadingFile)="isContentLoadingFile = !isContentLoadingFile" [masInfo]="true" (ficheroChange)="setFileContent('image',$event)">
              <div *ngIf="!isContentLoadingFile">
                <img src="/assets/Icons/uploadFileIcon.svg" alt="">
                <p>
                  Drop your file here!
                </p>
              </div>
              <div *ngIf="isContentLoadingFile">
                <iconLoader></iconLoader>
              </div>
            </div>
          </div>

          <div class="questionYoutube" *ngIf="hotspotDataSelected.type == getHotspotDataType('video')">
            <toggle [(ngModel)]="isExternalVideoContentHotspot"></toggle>
            <span>Is external video?</span>
          </div>
          <div class="dataContainer" *ngIf="hotspotDataSelected.type == getHotspotDataType('video')">
            <ng-container *ngIf="isExternalVideoContentHotspot">
              <input type="text" placeholder="https://www.youtube.com/...." [(ngModel)]="contentCacheData.externalVideo" (change)="changeHotspotDataUpdate()">
            </ng-container>
            <ng-container *ngIf="!isExternalVideoContentHotspot">
              <div class="previewContent" *ngIf="contentCacheData.videoFile != null && contentCacheData.videoFile instanceof Array">
                <video *ngFor="let videoOb of contentCacheData.videoFile" controls>
                  <source  [src]="videoOb.base64">
                </video>
              </div>
              <div class="drangAndDrop" fileInput [accept]="['video/*']" (loadingFile)="isContentLoadingFile = !isContentLoadingFile" [masInfo]="true" (ficheroChange)="setFileContent('video',$event)">
                <div *ngIf="!isContentLoadingFile">
                  <img src="/assets/Icons/uploadFileIcon.svg" alt="">
                  <p>
                    Drop your file here!
                  </p>
                </div>
                <div *ngIf="isContentLoadingFile">
                  <iconLoader></iconLoader>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="dataContainer" *ngIf="hotspotDataSelected.type == getHotspotDataType('audio')">
            <div class="previewContent" *ngIf="contentCacheData.audioFile != null && contentCacheData.audioFile instanceof Array">
              <audio *ngFor="let audioOb of contentCacheData.audioFile" autobuffer="autobuffer" controls>
                <source [src]="audioOb.base64 | safe">
              </audio>
            </div>
            <div class="drangAndDrop" fileInput [accept]="['audio/*']" (loadingFile)="isContentLoadingFile = !isContentLoadingFile" [masInfo]="true" (ficheroChange)="setFileContent('audio',$event)">
              <div *ngIf="!isContentLoadingFile">
                <img src="/assets/Icons/uploadFileIcon.svg" alt="">
                <p>
                  Drop your file here!
                </p>
              </div>
              <div *ngIf="isContentLoadingFile">
                <iconLoader></iconLoader>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="customContextMenu" *ngIf="isOpenContextMenu" [ngStyle]="{'left.px': leftContextMenu, 'top.px': topContextMenu}">
  <div>
    <button [attr.disabled]="optionsContextMenu.disableAdd" (click)="addHotspot()">
      <i class="material-icons">add</i>
      <span>Add new hotspot</span>
    </button>
    <button [attr.disabled]="optionsContextMenu.disableEdit" (click)="editHotspot()">
      <i class="material-icons">edit</i>
      <span>Edit hotspot</span>
    </button>
    <button [attr.disabled]="optionsContextMenu.disableDelete" (click)="deleteHotspot()">
      <i class="material-icons">delete</i>
      <span>Delete hotspot</span>
    </button>
  </div>
</div>

<div class="infospotElem" #infospotEl style="display:none">
</div>