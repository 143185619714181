import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iHotspot, iHotspotData } from '@app/Shared/Interfaces/iHotspot';
import { iResultHttp } from '@app/Shared/Interfaces/iResultHttp';
import { DefaultBaseService } from './DefaultBase.service';

@Injectable({
  providedIn: 'root'
})
export class InfoSpotService extends DefaultBaseService {

  res: iResultHttp = { status: -1, ok: false, message: "", data: undefined }

  constructor(private http: HttpClient) {
    super();
  }
  getInfoSpotContent(id: number){
    this.http.get(this.urlApi + '/api/infospot/'+id, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: iHotspotData.convertFrontCollection(data["infospot_contents"])};   
        this.sendNextResult('result', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultError', this.res, { method: this.getInfoSpotContent, args: [id] });
        this.checkStatusError(error);
      },
    });
  }
  create(hotspot:iHotspot){
    this.http.post(this.urlApi+"/api/infospot", hotspot, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data["data"]};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.create, args: [hotspot] });
        this.checkStatusError(error);
      },
    });
  }
  update(hotspot:iHotspot){
    this.http.put(this.urlApi+"/api/infospot/"+hotspot.id, hotspot, this.getHeader()).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data["data"]};
        this.sendNextResult('resultUpdate', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultUpdateError', this.res, { method: this.update, args: [hotspot] });
        this.checkStatusError(error);
      },
    });
  }
  delete(IDs: Array<number|string>){
    let idsObjs = this.convertArrayObject(IDs, "infospots_ids");
    this.http.delete(this.urlApi+"/api/infospot", this.sendBodyOptions(idsObjs) ).subscribe({
      next: (data) => {
        this.res = { status: 200, ok: true, message: "", data: data};
        this.sendNextResult('resultDelete', this.res);
      },
      error: (error) => {
        this.res = { status: error.error.code, ok: false, message: error.error.message };
        this.sendNextResult('resultDeleteError', this.res, { method: this.delete, args: [IDs] });
        this.checkStatusError(error);
      },
    });
  }
}
