<div class="loginGeneralContainer">
  <div class="formGeneralContainer">
    <img src="../../../assets/Images/Logos/logoWithTextLogin360Visi.png" alt="">
    <p>Welcome to the education of the future.</p>
    <form action="">
      <input [class.error]="emailError" class="inputData" type="email" name="email" placeholder="E-mail" [(ngModel)]="email">
      <input [class.error]="passwordError" class="inputData" type="password" name="password" placeholder="Password" [(ngModel)]="password">
      <button (click)="validateLogin()"class="btn-login-register" type="submit">Log In</button>
    </form>
    <span>Don't you have an account yet? <a [routerLink]="['/create-account']" routerLinkActive="router-link-active" >Sign up now</a></span>
    <a>I forgot my password</a>
  </div>
</div>