import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/Popups/ConfirmPopup/ConfirmPopup.component';
import { UploadFilePopupComponent } from 'src/app/Popups/UploadFilePopup/UploadFilePopup.component';
import { NotificacionesService } from 'src/app/Services/Utils/notificaciones.service';
import { PopupService } from 'src/app/Services/Utils/popup.service';
import { iFrame } from '@app/Shared/Interfaces/iFrame';
import { iPill } from '@app/Shared/Interfaces/iPill';
import { getThumbnails } from 'video-metadata-thumbnails';
import { iUnsubscribeDestroy } from 'src/app/Shared/Interfaces/iUnsubscribeDestroy';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { eHotspotDataType, hostpotDataTypeTransform, iHotspot, iHotspotData } from '@app/Shared/Interfaces/iHotspot';
import { MediaCoursesService } from '@app/Services/Api/MediaCourses.service';
import { iCourse } from '@app/Shared/Interfaces/iCourse';
import { InfoSpotService } from '@app/Services/Api/infoSpot.service';
import { CoursesService } from '@app/Services/Api/Courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FramesService } from '@app/Services/Api/Frames.service';
import { iDate } from '@app/Shared/Interfaces/iDate';
import { ContentInfospotService } from '@app/Services/Api/ContentInfospot.service';
import { LoaderService } from '@app/Services/Utils/loader.service';

@Component({
  selector: 'NewCourse-Editor',
  templateUrl: './NewCourse-Editor.component.html',
  styleUrls: ['./NewCourse-Editor.component.scss']
})
export class NewCourseEditorComponent extends iUnsubscribeDestroy implements OnInit {

  @Input("syncButton") syncButton: Observable<void>;
  @Input("course") course:iCourse;
  @Input("syncStatus") syncStatus:{ isLoading: boolean, isSave: boolean, lastTimeSave: string} ={ isLoading: false, isSave: true, lastTimeSave: '00:00'}
  @Output("syncStatusChange") syncStatusChange = new EventEmitter();
  @ViewChild("infospotEl") infospotElClone:ElementRef;
  @ViewChild("videoContainer") videoContainer:ElementRef;

  urlImages = environment.urlImages;
  //STATE VARIABLES
  isCreatedNewCourse: boolean = true;
  isOpenedProperties: boolean = false;
  isOpenedLibraries: boolean = false;
  isSelectedFile: boolean = false;
  isFramePropertiesOpen: boolean = false;
  isOpenContextMenu: boolean = false;
  isLoadingResource: boolean = false;
  isOpenEditHospotElements: boolean = false;
  isEditHospotInformation: boolean = false;
  isContentLoadingFile: boolean = false;
  isExternalVideoContentHotspot: boolean = false;
  isSavingData:boolean = false;
  isLoadingPills:boolean = false;

  // PANOLENS VARIABLES
  PANOLENS = require("panolens-three");
  THREE = this.PANOLENS.THREE;
  actualPanorama: any = null;
  actualViewer: any = null;
  rightClickPosition: { x: number, y: number, z:number } = {x: 0, y: 0, z: 0};
  uuidHotspotSelected:any = null

  // UPDATE SYSTEM
  dataUpdated: Array<any> = [];
  dataChanges: Array<{type:'media'|'infospot'|'content'|'frames', id:number, idParent:number, isNew: boolean, dataChange:any }> = [];
  dataDeletes: Array<{type:'media'|'infospot'|'content'|'frames', ids:Array<number>}> = [];
  intervalUpdate:NodeJS.Timeout;

  // PILL VARIABLES
  hotsposts: Array<any> = []
  selectedFrame: iFrame = { id: -1, title: "", infospots: [], seconds: 0 };
  hospotTitle="";
  pills: Array<iPill> = []
  selectedPill: iPill = { id: -1, name: "", type: "", frames: [], indice: -1, file: { base64: "", name: "", type: "" } }
  pillsExpanded: Array<iPill> = []
  actualFrame: any = null;
  leftContextMenu = 0;
  topContextMenu = 0;
  hotspotDataSelected : iHotspotData = iHotspotData.empty();
  allTypesDatas:Array<eHotspotDataType> = [ eHotspotDataType.VIDEO, eHotspotDataType.AUDIO, eHotspotDataType.IMAGE, eHotspotDataType.TEXT ];
  contentCacheData:{ editorText:any,videoFile:any,audioFile:any,pdfFile:any,imageFile:any,externalVideo:string }={
    editorText: "",
    videoFile: null,
    audioFile: null,
    pdfFile: null,
    imageFile: null,
    externalVideo: ""
  }

  //EDITOR VARIABLES
  historyActions = [];
  undoHistory = [];
  optionsContextMenu:{disableAdd:boolean|null, disableEdit:boolean|null, disableDelete:boolean|null} = {
    disableAdd:null,
    disableEdit:null,
    disableDelete:null
  }

  //DEPRECATED
  videoFrames: Array<any> = []
  playingVideo: boolean = false;
  fullScreenControl: boolean = false;

  constructor(private notificaciones: NotificacionesService, private loaderSe:LoaderService, private popupSE: PopupService, public contentInfSe:ContentInfospotService, public mediaCourSe:MediaCoursesService, public frameSe:FramesService, public infospotSe:InfoSpotService, public coursesSe:CoursesService, private actRoute:ActivatedRoute, private router: Router) {
    super();
  }

  
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if(this.dataChanges.length > 0){
      let conf = confirm("Are your sure exit without saving?");
      return conf;
    }
    return true;
  }

  @HostListener("document:contextmenu",['$event'])
  onContextMenu($event){
    if($event.composedPath()[0].tagName == 'CANVAS'){
      this.actualViewer.panorama.toggleInfospotVisibility(true);
      $event.preventDefault();
      if($event.which == 3){
        this.updateRighClickPosition();
      }
      this.openContext($event.pageX, $event.pageY);
    }
  }
  @HostListener("document:click",['$event'])
  onClickDocument($event){
    if($event.composedPath()[0].tagName == 'CANVAS'){
      this.actualViewer.panorama.toggleInfospotVisibility(true);
    }else if(this.selectedPill.id!=-1){
      let hideAllHotspotElements = true;
      // Para optimizar necesito utilizar un for en vez de un foreach o un map. Debido a que no admiten un break
      for (let element of $event.composedPath()) {
        if(element.tagName == "BODY"){
          break;
        }
        // Add * for more fast break
        if(element.matches('.infospotElem')){
          hideAllHotspotElements = false;
          break;
        }
        if(element.matches('.editHotspotElements *')){
          hideAllHotspotElements = false;
          break;
        }
        if(element.matches('.cdk-overlay-container *')){
          hideAllHotspotElements = false;
          break;
        }
      }

      if(hideAllHotspotElements){
        this.selectedFrame.infospots.forEach((hots:iHotspot)=>{
          hots.obj.onDismiss();
        })
        this.closeContentInfoSpot();
      }
    }
    if($event.composedPath()[0].tagName != 'CANVAS' || $event.which!=3){
      this.isOpenContextMenu=false;
    }
  }


  ngOnInit() {
    this.syncButton.pipe(takeUntil(this._unsub)).subscribe(val=>{
      this.syncStatus.isSave=false;
      this.sendSyncStatus();
      if(this.intervalUpdate != null){
        clearTimeout(this.intervalUpdate);
      }
      this.updateDiferences();
    })
    this.checkIfElementExist();
    this.listenerHotspot();
    let courseId = this.course.id;
    if(courseId == null){
      courseId = this.actRoute.snapshot.params['id'];
    }
    this.coursesSe.getCourseForPlayer(courseId!);
    this.coursesSe.getResultForPlayer().pipe(takeUntil(this._unsub)).subscribe(async val=>{
      if(val==null){return}
      if(window.location.href.includes("localhost")){
        console.error("You can't get files from localhost, return CORS");
        return;
      }
      this.isLoadingPills = true;
      let pillsAnother:Array<iPill> = [];
      if(val.data == null){
        return;
      }
      this.syncStatus.isLoading = false;
      for (let ele of val.data.media) {
        await fetch(this.urlImages + ele.url).then(res=>res.blob()).then(async blob=>{
          let pill: iPill = {
            id: ele.id,
            name: ele.title,
            type: ele.type,
            indice: ele.indice,
            frames:ele.frames
          };
          pill.file = this.urlImages+ele.url;
          if(ele.type == "Video"){
            let thumbnail = await getThumbnails(blob, {
              interval: 1,
              start: 0,
              end:1
            });
            if(thumbnail[1]!=null){
              pill.thumbnail = this.getURLFromBlob(thumbnail[1].blob!);
            }else if(thumbnail[0]!=null){
              pill.thumbnail = this.getURLFromBlob(thumbnail[0].blob!);
            }
          }else{
            pill.thumbnail = this.getURLFromBlob(blob);
          }
          
          pillsAnother.push(pill);
        })
      }

      this.isLoadingPills = false;
      console.log(this.pills);
      console.log(pillsAnother);
      if(this.pills.length != 0){
        //Actualiza los datos
        this.pills.forEach(oriPill=>{
          let virtPill = pillsAnother.find(e=> e.indice == oriPill.indice);
          if(virtPill!=null){
            oriPill.id = virtPill.id;
            oriPill.frames.forEach(oriFram => {
              let virtFram = virtPill?.frames.find(e=> {
                console.log(e.seconds,oriFram.seconds);
                return e.seconds == ((typeof oriFram.seconds == "string") ? parseInt(oriFram.seconds):oriFram.seconds)
              });
              if(virtFram!=null){
                oriFram.id=virtFram.id;
                oriFram.infospots.forEach(oriInfospot => {
                  let virtHotspot = virtFram?.infospots.find(e=>e.position == oriInfospot.position);
                  if(virtHotspot != null){
                    oriInfospot.id = virtHotspot.id;
                    oriInfospot.content.forEach(oriContent => {
                      let virtContent = virtHotspot?.content.find(e=>e.indice == oriContent.indice);
                      if(virtContent != null){
                        oriContent.id = virtContent.id;
                        if(typeof virtContent.type == "string"){
                          oriContent.type = this.getHotspotDataType(virtContent.type);
                        }
                      }
                    })
                  }
                });
              }
            });
          }
        });
      }else{
        this.pills = pillsAnother;
      }
      // Database return "Image" or "Video" or ...
      this.pills.forEach(pill => {
        pill.frames.forEach(frames => {
          frames.infospots.forEach(infospot => {
            infospot.content.forEach(content => {
              if(typeof content.type == "string"){
                content.type = this.getHotspotDataType(content.type);
              }
            })
          })
        })
      })
    })
    this.coursesSe.getResultError().pipe(takeUntil(this._unsub)).subscribe(val=>{
      if(val==null){return}
      this.notificaciones.showError(val.message);
    })
  }

  timeOutUpdate(){
    /**
     * Tiempo de espera hasta sincronizar con el sv
     * Si hay una actualización en menos de 15 segundos, lo cancela y empieza de 0
     * Si no hay una actualización en 15 segundos, actualiza los datos
     */
    this.syncStatus.isSave=false;
    this.sendSyncStatus();
    if(this.intervalUpdate != null){
      clearTimeout(this.intervalUpdate);
    }
    this.intervalUpdate = setTimeout(() => {
      this.updateDiferences();
    }, 15 * 1000);
  }

  checkUpdateDiferences(typeDif:'media'|'frames'|'infospot'|'content', data:any, idParent:number = -1, isDelete=false){
    /**
     * Media:
     * · canNew: false
     * · posibleChanges: Description, title
     * · parentIdNull: Never
     * Frames:
     * · canNew: true
     * · posibleChanges: ALL
     * · parentIdNull: Very Unlikely, but possible if you go very fast
     * Infospot:
     * · canNew: true
     * · posibleChanges: ALL
     * · parentIdNull: Likely
     * Content infospot:
     * · canNew: true
     * · posibleChanges: ALL
     * · parentIdNull: Very likely
     */

    let cloneEl = JSON.parse( JSON.stringify(data) );
    // Create new element
    let newData = {
      type:typeDif, 
      id:data.id, 
      isNew: false, 
      dataChange: cloneEl,
      idParent: idParent
    }
    if(data.id==null || data.id < 0){
      newData.isNew = true;
    }

    // Check if exist and update data
    let isAlreadyAppend = this.dataChanges.some(depth=>{
      if(depth.id == data.id && typeDif == depth.type){
        depth.dataChange=data;
        return true;
      }
      return false;
    });

    this.timeOutUpdate();

    if(isDelete){
      if(isAlreadyAppend){
        this.dataChanges = this.dataChanges.filter(e=>e.id != data.id);
      }
      if(!data.isNew){
        let existsType = this.dataDeletes.filter(e=>e.type == typeDif).length != 0;
        if(existsType){
          this.dataDeletes.map(e=>{
            e.ids.push(data.id);
          })
        }else{
          this.dataDeletes.push({type: typeDif,ids:[data.id]});
        }
      }
      return;
    }
    
    if(isAlreadyAppend){
      return;
    }
    
    switch (typeDif) {
      case "media":
        newData.dataChange.frames=[];
      break;
      case "frames":
        newData.dataChange.infospots=[];
      break;
      case "infospot":
        newData.dataChange.content=[];
      break;
    }
    this.dataChanges.push(newData);
  }

  updateDiferences(){
    /** 
     * Order by
     * 1. media
     * 2. frames
     * 3. infospot
     * 4. content
     * 
     * Lo ordenamos para así poder añadirlo a la base de datos sin ningún fallo
     */
    this.dataChanges = this.dataChanges.sort((a,b)=>{
      if(a.type == b.type){
        return 0;
      }
      if(a.type == b.type){
        return 0;
      }
      let check = b.type != "media";
      if(a.type == "media" && check){
        return -1;
      }
      check = check && b.type!="frames"
      if(a.type == "frames" && check){
        return -1;
      }
      check = check && b.type!="infospot"
      if(a.type == "infospot" && check){
        return -1;
      }
      return 1;
    })

    //Variables para detectar el caso especifico que crea un infospot y crea un content typo imagen dentro de ese infospot
    //ya que tiene que esperar a que se complete el infospot
    let newContentInfospotFormData:Array<{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any}> = [];
    let waitInfospotFormData:Array<{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any}> = [];
    let waitInfospotFormDataFrame:Array<{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any}> = [];
    /**
     * Hace un append a los elementos dentro de los padres, para ello se empieza por el final del array haciendo un .reverse
     * y se guarda los elementos hijos en un array temporal
     */
    let arrayToSplice:Array<{type:'media'|'frames'|'infospot'|'content',id:number}> = [];
    let tempArrayContent:Array<{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any}>=[];
    let tempArrayInfospot:Array<{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any}>=[];
    this.dataChanges.reverse().forEach(element => {
      // En el caso en el que el contenido no vaya a ser un formdata
      if(element.type == "content" )/*&& typeof element.dataChange.content == "string"){
        tempArrayContent.push(element);
      }else*/{
        newContentInfospotFormData.push(element);
      }
      if(element.type == "infospot"){
        /*tempArrayContent.forEach(content=>{
          if(content.idParent == element.id){
            element.dataChange.content.push(content);
            arrayToSplice.push({type: content.type, id: content.id});
          }
        })*/
        newContentInfospotFormData.forEach(content=>{
          if(content.idParent == element.id){
            waitInfospotFormData.push(element);
          }
        })
        tempArrayInfospot.push(element);
      }
      if(element.type == "frames"){
        tempArrayInfospot.forEach(hotspot=>{
          if(hotspot.idParent == element.id){
            element.dataChange.infospots.push(hotspot);
            arrayToSplice.push({type: hotspot.type, id: hotspot.id});
            waitInfospotFormData.filter(e=>e.id == hotspot.id).map(e=>{
              waitInfospotFormDataFrame.push(e);
            })
          }
        })
      }
    });
    // Eliminar los elementos que están en el arrayToSplice
    this.dataChanges = this.dataChanges.filter(elem=>{
      return arrayToSplice.filter(spl => elem.id == spl.id).length == 0;
    })

    // Para mayor optimizacion, añade los infospot con el mismo parentId en un frame vacio
    let tempInfospotVirtual:Array<{idParent: number, val:Array<{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any}>}> = [];
    this.dataChanges.forEach(element=>{
      if(element.type == "infospot"){
        let exists = tempInfospotVirtual.find(e=>e.idParent == element.idParent) != null;
        if(exists){
          tempInfospotVirtual.map(e=>{
            if(e.idParent == element.idParent){
              e.val.push(element);
            }
          })
        }else{
          tempInfospotVirtual.push({idParent: element.idParent, val: [element]});
        }
      }
    })
    let infospotDeletes:Array<number> = [];
    // Añadir los nuevos frames virtuales con los infospot
    tempInfospotVirtual.map(element=>{
      if(element.val.length > 1){
        let virtualFrame:{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any};
        virtualFrame = {
          type:"frames",
          idParent: -1,
          isNew: false,
          id: element.idParent,
          dataChange:{
            id: element.idParent,
            infospots: element.val
          }
        }
        this.dataChanges.push(virtualFrame);
        element.val.forEach(e=>{
          infospotDeletes.push(e.dataChange.id);
          waitInfospotFormDataFrame.push(e);
        })
      }
    })
    // Eliminar los infospot optimizados
    this.dataChanges = this.dataChanges.filter(elem=>{
      return infospotDeletes.filter(spl => elem.id == spl).length == 0;
    })

    // Preparar los datos para que los envie correctamente
    let prepareFn = (ob:{type: "media" | "frames" | "infospot" | "content",id: number,idParent: number,isNew: boolean,dataChange: any})=>{
      if(ob.id < 0){
        ob.dataChange.id = null;
      }
      if(ob.type=="media"){
        if(ob.dataChange.frames.length > 0){
          ob.dataChange.frames.forEach(el => {
            prepareFn(el);
          });
        }
      }
      if(ob.type=="frames"){
        if(typeof ob.dataChange.seconds == "string" && ob.dataChange.seconds!=''){
          if(ob.dataChange.seconds.includes(".")){
            ob.dataChange.seconds = parseInt(ob.dataChange.seconds);
          }else{
            let splitTime = ob.dataChange.seconds.split(":");
            ob.dataChange.seconds = parseInt(splitTime[1])+parseInt(splitTime[0])*60;
          }
        }
        ob.dataChange.media = ob.idParent;
        if(ob.dataChange.infospots.length > 0){
          ob.dataChange.infospots.forEach(el => {
            prepareFn(el);
            el.dataChange.frame = null;
          });
          ob.dataChange.infospots = ob.dataChange.infospots.map(el=>{
            return el.dataChange;
          })
        }
      }
      if(ob.type=="infospot"){
        /*if(ob.dataChange.obj != null){
          ob.dataChange.obj = null;
        }
        if(ob.dataChange.uuid != null){
          ob.dataChange.uuid = null;
        }*/
        if(ob.dataChange.content.length > 0){
          ob.dataChange.content.forEach(el => {
            prepareFn(el);
            el.infospot = null;
          });
          ob.dataChange.content = ob.dataChange.content.map(el=>{
            return el.dataChange;
          })
        }
        if(ob.dataChange.position != null){
          ob.dataChange.position = [ob.dataChange.position.x, ob.dataChange.position.y, ob.dataChange.position.z];
        }
        ob.dataChange.frame = ob.idParent;
      }
      if(ob.type == "content"){
        switch(ob.dataChange.type){
          case eHotspotDataType.VIDEO:
            ob.dataChange.type="Video";
          break;
          case eHotspotDataType.IMAGE:
            ob.dataChange.type="Image";
          break;
          case eHotspotDataType.FORM:
            ob.dataChange.type="Survey";
          break;
          case eHotspotDataType.TEXT:
            ob.dataChange.type="Text";
          break;
          case eHotspotDataType.AUDIO:
            ob.dataChange.type="Audio";
          break;
        }
        ob.dataChange.infospot = ob.idParent;
        if(ob.dataChange.indice != null){
          ob.dataChange.indice = ob.dataChange.indice;
        }
      }
    }
    this.dataChanges.forEach(e=>{
      prepareFn(e);
    })

    //Enviar las actualizaciones
    this.dataChanges.forEach(elem=>{
      switch (elem.type) {
        case "media":
          this.mediaCourSe.edit(this.course.id,elem.dataChange);
        break;
        case "frames":
          if(elem.isNew){
            this.frameSe.create(elem.dataChange);
          }else{
            this.frameSe.update(elem.dataChange);
          }
        break;
        case "infospot":
          if(elem.isNew){
            this.infospotSe.create(elem.dataChange);
          }else{
            this.infospotSe.update(elem.dataChange);
          }
        break;
        case "content":
          //let isNeedWait = newContentInfospotFormData.filter(e=> e.id == elem.id);
          let isNeedWait = waitInfospotFormData.filter(e=> e.id == elem.idParent);
          if(isNeedWait.length == 0){
            if(elem.isNew){
              this.contentInfSe.create(elem.dataChange);
            }else{
              this.contentInfSe.update(elem.dataChange);
            }
          }
        break;
      }
    });

    /**
     * Caso especifico cuando un content tiene que ser enviado por formData y tiene que esperar a que termine infospot
     * Debido a la optimización de consultas de multiples infospots en un mismo frame, también tengo que escuchar cuando termina el frame
     */
    if(waitInfospotFormData.length > 0){
      let amountInfospots = 0;
      if(waitInfospotFormDataFrame.length > 0){
        let amountFrames = 0;
        this.frameSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(val=>{
          if(val==null){return;}

          let hotspotsApped:Array<{idN: number, elem:any}> = [];
          val.data.infospots.forEach(element => {
            waitInfospotFormData.filter(
              e=>this.comparePositions(e.dataChange.position,element.position)
            ).map(e=>{
              hotspotsApped.push({idN: element.id, elem:e})
            });
          });

          hotspotsApped.forEach(elem=>{
            amountFrames++;
            amountInfospots++;
            newContentInfospotFormData.filter(
              e=>e.idParent == elem.elem.id
            ).forEach(e=>{
              e.dataChange.infospot = elem.idN;
              if(e.isNew){
                this.contentInfSe.create(e.dataChange);
              }else{
                this.contentInfSe.update(e.dataChange);
              }              
            })
          })
          if(amountFrames == waitInfospotFormDataFrame.length){
            this._unsubInd2.next(" ");
          }
        })
      }
      this.infospotSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(val=>{
        if(val==null){return;}
        let hotspotsApped:Array<{idN: number, elem:any}> = [];
        waitInfospotFormData.filter(e=>this.comparePositions(e.dataChange.position,val.data.position)).map(e=>{
          hotspotsApped.push({idN: val.data.id, elem:e})
        })
        hotspotsApped.forEach(elem=>{
          amountInfospots++;
          newContentInfospotFormData.filter(
            e=>e.idParent == elem.elem.id
          ).forEach(e=>{
            e.dataChange.infospot = elem.idN;
            if(e.isNew){
              this.contentInfSe.create(e.dataChange);
            }else{
              this.contentInfSe.update(e.dataChange);
            }              
          })
        })
        if(amountInfospots == waitInfospotFormDataFrame.length){
          this._unsubInd.next(" ");
        }    
      });
    }
    
    // Enviar deletes
    this.dataDeletes.forEach(e=>{
      switch (e.type) {
        case "media":
          this.mediaCourSe.delete(e.ids);
        break;
        case "frames":
          this.frameSe.delete(e.ids);
        break;
        case "infospot":
          this.infospotSe.delete(e.ids);
        break;
        case "content":
          this.contentInfSe.delete(e.ids);
        break;
      }
    })

    // Effect saving
    setTimeout(() => {
      this.dataChanges=[];
      this.isSavingData = true;
      this.syncStatus.isLoading = true;
      this.sendSyncStatus();
    }, 800);
    // Update all information
    setTimeout(() => {
      this.coursesSe.getCourseForPlayer(this.course.id!);
      this.syncStatus.isSave = true;
      let date= iDate.javascriptConvert(new Date());
      this.syncStatus.lastTimeSave = iDate.cerosAdd(date.hour)+":"+iDate.cerosAdd(date.minutes);
      this.sendSyncStatus();
    }, 3000);
  }

  async listenerHotspot(){
    await iStaticUtilities.sleep(10)
    //Convert in async and wait load all html
    this.videoContainer.nativeElement.addEventListener("click", (e)=>{
      var target:any = e.target; 

      while (target && target.parentNode !== this.videoContainer.nativeElement.parentNode) {
        if (!target) { return; } // If element doesn't exist

        if (target.matches('.headerInfospot .buttonHotspot.add')){
          this.addHotspotContent(target.getAttribute('uuid'));
        }
        if (target.matches('.headerInfospot .buttonHotspot.edit')){
          this.openEditHotspotProperties(target.getAttribute('uuid'));
        }
        if (target.matches('.bodyInfospot .buttonHotspot.edit')){
          this.openEditHotspotElement(target.getAttribute('uuid'), target.getAttribute('index'));
        }


        //Override target
        target = target.parentNode;
      }
    });
  }

  comparePositions(positionA,positionB){
    return positionA.x == positionB.x && positionA.y == positionB.y && positionA.z == positionB.z;
  }
  

  updateRighClickPosition(){
    let intersectObjs = this.actualViewer.raycaster.intersectObject(this.actualViewer.panorama, true);
    this.rightClickPosition = intersectObjs[0].point;
    let infoSpot = intersectObjs.filter(e=>e.object.type == "infospot");
    if(infoSpot.length > 0){
      this.uuidHotspotSelected=infoSpot[0].object.uuid;
      this.optionsContextMenu.disableDelete = null;
      this.optionsContextMenu.disableEdit = null;
      this.optionsContextMenu.disableAdd = false;
    }else{
      this.uuidHotspotSelected=null;
      this.optionsContextMenu.disableDelete = false;
      this.optionsContextMenu.disableEdit = false;
      this.optionsContextMenu.disableAdd = null;
    }
  }

  openContext(x, y){
    this.leftContextMenu = x;
    this.topContextMenu = y;
    this.isOpenContextMenu = true;
  }

  openProperties() {
    if (this.selectedPill.id == -1) {
      this.notificaciones.showWarning("To modify the properties of a pill you have to select one.")
      return;
    }
    if (this.isOpenedProperties) {
      this.isOpenedProperties = false;
    } else {
      this.isOpenedProperties = true;
      this.isOpenedLibraries = false;
      this.isOpenEditHospotElements = false;
      this.isEditHospotInformation = false;
      this.contentCacheData.editorText = "";
    }
    this.updateSizeCanvas();
  }

  openLibraries() {
    if (this.isOpenedLibraries) {
      this.isOpenedLibraries = false;
    } else {
      this.isOpenedProperties = false;
      this.isOpenedLibraries = true;
      this.isOpenEditHospotElements = false;
      this.isEditHospotInformation = false;
      this.contentCacheData.editorText = "";
    }
    this.updateSizeCanvas();
  }

  openEditHotspotProperties(uuid){
    this.isOpenedLibraries = false;
    this.isOpenedProperties = false;
    this.isOpenEditHospotElements = true;
    this.isEditHospotInformation = true;
    this.uuidHotspotSelected = uuid;
    this.hospotTitle = this.findHostpotByUuid(uuid).title;
    this.updateSizeCanvas();
  }
  openEditHotspotElement(uuid, index){
    //Clear cache
    this.contentCacheData.editorText= "";
    this.contentCacheData.videoFile= null;
    this.contentCacheData.audioFile= null;
    this.contentCacheData.pdfFile= null;
    this.contentCacheData.imageFile= null;
    this.contentCacheData.externalVideo= "";

    index = parseInt(index);
    this.isOpenedLibraries = false;
    this.isOpenedProperties = false;
    this.isOpenEditHospotElements = true;
    this.isEditHospotInformation = false;
    this.uuidHotspotSelected = uuid;

    let hotspot = this.findHostpotByUuid(uuid);
    this.isExternalVideoContentHotspot = false;
    this.hotspotDataSelected = hotspot.content.find(e=>e.indice == index)!;
    switch (this.hotspotDataSelected.type) {
      case eHotspotDataType.TEXT:
        this.contentCacheData.editorText = this.hotspotDataSelected.content;
      break;
      case eHotspotDataType.AUDIO:
        this.contentCacheData.audioFile = this.hotspotDataSelected.content;
      break;
      case eHotspotDataType.VIDEO:
        let cont = this.hotspotDataSelected.content;
        if(cont != null && typeof cont == "string" && cont.startsWith("http")){
          this.isExternalVideoContentHotspot = true;
          this.contentCacheData.externalVideo = this.hotspotDataSelected.content;
        }
      break;
      case eHotspotDataType.IMAGE:
        this.contentCacheData.imageFile = this.hotspotDataSelected.content;
      break;
    }
    this.updateSizeCanvas();
  }


  closeContentInfoSpot(){
    this.isOpenEditHospotElements = false;
    this.isEditHospotInformation = false;
    this.contentCacheData.editorText = "";
    this.updateSizeCanvas();
  }
  changeHostpotUpdate(){
    let hotspot = this.findHostpotByUuid(this.uuidHotspotSelected)
    hotspot.title = this.hospotTitle;
    this.updateHotspotInformation(hotspot);
    this.checkUpdateDiferences("infospot",hotspot,this.selectedFrame.id);
  }
  changeHotspotDataUpdate(){
    let hotspot = this.findHostpotByUuid(this.uuidHotspotSelected);
    this.hotspotDataSelected.content = '';
    //Load old data
    switch (this.hotspotDataSelected.type) {
      case eHotspotDataType.TEXT:
        this.hotspotDataSelected.content = this.contentCacheData.editorText;
      break;
      case eHotspotDataType.IMAGE:
        this.hotspotDataSelected.content = this.contentCacheData.imageFile;
      break;
      case eHotspotDataType.AUDIO:
        this.hotspotDataSelected.content = this.contentCacheData.audioFile;
      break;
      case eHotspotDataType.PDF:
        this.hotspotDataSelected.content = this.contentCacheData.pdfFile;
      break;
      case eHotspotDataType.VIDEO:
        this.hotspotDataSelected.content = this.contentCacheData.videoFile;
      break;
    }
    this.updateHotspotInformation(hotspot);
    this.sendUpdateContent();
  }
  setFileContent(type:string,file){
    this.isContentLoadingFile = false;
    switch(type.toLocaleLowerCase()){
      case "image":
        this.contentCacheData.imageFile = file;
      break;
      case "audio":
        this.contentCacheData.audioFile = file;
      break;
      case "pdf":
        this.contentCacheData.pdfFile = file;
      break;
      case "video":
        this.contentCacheData.videoFile = file;
      break;
    }
    this.hotspotDataSelected.content = file;
    this.sendUpdateContent();
  }
  sendUpdateContent(){
    let infospot = this.selectedFrame.infospots.find(e=>e.uuid == this.uuidHotspotSelected)!;
    this.checkUpdateDiferences("content",this.hotspotDataSelected,infospot.id);
  }
  appendInfospotsBackend(){
    /*this.pills.forEach(pill=>{
      pill.frames.forEach(frame=>{
        let indexFrame = pill.frames.indexOf(frame);
        frame.infospots.forEach(infospot=>{   
          if(infospot.obj == null){
            let hotspot = this.createHotspot(infospot.position.x, infospot.position.y, infospot.position.z, {frame: frame, index: indexFrame}, false);
            infospot.obj = hotspot.obj;
            infospot.uuid = hotspot.uuid;
          }
        })
      })
    })*/
  }
  addHotspot(){
    if(this.selectedPill.type == "Video" && !this.actualPanorama.isVideoPaused() ){
      this.actualPanorama.toggleVideo();
    }
    let currentFrame = this.getFrameCurrentTime();
    let hotspot = this.createHotspot(this.rightClickPosition.x * -1, this.rightClickPosition.y, this.rightClickPosition.z, currentFrame);
    let idParent = currentFrame.frame.id!;
    this.checkUpdateDiferences("infospot",hotspot,idParent);
  }

  createHotspot(x,y,z, currentFrame:{frame: iFrame, index: number}, goToFrame = true){
    let infospot = new this.PANOLENS.Infospot();
    infospot.position.set( x, y, z );
    infospot.material.color.set("#FF8600");
    infospot.addHoverElement(this.infospotElClone.nativeElement);

    let position = {x: x, y:y, z:z};
    let hotspotFrame = currentFrame.frame.infospots.find(e=>this.comparePositions(e.position,position));

    let hotspot:iHotspot

    if(hotspotFrame != null){
      hotspotFrame.obj = infospot;
      hotspotFrame.uuid = infospot.uuid;
      hotspot = hotspotFrame;
    }else{
      hotspot = {
        obj:infospot,
        position:{
          x:x,
          y:y,
          z:z
        },
        uuid:infospot.uuid,
        title:'Untitled hotspot',
        content:[],
        id: this.randomIdGenerator()
      };
      currentFrame.frame.infospots.push(hotspot);
    }


    if(goToFrame){
      this.changeFrame(this.selectedPill,currentFrame.frame);
    }

    this.actualPanorama.add(infospot);
    this.actualViewer.panorama.toggleInfospotVisibility(true);

    this.updateHotspotInformation(hotspot);
    this.overrideInfospotEvents(infospot);

    return hotspot;
  }

  editHotspot(){  
    this.openEditHotspotProperties(this.uuidHotspotSelected);  
  }
  findHostpotByUuid(uuid: string):iHotspot{
    return this.selectedFrame.infospots.find(e=>e.uuid == uuid)!;
  }

  addHotspotContent(uuid){
    let hotspot = this.findHostpotByUuid(uuid);
    let lastIndex = hotspot.content.length;
    if(lastIndex > 0){
      lastIndex = hotspot.content[lastIndex - 1].indice;
    }
    hotspot.content.push(
      {
        content:'',
        title: 'Untitled section',
        id:this.randomIdGenerator(),
        indice: lastIndex + 1,
        type: eHotspotDataType.TEXT,
      }
    )
    this.updateHotspotInformation(hotspot);
  }

  deleteHotspot(){
    let infospot = this.selectedFrame.infospots.find(e=>e.uuid == this.uuidHotspotSelected);
    this.checkUpdateDiferences("infospot",infospot,this.selectedFrame.id);

    this.deleteHotspotByUUID(this.uuidHotspotSelected);
  }

  deleteHotspotByUUID(uuid){
    this.closeContentInfoSpot();
    let infospot = this.actualPanorama.children.find(e=>e.uuid == uuid);

    infospot.removeHoverElement();
    
    this.actualPanorama.remove(infospot);
  }

  updateHotspotInformation(hotspot:iHotspot){
    //Ojala angular admitiera que se pueda insertar componentes dinamicos en un objeto clonado, no existente o esta deprecated
    let el = hotspot.obj.element;
    hotspot.obj.element.setAttribute("uuid", hotspot.uuid);
    
    let actualScroll = 0;
    if(el.querySelector(".bodyInfospot") != null){
      actualScroll = el.querySelector(".bodyInfospot").scrollTop;
    }

    el.innerHTML = `
    <div class="headerInfospot">
      <div class="dataInfospotContainer">
        <div class="titleContainer">
          <span><b>Title: ${hotspot.title}</b></span>
        </div>
        <div class="buttonsContainer">
          <button class="buttonHotspot add" uuid="${hotspot.uuid}">
            <i class="material-icons">add</i>
            <span>Add</span>
          </button>
          <button class="buttonHotspot edit" uuid="${hotspot.uuid}">
            <i class="material-icons">edit</i>
            <span>Edit</span>
          </button>
        </div>
      </div>
    </div>
    <div class="bodyInfospot">${this.getBodyInfoSpot(hotspot)}</div>
    `;
    // Add CSS attribute angular compiler
    let attr = el.getAttributeNames().find(e=>e.startsWith("_ngcontent-"));
    this.addAllChildrenAttribute(el,attr);

    if(el.querySelector(".bodyInfospot")){
      el.querySelector(".bodyInfospot").scrollTop = actualScroll;
    }
    el.querySelector(".bodyInfospot").addEventListener("wheel", (e)=>{
      e.stopPropagation();
    }, {passive: false})

  }

  getBodyInfoSpot(hotspot:iHotspot){
    return hotspot.content.map(element => {
      return `
      <div class="dataInfospotContainer">
        <div class="titleContainer">
          <span><b>Title: ${element.title}</b></span>
        </div>
        <div class="typeContainer">
          <span>Type: ${hostpotDataTypeTransform(element.type)}</span>
        </div>
        <div class="buttonsContainer">
          <button class="buttonHotspot edit" uuid="${hotspot.uuid}" index="${element.indice}">
            <i class="material-icons">edit</i>
            <span>Edit</span>
          </button>
        </div>
      </div>
      `;
    }).join(" ");
  }
  feedbackTextEditor(){
    this.notificaciones.showFeedBack("Changes successfully saved");
    this.sendUpdateContent();
  }
  getTypeDataHotspotString(dataType){
    return hostpotDataTypeTransform(dataType);
  }
  getHotspotDataType(type:string){
    let retType = eHotspotDataType.TEXT;
    switch (type.toLowerCase()) {
      case 'video':
        retType = eHotspotDataType.VIDEO;
      break;
      case 'audio':
        retType = eHotspotDataType.AUDIO;
      break;
      case 'pdf':
        retType = eHotspotDataType.PDF;
      break;
      case 'image':
        retType = eHotspotDataType.IMAGE;
      break;
      case 'text':
        retType = eHotspotDataType.TEXT;
      break;
      case 'form':
        retType = eHotspotDataType.FORM;
      break;
    }
    return retType;
  }

  addAllChildrenAttribute(el,attr){
    for (var i = 0; i < el.children.length; i++) {
      var child = el.children[i];
      child.setAttribute(attr,'');
      if(el.children[i].children.length > 0){
        this.addAllChildrenAttribute(el.children[i], attr);
      }
    }
  }

  overrideInfospotEvents(infospot){

    infospot._listeners.hoverenter = [];
    infospot._listeners.click = [];

    infospot._listeners.hoverenter.push((ev)=>{
      if ( !infospot.container ) { return; }
      
      const cursorStyle = infospot.cursorStyle || ( infospot.mode === 1 ? 'pointer' : 'default' );
      const { scaleDownAnimation, scaleUpAnimation } = infospot;
  
      infospot.isHovering = true;
      infospot.container.style.cursor = cursorStyle;
  
      if( infospot.animated ){
        scaleDownAnimation.stop();
        scaleUpAnimation.start();
      }
  
    });

    infospot._listeners.dismiss.push(ev=>{    
      this.closeContentInfoSpot();
    })

    infospot._listeners.click.push((ev)=>{
      if ( !infospot.container ) { return; }

      if(ev.mouseEvent.which != 1) { return; }

      const { element } = infospot;
      
      this.selectedFrame.infospots.forEach((hots:iHotspot)=>{
        hots.obj.onDismiss();
      })

      if( infospot.element && ev.mouseEvent.clientX >= 0 && ev.mouseEvent.clientY >= 0 ){
        const { left, right, style } = element;
        if ( element.mode === 2 || element.mode === 3 ) {
  
            style.display = 'none';
            left.style.display = 'flex';
            right.style.display = 'flex';
  
            // Store element width for reference
            element._width = left.clientWidth;
            element._height = left.clientHeight;
  
        } else {
  
            style.display = 'flex';
            if ( left ) { left.style.display = 'none'; }
            if ( right ) { right.style.display = 'none'; }
  
            // Store element width for reference
            element._width = element.clientWidth;
            element._height = element.clientHeight;
  
        }
      }
      infospot.lockHoverElement();
    });
  }

  async changeFrame(pill, frame){
    if(this.selectedPill.id != pill.id){
      await this.changePil(pill);
    }
    this.selectedFrame = frame;
    this.isFramePropertiesOpen = true; 
    if(this.selectedPill.type == "Video"){
      this.actualPanorama.videoElement.currentTime = frame.seconds
    }
  }

  randomIdGenerator() {
    // With negative id
    return Math.floor(Math.random() * 99999) * -1
  }

  addNewPill(type: String) {
    let indice = 1;
    if(this.pills.length > 0){
      indice = this.pills[this.pills.length - 1].indice + 1;
    }
    var pill: iPill = {
      id: this.randomIdGenerator(),
      name: "New untitled file",
      type: type,
      frames: [],
      indice: indice
    }
    pill.file = { base64: "", name: "", type: "" };
    this.pills.push(pill);
  }

  checkTypeOfString(ob:any){
    return typeof ob=="string";
  }

  checkUploadSelectedPill(){
    if (this.selectedPill.id == -1) { return false; }
    if (this.selectedPill.file != null && typeof this.selectedPill.file != "string"){
      return this.selectedPill.file.base64!='';
    }
    return false;
  }

  async changePil(pill:iPill){
    if(pill.type=='Video' && (typeof pill.file != "string" && pill.file!.miniature == null)){
      return;
    }
    if(this.selectedPill.id == pill.id){
      return;
    }
    this.clearSelectedFrame();
    
    this.selectedPill.frames.forEach(fram=>{
      fram.infospots.forEach(hot=>{
        hot.obj.removeHoverElement();
      })
    })

    this.selectedPill = pill;
    this.isFramePropertiesOpen = false; 
    this.isLoadingResource = true; 
    await iStaticUtilities.sleep(50);
    this.addFileToEditor();
    await iStaticUtilities.sleep(50);

    this.selectedPill.frames.forEach((fram, index)=>{
      /*let amountHotspot = fram.infospots.length;
      fram.infospots.splice(0,amountHotspot);*/
      fram.infospots.forEach(hot=>{
        this.createHotspot(hot.position.x,hot.position.y,hot.position.z,{frame: fram, index},false);
      });
    })
    await iStaticUtilities.sleep(500);    
    this.updateSizeCanvas();
  }
  
  addFile(pill: iPill) {
    this.popupSE.openPopup(UploadFilePopupComponent, { type: pill.type, name: pill.name, file: pill.file })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd)).subscribe(async res => {
      if (res == null) { return }
      if (res.returnValue.accionHecha) {
        if (pill.type == "Video") {
          pill.file = res.returnValue.data
          let thumbnail = await getThumbnails(res.returnValue.data.base64, {
            interval: 1,
            start: 0,
            end:1
          });
          if(typeof pill.file != "string"){
            if(thumbnail[1] != null){
              pill.file!.miniature = this.getURLFromBlob(thumbnail[1].blob!);
            }
          }
        }
        if (pill.type == "Image") {
          pill.file = res.returnValue.data
        }
        this.loaderSe.open();
        this.mediaCourSe.create(this.course.id,pill);
        this.mediaCourSe.getResultUpdate().pipe(takeUntil(this._unsubInd2)).subscribe(val=>{
          if(val==null){return;}
          this.loaderSe.closeSlow();
          this.pills.forEach(e=>{
            if(e.indice == val.data.indice){
              e.id = val.data.id;
            }
          })
          this._unsubInd2.next("");
        })
        this.mediaCourSe.getResultUpdateError().pipe(takeUntil(this._unsubInd2)).subscribe(val=>{
          if(val==null){return;}
          this.loaderSe.closeSlow();
          this.notificaciones.showError(val.message);
          this._unsubInd2.next("");
        })
        console.log(this.pills);
      }
      this._unsubInd.next("");
    })
  }

  async obtainFrames(base64:any){
    /*this.loader.open();
    this.videoFrames = await getThumbnails(base64, {
      interval: 1,
      start: 0,
    });
    this.videoFrames.forEach(element => {
      element.blob = this.getURLFromBlob(element.blob)
    })
    this.loader.closeSlow();*/
  }

  updatePill(){
    this.checkUpdateDiferences("media",this.selectedPill);
  }

  deletePill() {
    this.validateAction("Remove", "pill",()=>{
      this.checkUpdateDiferences("media",this.selectedPill,-1,true);
      this.pills.splice(this.pills.indexOf(this.selectedPill, 1))
      this.clearSelectedPill();
      this.isOpenedProperties = false;
      this.clearCanvas();
      this.isSelectedFile = false;
    })
  }

  clearSelectedPill() {
    this.selectedPill.frames = []
    this.selectedPill.id = -1;
    this.selectedPill.file = { base64: "", name: "", type: "" }
    this.selectedPill.name = ""
    this.selectedPill.type = ""
  }

  clearSelectedFrame() {
    this.selectedFrame = { id: -1, title: "", infospots: [], seconds: 0 };
  }

  async toggleExpandPill(pill: iPill) {
    if(this.selectedPill.id != pill.id){
      await this.changePil(pill);
      this.selectedPill = pill;
    }
    if (this.pillsExpanded.length == 0) {
      this.pillsExpanded.push(pill)
    } else {
      if (this.pillsExpanded.includes(pill)) {
        this.pillsExpanded.splice(this.pillsExpanded.indexOf(pill), 1)
      } else {
        this.pillsExpanded.push(pill)
      }
    }
  }

  expandAll() {
    this.pillsExpanded = [...this.pills];
  }

  constrainAll() {
    this.pillsExpanded = []
  }

  getFrameCurrentTime():{frame: iFrame, index: number}{
    let currentTime = 0;
    if(this.selectedPill.type == "Video"){
      currentTime = this.actualPanorama.videoElement.currentTime.toFixed(2);
    }
    let framesFind = this.selectedPill.frames.filter(e=>e.seconds == currentTime);
    if(framesFind.length == 0){
      this.addNewFrame();
    }
    let frame = this.selectedPill.frames.find(e=>e.seconds==currentTime)!;
    let index = this.selectedPill.frames.indexOf(frame);
    return {frame: frame, index: index}
  }

  addNewFrame() {
    let pillId = this.selectedPill.id!;
    if (pillId == -1) {
      this.notificaciones.showWarning("To add a frame you have to select a pill")
      return;
    } else if (this.selectedPill.type=='Video'&&typeof this.selectedPill.file != "string" && this.selectedPill.file?.base64 == "") {
      this.notificaciones.showWarning("To add a frame you have to upload a video");
      return;
    }
    let currentTime = 0;
    if(this.selectedPill.type == "Video"){
      currentTime = this.actualPanorama.videoElement.currentTime.toFixed(2);
      let isExistActualFrame = this.selectedPill.frames.filter(e=>e.seconds == currentTime).length;
      if(isExistActualFrame > 0){
        this.notificaciones.showWarning("There is already an interactive frame in this milliseconds");
        return;
      }
    }
    var frame: iFrame = {
      id: this.randomIdGenerator(),
      title: "Untitled frame",
      infospots: [],
      seconds: currentTime
    }
    this.selectedPill.frames.push(frame);
    this.checkUpdateDiferences("frames", frame, pillId);
  }
  clearFrame() {
    this.validateAction("Reset", "frame",()=>{
      if(this.selectedFrame.infospots.length > 0){
        this.selectedFrame.infospots.forEach(e=>{
          this.checkUpdateDiferences("infospot",e,this.selectedFrame.id,true);
          this.deleteHotspotByUUID(e.uuid);
        });
      }
      this.selectedFrame.title = "Untitled frame";
      this.selectedFrame.infospots = [];
      this.checkUpdateDiferences("frames",this.selectedFrame,this.selectedPill.id);
    });
  }
  clearHotspots() {
    this.validateAction("Clear", "hotsposts",()=>{
      if(this.selectedFrame.infospots.length > 0){
        this.selectedFrame.infospots.forEach(e=>{
          this.checkUpdateDiferences("infospot",e,this.selectedFrame.id,true);
          this.deleteHotspotByUUID(e.uuid);
        });
      }
      this.selectedFrame.infospots = []
    });
  }
  cleanHostpotContent(){
    this.validateAction("Clear", "all hotspots content",()=>{
      let hotspot = this.findHostpotByUuid(this.uuidHotspotSelected);
      hotspot.content = [];
      this.updateHotspotInformation(hotspot);
    })
  }
  deleteSection() {
    this.validateAction("Remove", "section",()=>{
      let hotspot = this.findHostpotByUuid(this.uuidHotspotSelected);
      this.checkUpdateDiferences("content",this.hotspotDataSelected,hotspot.id,true);
      let index = hotspot.content.findIndex(e=> e.indice == this.hotspotDataSelected.indice);
      hotspot.content.splice(index, 1);
      this.updateHotspotInformation(hotspot);
    });    
  }
  changeEditorText(textEditor){
    this.contentCacheData.editorText = textEditor;
    this.hotspotDataSelected.content = textEditor;
    this.sendUpdateContent();
  }
  removeFrame() {
    this.validateAction("Remove", "frame",()=>{
      this.checkUpdateDiferences("frames",this.selectedFrame,this.selectedPill.id,true);
      if(this.selectedFrame.infospots.length > 0){
        this.selectedFrame.infospots.forEach(e=>{
          this.deleteHotspotByUUID(e.uuid);
        });
      }
      this.selectedPill.frames.splice(this.selectedPill.frames.indexOf(this.selectedFrame), 1)
      this.isFramePropertiesOpen = false;
    });
  }

  validateAction(accion: String, tipo: String, sucessFn:Function) {
    this.popupSE.openPopup(ConfirmPopupComponent, { accion: accion, tipo: tipo })
    this.popupSE.returnData().pipe(takeUntil(this._unsubInd2)).subscribe(res => {
      if (res == null) { return }
      if (res.returnValue.accionHecha) {
        sucessFn();
      }
      this._unsubInd2.next("");
    })
  }
  addFileToEditor() {
    if (typeof this.selectedPill.file == "string" || this.selectedPill.file?.base64 != "") {
      if (this.checkIfElementExist()) {
        this.clearCanvas();
      }
      /*if(this.selectedPill.type == "Video"){
        this.obtainFrames(this.selectedPill.file?.base64);
      }*/
      this.createPanoramaElement();
    }
  }

  checkIfElementExist(): Boolean {
    var exist = false;
    if ((document.querySelector(".videoEditorBody > .canvasContainer > canvas") as HTMLElement) != null) {
      exist = true;
    } else {
      exist = false;
    }
    return exist;
  }

  updateTimeVideo(){
    let disabled = false;
    this.selectedPill.frames.forEach(frame=>{
      frame.infospots.forEach(elem=>{
        if(elem.obj == null){
          disabled = true;
        }else{
          elem.obj.visible = false;
        }
      });
    });
    //Cuando actualiza de la base de datos y aun no ha ejecutado changePil
    if(disabled){return;}
    let currentTime = this.actualPanorama.videoElement.currentTime.toFixed(0);
    let framesFind = this.selectedPill.frames.filter(e=>{
      if(typeof e.seconds == "string"){
        return parseFloat(e.seconds).toFixed(0) == currentTime
      }else{
        return e.seconds?.toFixed(0) == currentTime
      }
    });
    if(framesFind.length > 0){
      framesFind.forEach(frame=>{
        frame.infospots.forEach(elem => {
          elem.obj.visible = true;
        });
      })
      if(framesFind.length == 1){
        this.selectedFrame = framesFind[0];
      }
    }else{
      this.selectedFrame = { id: -1, title: "", infospots: [], seconds: 0 };
    }
  }

  async updateSizeCanvas(){
    await iStaticUtilities.sleep(10);
    let elm : any = document.querySelector(".videoEditorBody > .canvasContainer > canvas");
    let width = elm?.offsetWidth;
    let height = elm?.offsetHeight;
    if(this.actualViewer!=null){
      this.actualViewer.renderer.domElement.style.width = width;
      this.actualViewer.renderer.domElement.style.height = height;
      this.actualViewer.onWindowResize(width, height);
    }
  }

  async createPanoramaElement() {
    if((typeof this.selectedPill.file == "string" || this.selectedPill.file?.base64 != "") && !this.checkIfElementExist()){
      let viewer = new this.PANOLENS.Viewer({
        container: document.querySelector(".videoEditorBody > .canvasContainer") as HTMLElement,
      });
      let panorama;
      let data:any = this.selectedPill.file;
      if(typeof this.selectedPill.file != "string"){
        data = this.selectedPill.file?.base64;
      }
      if(this.selectedPill.type == "Video"){
        panorama = new this.PANOLENS.VideoPanorama(data, { muted: false, loop: false });
      }else{
        panorama = new this.PANOLENS.ImagePanorama(data);
      }

      this.actualPanorama = panorama;
      viewer.add(panorama);
      this.isSelectedFile = true;

      this.actualViewer = viewer;

      if(this.selectedPill.type == "Video"){
        this.actualPanorama.getVideoElement().addEventListener("timeupdate",(ev)=>{
          this.updateTimeVideo();
        });
      }

      await iStaticUtilities.sleep(200)
      this.isLoadingResource = false;
    }else{
      this.isLoadingResource = false;
    }
  }


  clearCanvas() {
    if((document.querySelector(".videoEditorBody > .canvasContainer > canvas") as HTMLElement) != null){
      (document.querySelector(".videoEditorBody > .canvasContainer > canvas") as HTMLElement).nextSibling?.nextSibling?.remove();
      (document.querySelector(".videoEditorBody > .canvasContainer > canvas") as HTMLElement).nextSibling?.remove();
      (document.querySelector(".videoEditorBody > .canvasContainer > canvas") as HTMLElement).remove();
      this.actualPanorama = null;
      this.videoFrames = []
    }
  }
  previewElement() {
    this.updateDiferences();
    localStorage.setItem("lastRoute", location.pathname)
    setTimeout(() => {
      this.router.navigate(["/course-visualicer/"+this.course.id])
    }, 3000);
  }
  publishCourse(){
    this.coursesSe.publishCourse(this.course.id!);
    this.coursesSe.getResultUpdate().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showFeedBack(res.message)
      setTimeout(() => {
        this.router.navigate(["/discover"])
      }, 500);
      this._unsubInd.next("");
    })
    this.coursesSe.getResultUpdateError().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.notificaciones.showError(res.message)
      this._unsubInd.next("")
    })
    // console.log(this.pills);
  }
  getURLFromBlob(blob: Blob) {
    return String(window.URL.createObjectURL(blob!))
  }
  playVideo(){

    (document.querySelector("div.videoEditor > div.videoEditorBody > .canvasContainer > div:nth-child(4) > span:nth-child(4) > span:nth-child(1)") as HTMLElement).click();
    if (this.playingVideo) {
      this.playingVideo = false;
    }else{
      this.playingVideo = true;
    }
  }
  sendSyncStatus(){
    this.syncStatusChange.emit(this.syncStatus);
  }
}
