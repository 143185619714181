import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoSpotService } from '@app/Services/Api/infoSpot.service';
import { UserService } from '@app/Services/Api/User.service';
import { NotificacionesService } from '@app/Services/Utils/notificaciones.service';
import { iHotspotData } from '@app/Shared/Interfaces/iHotspot';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-InfoSpotContent',
  templateUrl: './InfoSpotContent.component.html',
  styleUrls: ['./InfoSpotContent.component.scss']
})
export class InfoSpotContentComponent extends iUnsubscribeDestroy implements OnInit {
  urlMedia = environment.urlImages
  isDataLoaded: boolean = false;
  infoContents: Array<any> = []
  ingoContentsOpeneds: Array<number> = []
  contentSelected: iHotspotData = iHotspotData.empty();
  constructor(public dialogRef: MatDialogRef<InfoSpotContentComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any, private infoSpotSE: InfoSpotService, private notificaciones: NotificacionesService) {
    super();
  }

  closePopup(returnValue?: any) {
    let p = { returnValue: returnValue };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getInfoSpotContent()
  }

  //LLAMADAS A LA API
  getInfoSpotContent(){
    this.infoSpotSE.getInfoSpotContent(this.modalData.id);
    this.infoSpotSE.getResult().pipe(takeUntil(this._unsubInd)).subscribe(res=>{
      if(res==null){return;}
      this.infoContents = res.data;
      this.isDataLoaded = true;
      this._unsubInd.next("")
    })
  }

  
  //FUNCIONALIDADES
  controlContainer(){
    if (this.ingoContentsOpeneds.length == 0) {
      this.ingoContentsOpeneds.push(this.contentSelected.id)
    }else{
      if (this.ingoContentsOpeneds.includes(this.contentSelected.id)) {
        this.ingoContentsOpeneds.splice(this.ingoContentsOpeneds.indexOf(this.contentSelected.id), 1)
      }else{
        this.ingoContentsOpeneds.push(this.contentSelected.id)
      }
    }
  }
  
  isCompletedHotspot(){
    if (this.ingoContentsOpeneds.length == this.infoContents.length) {
      this.closePopup({completed: true})
    }else{
      this.forceClosePopup();
    }
  }
}
