import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificacionesService } from 'src/app/Services/Utils/notificaciones.service';
import { iCourse } from '@app/Shared/Interfaces/iCourse';
import { iStaticUtilities } from '@app/Shared/Interfaces/iStaticUtilities';
import { LoaderService } from '@app/Services/Utils/loader.service';
import { CategoriesService } from '@app/Services/Api/Categories.service';
import { takeUntil } from 'rxjs';
import { iUnsubscribeDestroy } from '@app/Shared/Interfaces/iUnsubscribeDestroy';
import { iCategory } from '@app/Shared/Interfaces/iCategory';
import { iDate } from '@app/Shared/Interfaces/iDate';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'NewCourse-FirstForm',
  templateUrl: './NewCourse-FirstForm.component.html',
  styleUrls: ['./NewCourse-FirstForm.component.scss']
})
export class NewCourseFirstFormComponent extends iUnsubscribeDestroy implements OnInit, OnChanges {
    
  @Output() emitCourseName = new EventEmitter<String>()
  @Output() hasChanges = new EventEmitter<Boolean>()
  @Output() data = new EventEmitter<iCourse>()
  @Input() course: iCourse = {title:"", categories:[], description: ""}

  courseName:string = ""
  courseNameError: Boolean = false;
  courseDuration:string = "00:00"
  courseDurationError: Boolean = false;
  elementActive:Number = 0;
  topics: Array<iCategory> = [];
  topicsSelected: Array<string> = []
  topicsError: Boolean = false;
  description: string = ""
  descriptionError: Boolean = false;
  isLoadingImage: boolean = false;
  isEdit: boolean = false;
  imagePreview = "/assets/Images/Images/placeholder.jpg";


  constructor(private notificaciones: NotificacionesService, private loader:LoaderService, private categories:CategoriesService, private actRoute:ActivatedRoute ) { 
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.isEdit = this.actRoute.snapshot.params['id'] != null;
    if(changes["course"]){
      this.updateVariables();
    }
  }

  ngOnInit() {
    this.updateVariables();
    this.loader.open();
    this.categories.getAll();
    this.categories.getResult().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return;}
      this.topics = value.data;
      this.loader.closeSlow();
    })
    this.categories.getResultError().pipe(takeUntil(this._unsub)).subscribe(value=>{
      if(value==null){return;}
      this.notificaciones.showError(value.message);
      this.loader.closeSlow();
    });
  }
  getUserName(){
    return localStorage.getItem("nameUser");
  }

  updateVariables(){
    iStaticUtilities.isEmpty(this.course.title,()=>{
      this.courseName = this.course.title;
    })
    iStaticUtilities.isEmpty(this.course.categories,()=>{
      this.topicsSelected = this.course.categories.map(e=>e.name);
    })
    iStaticUtilities.isEmpty(this.course.description,()=>{
      this.description = this.course.description;
    })
    iStaticUtilities.isEmpty(this.course.image,()=>{
      this.imagePreview = environment.urlImages + this.course.image!;
    });
    iStaticUtilities.isEmpty(this.course.duration,()=>{
      let dur = this.course.duration!;
      let min = parseInt(String(dur/60));
      let seg = dur - min;
      this.courseDuration = iDate.cerosAdd(min)+":"+iDate.cerosAdd(seg);
    },undefined,[0]);
  }
  isTopicSelected(topicName){
    return this.topicsSelected.includes(topicName)
  }

  sendCourseName(){
    this.emitCourseName.emit(this.courseName)
  }
  detectChanges(){
    this.hasChanges.emit(true);
  }
  manageTopics(topic:string){
    if (this.topicsSelected.length == 0) {
      this.topicsSelected.push(topic)
    }else{
      if (this.topicsSelected.includes(topic)) {
        this.topicsSelected.splice(this.topicsSelected.indexOf(topic), 1)
      }else{
        this.topicsSelected.push(topic)
      }
    }
  }
  validatePaso0(){
    iStaticUtilities.isEmpty(this.courseName,()=>{
      this.elementActive = 1;
    },()=>{
      this.courseNameError = true;
      this.notificaciones.showError("You must enter a name for the course")
    },['Untitled course']);
  }
  validatePaso1(){
    iStaticUtilities.isEmpty(this.topicsSelected,()=>{
      this.topicsError = false;
      this.elementActive = 2;
    },()=>{
      this.topicsError = true;
      this.notificaciones.showError("You must select at least one topic for the course")
    });
  }
  setFileContent(ev){
    this.imagePreview = ev[0];
    this.isLoadingImage = false;
  }
  validarPaso2(){
    iStaticUtilities.isEmpty(this.description,()=>{
      this.descriptionError = false;
      iStaticUtilities.isEmpty(this.courseDuration,()=>{
        this.courseDurationError = false;
        let splitHour = this.courseDuration.split(":");
        let minutos = parseInt(splitHour[1]) + (parseInt(splitHour[0]) * 60);

        var course:iCourse = {
          title: this.courseName,
          description: this.description,
          categories: this.topicsSelected,
          duration: minutos
        }
        if( this.imagePreview.startsWith("data:image") ){
          course.image = this.imagePreview;
        }
        this.data.emit(course)
      },()=>{
        this.courseDurationError = true;
        this.notificaciones.showError("You mus enter a duration for the course");
      },['00:00'])
    },()=>{
      this.descriptionError = true;
      this.notificaciones.showError("You must enter a description for the course")
    })

  }

}
