<div class="buscadorContainer" (click)="controlOpenFilters()">
  <div class="icons">
    <img class="icon" *ngIf="!loaderActive" src="../../../assets/Icons/searchIcon.svg" alt="">
    <iconLoader class="icon" *ngIf="loaderActive" [size]="'small'"></iconLoader>
  </div>
  <div class="activeFilters">
    <ng-container *ngFor="let category of categoriesSelected | slice:0:4; let i = index">
      <span class="selected">
        {{category}}
        <mat-icon (click)="$event.stopPropagation();controlCategories(category);emitCategories()">close</mat-icon>
      </span>
      <span class="selected moreElements" *ngIf="i == 3 && (categoriesSelected.length - 4) != 0">+ {{categoriesSelected.length - 4}}</span>
    </ng-container>
  </div>
  <input type="text" [placeholder]="placeholder" [(ngModel)]="data" (input)="filter(data);" (change)="emitCriteria()"
    class="inputData">

</div>
<div class="filtersContainer" *ngIf="type == 'complex' && opened">
  <div class="filterSubcontainer">
    <span class="filterTitle">Specialities</span>
    <div class="filters">
      <span [class.selected]="categoriesSelected.includes(category)" *ngFor="let category of categories"
        (click)="controlCategories(category);emitCategories()">{{category}}</span>
    </div>
  </div>
  <div class="filterSubcontainer">
    <span class="filterTitle">File</span>
    <div class="filters">
      <span [class.selected]="filesTypesSelected.includes(file)" *ngFor="let file of filesTypes"
        (click)="controlFileType(file);emitFyleType()">{{file}}</span>
    </div>
  </div>
  <div class="filterSubcontainer">
    <span class="filterTitle">Sort by</span>
    <div class="filters">
      <span [class.selected]="sortTypesSelected == sort" *ngFor="let sort of sortTypes"
        (click)="sortTypesSelected = sort; emitSort()">{{sort}}</span>
    </div>
  </div>
</div>