<div class="uploadFileContainer">
  <div class="header">
    <p>Attach 360 {{type}} file</p>
    <div (click)="forceClosePopup()">
      <img src="/assets/Icons/closeIconBlack.svg" alt="">
    </div>
  </div>
  <div class="body">
    <div>
      <p>{{pillName}}</p>
    </div>
    <div>
      <div>
        <img src="/assets/Icons/fileIcon.svg" alt="">
        <p *ngIf="fileName == ''">No {{type}}video attached</p>
        <p *ngIf="fileName != ''">{{fileName}}</p>
      </div>
      <p *ngIf="fileName != '' && checkIfFormatIsValid(fileFormat)" class="correct">Upload successfully!</p>
      <p *ngIf="fileName != '' && !checkIfFormatIsValid(fileFormat)" class="incorrect">Format not valid!</p>
  
    </div>
    <div fileInput [accept]="getAccept()" [masInfo]="true" [(fichero)]="file" (ficheroChange)="setFile(file)" (loadingFile)="changeLoading()">
      <div *ngIf="!isLoadingFile">
        <img src="/assets/Icons/uploadFileIcon.svg" alt="">
        <p>
          Drop your file here!
        </p>
      </div>
      <div *ngIf="isLoadingFile">
        <iconLoader></iconLoader>
      </div>
    </div>
   
  </div>
  <div class="footer">
    <button type="button" (click)="forceClosePopup()">Cancel</button>
    <button type="button"(click)="closePopup({accionHecha: true, data: file})">Finish</button>
  </div>
</div>