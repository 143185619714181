<header></header>
<div class="myProfileGeneralContainer">
  <p>My profile</p>
  <div class="chartsContainer">
    <div>
      <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
      <p class="noData" *ngIf="gradesStatistics.length == 0 && dataIsLoaded">No data available yet</p>

      <GradesChart [data]="gradesStatistics" *ngIf="dataIsLoaded && gradesStatistics.length != 0"></GradesChart>
    </div>
    <div>
      <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
      <p class="noData" *ngIf="categoriesStatistics.length == 0 && dataIsLoaded">No data available yet</p>
      <CategoriesChart [data]="categoriesStatistics" *ngIf="dataIsLoaded && categoriesStatistics.length != 0"></CategoriesChart>
    </div>
  </div>
  <p>In progress</p>
  <div class="coursesInProgressContainer">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <span class="noData" *ngIf="startedCourses.length == 0">You do not have any courses yet</span>
      <card [size]="'big'" [data]="startedCourse" [activeProgressBar]="activeProgressBar" [id]="'p'+i"
        (mousemove)="rotateToMouse($event, i, 'p')" (mouseleave)="clearPosition(i, 'p')" [action]="'continue'"
        *ngFor="let startedCourse of startedCourses; let i = index"></card>
    </ng-container>
  </div>
  <p>Diplomas and finished courses</p>
  <div class="gradesDiplomasContainer">
    <iconLoader class="centered" *ngIf="!dataIsLoaded"></iconLoader>
    <ng-container *ngIf="dataIsLoaded">
      <span class="noData" *ngIf="gradesCourses.length == 0">You have no degrees or diplomas yet</span>
      <card [size]="'medium'" [data]="gradeCourse" [cardType]="'grades'" [id]="'g'+i" (mousemove)="rotateToMouse($event, i, 'g')"
      (mouseleave)="clearPosition(i, 'g')" *ngFor="let gradeCourse of gradesCourses; let i = index"></card>
    </ng-container>
  </div>
  <!-- <ng-container *ngIf="gradesCourses.length != 0">
    <button class="seeMoreButton" type="button" (click)="limit=limit+3" *ngIf="limit < gradesCourses.length">See more</button>
    <button class="seeMoreButton" type="button" (click)="limit= 3" *ngIf="limit >= gradesCourses.length">See less</button>
  </ng-container> -->
</div>
<footer></footer>